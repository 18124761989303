import { ThunkAction } from '../store/shared/types';
import {
  roleTypesFetchFailed,
  roleTypesFetchSucceeded,
  roleTypesFetchStarted,
} from '../store/roleTypes/actions';
import ALKS from 'alks.js';
import * as config from '../config';
import { RoleType } from '../models';
import { OktaAuth } from '@okta/okta-auth-js';
import getErrorMessage from '../util/getErrorMessage';

/**
 * Fetchs the list of role types from ALKS
 *
 * @param auth - the okta auth object from state
 */
export const fetchRoleTypes = (auth: OktaAuth): ThunkAction<void> => async (
  dispatch
) => {
  dispatch(roleTypesFetchStarted());

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    dispatch(roleTypesFetchFailed(reason));
    throw e;
  }

  const alks = ALKS.create({
    baseUrl: config.default.alks.baseUrl,
    accessToken,
  });

  let alksRoleTypes: ALKS.AwsRoleType[];
  try {
    alksRoleTypes = await alks.getAllAWSRoleTypes({
      getDynamicValues: true,
    });
  } catch (e) {
    const reason = `An error occured when fetching role types from ALKS: ${getErrorMessage(
      e
    )}`;
    dispatch(roleTypesFetchFailed(reason));
    throw e;
  }

  const roleTypes: RoleType[] = alksRoleTypes.map((alksRoleType) => ({
    name: alksRoleType.roleTypeName,
    defaultArns: alksRoleType.defaultArns.filter((arn) => arn !== '0' && arn != ""),
    trustRelationship: alksRoleType.trustRelationship,
    instanceProfile: alksRoleType.instanceProfile,
    templateFields: alksRoleType.templateVariables || [],
  }));

  dispatch(roleTypesFetchSucceeded(roleTypes));
};
