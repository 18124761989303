import * as React from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import styled from 'styled-components';

const ConfirmationModal = ({ closeModal, title, message, func }) => {
  return (
    <>
      <StyledModalHeader toggle={closeModal}>{title}</StyledModalHeader>
      <StyledModalBody>{message}</StyledModalBody>
      <StyledModalFooter>
        <Button
          color="primary"
          onClick={() => {
            func();
            closeModal();
          }}
        >
          Confirm
        </Button>
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </StyledModalFooter>
    </>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};

  && .close {
    color: ${({ theme }) => theme.textColor};
    text-shadow: 0 1px 0 ${({ theme }) => theme.modal.backgroundColor};
  }
`;

const StyledModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledModalFooter = styled(ModalFooter)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

export default ConfirmationModal;
