import {
  PreferenceActionType,
  PREFERENCE_ACCOUNT_STARRED,
  PREFERENCE_ACCOUNT_UNSTARRED,
  PREFERENCE_TOGGLE_ACCOUNT_STARRED,
  PREFERENCE_SET_PREFERRED_ROLE,
  PREFERENCE_SET_PREFERRED_ROLE_DURATION,
  PREFERENCE_ADD_TAG,
  PREFERENCE_REMOVE_TAG,
  PREFERENCE_BULK_ADD_TAGS,
  ADD_GLOBAL_TAG,
  REMOVE_GLOBAL_TAG,
  READ_ANNOUNCEMENT,
  PREFERENCES_ARE_LOADING,
  PREFERENCES_FAILED,
  PREFERENCES_SUCCEEDED,
  PUT_PREFERENCES_FAILED,
  PUT_PREFERENCES_STARTED,
  PUT_PREFERENCES_SUCCEEDED,
  SET_THEME,
  PreferenceReduxState,
} from './types';
import {
  applyDefaultAccountPreferences,
  applyDefaultRolePreferences,
  reservedTags,
} from '../../models';
import { DataFetchStatus } from '../shared/types';
import { lightMode } from '../../styles/theme';

export const initialState: PreferenceReduxState = {
  preferenceState: {
    accounts: {},
    roles: {},
    globalTags: {},
    announcementsRead: {},
    theme: lightMode,
  },
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
  putStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
};

export const preferenceReducer = (
  state = initialState,
  action: PreferenceActionType
): PreferenceReduxState => {
  switch (action.type) {
    case PREFERENCES_ARE_LOADING: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
        failureMessage: undefined,
      };
    }

    case PREFERENCES_FAILED: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
        failureMessage: action.failureMessage,
      };
    }

    case PREFERENCES_SUCCEEDED: {
      const { preferences } = action;
      const accountPreferenceState = state.preferenceState.accounts;
      const newAccountPreferenceState = { ...accountPreferenceState };

      for (const accountId of Object.keys(preferences.accounts ?? {})) {
        newAccountPreferenceState[accountId] = {
          ...applyDefaultAccountPreferences(accountPreferenceState[accountId]),
          ...preferences.accounts[accountId],
          tags: {
            ...accountPreferenceState[accountId]?.tags,
            ...preferences.accounts[accountId].tags,
          },
        };
      }

      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        failureMessage: undefined,
        preferenceState: {
          accounts: newAccountPreferenceState,
          roles: {
            ...state.preferenceState.roles,
            ...preferences.roles,
          },
          globalTags: {
            ...state.preferenceState.globalTags,
            ...preferences.globalTags,
          },
          announcementsRead: {
            ...state.preferenceState.announcementsRead,
            ...preferences.announcementsRead,
          },
          theme: {
            ...state.preferenceState.theme,
            ...preferences.theme,
          },
        },
      };
    }

    case PUT_PREFERENCES_STARTED: {
      return {
        ...state,
        putStatus: DataFetchStatus.DATA_FETCH_PENDING,
        putFailureMessage: undefined,
      };
    }

    case PUT_PREFERENCES_FAILED: {
      return {
        ...state,
        putStatus: DataFetchStatus.DATA_FETCH_FAILED,
        putFailureMessage: action.failureMessage,
      };
    }

    case PUT_PREFERENCES_SUCCEEDED: {
      return {
        ...state,
        putStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        putFailureMessage: undefined,
      };
    }

    case PREFERENCE_ACCOUNT_STARRED: {
      const { accountId } = action;

      const prefs =
        state.preferenceState.accounts[accountId] ||
        applyDefaultAccountPreferences({});

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: {
            ...state.preferenceState.accounts,
            [accountId]: {
              ...prefs,
              starred: true,
            },
          },
        },
      };
    }

    case PREFERENCE_ACCOUNT_UNSTARRED: {
      const { accountId } = action;
      if (!state.preferenceState.accounts[accountId]) {
        return state;
      }

      const prefs = state.preferenceState.accounts[accountId];

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: {
            ...state.preferenceState.accounts,
            [accountId]: {
              ...prefs,
              starred: false,
            },
          },
        },
      };
    }

    case PREFERENCE_TOGGLE_ACCOUNT_STARRED: {
      const { accountId } = action;

      const prefs =
        state.preferenceState.accounts[accountId] ||
        applyDefaultAccountPreferences({});

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: {
            ...state.preferenceState.accounts,
            [accountId]: {
              ...prefs,
              starred: !prefs.starred,
            },
          },
        },
      };
    }

    case PREFERENCE_SET_PREFERRED_ROLE: {
      const { accountId, roleId } = action;

      const prefs =
        state.preferenceState.accounts[accountId] ||
        applyDefaultAccountPreferences({});

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: {
            ...state.preferenceState.accounts,
            [accountId]: {
              ...prefs,
              preferredRole: roleId,
            },
          },
        },
      };
    }

    case PREFERENCE_SET_PREFERRED_ROLE_DURATION: {
      const { roleId, duration } = action;

      const prefs =
        state.preferenceState.roles[roleId] || applyDefaultRolePreferences({});

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          roles: {
            ...state.preferenceState.roles,
            [roleId]: {
              ...prefs,
              duration,
            },
          },
        },
      };
    }

    case PREFERENCE_ADD_TAG: {
      const { accountId, tag } = action;

      const currentAccountPrefs = applyDefaultAccountPreferences(
        state.preferenceState.accounts[accountId]
      );

      // check if tag already exists OR if it's one of the reservedTags then just return state as is
      if (currentAccountPrefs.tags[tag.key] || reservedTags[tag.key]) {
        return state;
      }

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: {
            ...state.preferenceState.accounts,
            [accountId]: {
              ...currentAccountPrefs,
              tags: { ...currentAccountPrefs.tags, [tag.key]: tag },
            },
          },
        },
      };
    }

    case PREFERENCE_REMOVE_TAG: {
      const { accountId, tag } = action;

      const prefs = applyDefaultAccountPreferences(
        state.preferenceState.accounts[accountId]
      );
      const newTags = {
        ...state.preferenceState.accounts[accountId].tags,
      };
      delete newTags[tag.key];

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: {
            ...state.preferenceState.accounts,
            [accountId]: {
              ...prefs,
              tags: newTags,
            },
          },
        },
      };
    }

    case PREFERENCE_BULK_ADD_TAGS: {
      const { tagsByAccountId } = action;
      const accountPreferenceState = state.preferenceState.accounts;
      const newAccountPreferenceState = { ...accountPreferenceState };

      for (const accountId of Object.keys(tagsByAccountId)) {
        newAccountPreferenceState[accountId] = {
          ...applyDefaultAccountPreferences(accountPreferenceState[accountId]),
          tags: {
            ...accountPreferenceState[accountId]?.tags,
            ...tagsByAccountId[accountId],
          },
        };
      }

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          accounts: newAccountPreferenceState,
        },
      };
    }

    case ADD_GLOBAL_TAG: {
      const { tag } = action;

      const newGlobalTags = state.preferenceState.globalTags;
      // if tag exists increase count, else create new k/v pair set to 1
      if (newGlobalTags[tag.key] !== undefined) {
        const previousValue: number = newGlobalTags[tag.key];
        newGlobalTags[tag.key] = previousValue + 1;
      } else {
        newGlobalTags[tag.key] = 1;
      }

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          globalTags: newGlobalTags,
        },
      };
    }

    case REMOVE_GLOBAL_TAG: {
      const { tag } = action;

      const newGlobalTags = state.preferenceState.globalTags;
      // decrement count of tag, if 0 remove it
      if (newGlobalTags[tag.key]) {
        newGlobalTags[tag.key] = newGlobalTags[tag.key] - 1;
        if (newGlobalTags[tag.key] === 0) {
          delete newGlobalTags[tag.key];
        }
      }

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          globalTags: newGlobalTags,
        },
      };
    }

    case READ_ANNOUNCEMENT: {
      const { announcementId } = action;

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          announcementsRead: {
            ...state.preferenceState.announcementsRead,
            [announcementId]: true,
          },
        },
      };
    }

    case SET_THEME: {
      const { theme } = action;

      return {
        ...state,
        preferenceState: {
          ...state.preferenceState,
          theme,
        },
      };
    }

    default: {
      return state;
    }
  }
};
