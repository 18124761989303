import { AnyAction, Store } from 'redux';
import { AppState } from '../index';
import { PreferenceState } from './types';
import { throttle } from 'lodash';
import { DataFetchStatus } from '../shared/types';
import { putUserPreference } from '../../operations/putUserPreference';
import { oktaAuth } from '../oktaAuth';

const select = (state: AppState): PreferenceState =>
  state.ui.preferences.preferenceState;

export const registerUserPreferenceListener = (store: Store<AppState>) => {
  let currentValue: PreferenceState | undefined;
  // we want to ignore the first two initial state changes because they always happen
  // which is the setting the initial state change, and the add meta data tag change
  let initialStateChangeCounter = 2;
  store.subscribe(
    throttle(() => {
      const previousValue = currentValue;
      currentValue = select(store.getState());

      if (previousValue !== currentValue) {
        if (initialStateChangeCounter > 0) {
          initialStateChangeCounter = initialStateChangeCounter - 1;
        } else {
          const userId = store.getState().data.userInfo.username;
          const fetchStatus = store.getState().ui.preferences.fetchStatus;
          if (fetchStatus === DataFetchStatus.DATA_FETCH_SUCCEEDED) {
            // we only make changes to user preference if the initial fetch succeeded otherwise we could potentially override what they had
            // Not sure exactly why but typescript seems to think that ThunkAction<void> (which extends AnyAction) can't be passed to a function that takes an AnyAction. Typescript is wrong - BW 3/4/2020
            store.dispatch(
              (putUserPreference(
                oktaAuth,
                userId,
                currentValue
              ) as unknown) as AnyAction
            );
          }
        }
      }
    }, 2500)
  );
};
