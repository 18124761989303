import * as t from 'io-ts';

export const RolePreferences = t.interface({
  duration: t.number,
});

export type RolePreferences = t.TypeOf<typeof RolePreferences>;

const rolePreferenceDefaults: RolePreferences = {
  duration: 1,
};

export const applyDefaultRolePreferences = (
  prefs: Partial<RolePreferences> = {}
) => ({
  ...rolePreferenceDefaults,
  ...prefs,
});

export default RolePreferences;
