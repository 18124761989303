import { ArfAccountRemediation } from '../models';
import { getActiveAccountId } from './getActiveAccountId';
import { getArfAccountRemediationsById } from './common';
import { createSelector } from 'reselect';

export const getActiveArfAccountRemediations = createSelector(
  [getArfAccountRemediationsById, getActiveAccountId],
  (arfAccountRemediations, accountId): ArfAccountRemediation[] | undefined => {
    return accountId ? arfAccountRemediations?.get(accountId) : undefined;
  }
);

export default getActiveArfAccountRemediations;
