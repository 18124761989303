import { push } from 'connected-react-router';
import qs from 'query-string';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import AccountList, {
  Props as AccountListProps,
} from '../components/accountList/AccountList';
import { getPreferredRoles } from '../selectors/getPreferredRoles';
import { getStarredAccounts } from '../selectors/getStarredAccounts';
import { getUnstarredAccounts } from '../selectors/getUnstarredAccounts';
import { AppState } from '../store';
import { toggleAccountStarred } from '../store/preferences/actions';
import { getMatchingAccounts } from '../util/getMatchingAccounts';
import { getMostRecentKeys } from '../selectors/getMostRecentKeys';
import { getAccountSearchText } from '../selectors/common';
import { getActiveAccountId } from '../selectors/getActiveAccountId';
import { Dispatch } from '../store/shared/types';

const mapStateToProps = (state: AppState) => ({
  starredAccounts: getMatchingAccounts(
    getStarredAccounts(state),
    getAccountSearchText(state)
  ),
  unstarredAccounts: getMatchingAccounts(
    getUnstarredAccounts(state),
    getAccountSearchText(state)
  ),
  preferredRoles: getPreferredRoles(state),
  mostRecentKeys: getMostRecentKeys(state),
  location: state.router.location,
  selectedAccountId: getActiveAccountId(state),
});
type MappedPropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAccountClick: (
    location: RouteComponentProps['location'],
    accountId: string
  ) =>
    dispatch(
      push({
        ...location,
        search: qs.stringify({
          ...(qs.parse(location.search)),
          account: accountId,
        }),
      })
    ),
  onStarClick: (accountId: string) => dispatch(toggleAccountStarred(accountId)),
});
type MappedPropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (
  stateProps: MappedPropsFromState,
  dispatchProps: MappedPropsFromDispatch
): AccountListProps => {
  const newStateProps: Omit<MappedPropsFromState, 'location'> = {
    ...stateProps,
  };
  // @ts-ignore
  delete newStateProps.location;

  const newDispatchProps = {
    ...dispatchProps,
    onAccountClick: dispatchProps.onAccountClick.bind(
      null,
      stateProps.location
    ),
  };

  return {
    ...stateProps,
    ...newDispatchProps,
  };
};

export const VisibleAccountList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AccountList);

export default VisibleAccountList;
