import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import SignedUrlResponse from '../../models/SignedUrlResponse';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export interface DispatchProps {
  getSignedUrl: (
    auth: OktaAuth,
    accountId: string,
    role: string,
    destination: string,
    duration: string
  ) => Promise<SignedUrlResponse>;
}

export type OwnProps = RouteComponentProps;

export type Props = OwnProps & DispatchProps;

export const ClickThruAction: React.FC<Props> = ({
  getSignedUrl,
  location,
}) => {
  const { oktaAuth } = useOktaAuth();

  React.useEffect(() => {
    const accountId = url.get('accountId') || '';
    const role = url.get('role') || '';
    const destination = url.get('destination') || '';
    const duration = url.get('duration') || '1';

    // TODO: This redirection is async and should happen in a thunk, not a UI component - BW
    if (accountId && role && destination) {
      getSignedUrl(oktaAuth, accountId, role, destination, duration).then(
        (res) => {
          if (res.statusCode === 200 && res.signedUrl) {
            // redirect to signed url
            window.location.href = res.signedUrl;
          } else {
            // redirect to error page
            if (res.accountRole) {
              window.location.href =
                '/error?code=' +
                res.statusCode +
                '&message=' +
                res.errorMessage +
                '&accountRole=' +
                res.accountRole;
            } else {
              window.location.href =
                '/error?code=' +
                res.statusCode +
                '&message=' +
                res.errorMessage;
            }
          }
        }
      );
    }
  });

  const queryParams = location.search;
  const url = new URLSearchParams(queryParams);
  // check that accountId, role, and destination are set if not don't send them to lambda
  const accountId = url.get('accountId');
  const role = url.get('role');
  const destination = url.get('destination');

  if (!accountId || !role || !destination) {
    return (
      <StyledDiv>
        <Container className="text-center">
          <h1>Invalid console link</h1>
        </Container>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <Container className="text-center">
        <section>Redirecting...</section>
      </Container>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  color: ${({ theme }) => theme.textColor};
`;

export default ClickThruAction;
