import { Account } from './Account';
import {
  AccountPreferences,
  applyDefaultAccountPreferences,
} from './AccountPreferences';
import memoize from 'memoizee';

// This is the same as `type AccountView = Account & AccountPreferences` except that it's extensible
export interface AccountView extends Account, AccountPreferences {}

export const getAccountView = memoize(
  (account: Account, prefs?: AccountPreferences): AccountView => ({
    ...account,
    ...applyDefaultAccountPreferences(prefs),
  }),
  { length: 2 }
);

export default AccountView;
