import { AppState } from '../store';
import TagPrefrence, {
  ModelProps,
  DispatchProps,
  Props,
} from '../components/preferences/TagPreference';
import getActiveAccount from '../selectors/getActiveAccount';
import { connect } from 'react-redux';
import {
  addTag,
  removeTag,
  addGlobalTag,
  removeGlobalTag,
} from '../store/preferences/actions';
import { getGlobalTags } from '../selectors/common';
import { Tag } from '../models';

const mapStateToProps = (state: AppState): ModelProps => ({
  account: getActiveAccount(state),
  globalTags: getGlobalTags(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  addTag: (accountTags: Record<string, Tag>, accountId: string, tag: Tag) => {
    if (!accountTags[tag.key]) {
      dispatch(addTag(accountId, tag));
      dispatch(addGlobalTag(tag));
    }
  },
  removeTag: (
    accountTags: Record<string, Tag>,
    accountId: string,
    tag: Tag
  ) => {
    if (accountTags[tag.key]) {
      dispatch(removeTag(accountId, tag));
      dispatch(removeGlobalTag(tag));
    }
  },
});

const mergeProps = (
  stateProps: ModelProps,
  dispatchProps: ReturnType<typeof mapDispatchToProps>
): Props => {
  const newDispatchProps: DispatchProps = {
    ...dispatchProps,
    addTag: dispatchProps.addTag.bind(null, stateProps.account?.tags ?? {}),
    removeTag: dispatchProps.removeTag.bind(
      null,
      stateProps.account?.tags ?? {}
    ),
  };

  return {
    ...stateProps,
    ...newDispatchProps,
  };
};

export const VisibleTagPreference = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TagPrefrence);

export default VisibleTagPreference;
