import { connect } from 'react-redux';
import { AppState } from '../store';
import { loadData } from '../util/loadData';
import arfAccountViolationsLoader from '../util/dataLoaders/arfAccountViolationsLoader';
import getActiveArfAccountViolations from '../selectors/getActiveArfAccountViolations';
import ArfAccountViolations from '../components/old_arf/ArfAccountViolations';

const mapStateToProps = (state: AppState) => {
  const arfAccountViolations = getActiveArfAccountViolations(state);
  return {
    arfAccountViolations,
  };
};

export const ActiveArfAccountViolations = loadData(
  [arfAccountViolationsLoader],
  connect(mapStateToProps)(ArfAccountViolations)
);

export default ActiveArfAccountViolations;
