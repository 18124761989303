import * as React from 'react';
import styles from './HomePage.module.scss';
import { StarredAccountIamRolesFetcher } from '../../containers/StarredAccountIamRolesFetcher';
import { RoleTypesFetcher } from '../../containers/RoleTypesFetcher';
import { FilterableAccountList } from '../accountList/FilterableAccountList';
import AccountPanel from '../accountPanel/AccountPanel';
import ConnectedModalRoot from '../../containers/ConnectedModalRoot';

export const HomePage: React.FC = () => (
  <>
    <ConnectedModalRoot />
    <StarredAccountIamRolesFetcher />
    <RoleTypesFetcher />
    <div className={styles.homePage}>
      <div className={styles.accountList}>
        <FilterableAccountList />
      </div>
      <div className={styles.accountPanel}>
        <AccountPanel />
      </div>
    </div>
  </>
);

export default HomePage;
