import { OktaAuth } from '@okta/okta-auth-js';
import { connect } from 'react-redux';
import { ClickThruAction } from '../components/main/ClickThruAction';
import { fetchSignedLoginUrl } from '../operations/fetchSignedLoginUrl';
import { Dispatch } from '../store/shared/types';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSignedUrl: (
    auth: OktaAuth,
    accountId: string,
    role: string,
    destination: string,
    duration: string
  ) =>
    dispatch(fetchSignedLoginUrl(auth, accountId, role, destination, duration)),
});

export const VisibleClickThruAction = connect(
  undefined,
  mapDispatchToProps
)(ClickThruAction);

export default VisibleClickThruAction;
