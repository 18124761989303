import { DataFetchStatus } from '../shared/types';
import {
  ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED,
  ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED,
  ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED,
  ArfAccountViolationsActionType,
  ArfAccountViolationsState,
} from './types';

const initialState: ArfAccountViolationsState = {
  fetchJobs: new Map(),
  byId: new Map(),
};

export const arfAccountViolationsReducer = (
  state = initialState,
  action: ArfAccountViolationsActionType
): ArfAccountViolationsState => {
  switch (action.type) {
    case ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED: {
      const { accountId } = action;

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          status: DataFetchStatus.DATA_FETCH_PENDING,
          failureMessage: undefined,
        }),
      };
    }

    case ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED: {
      const { accountId, failureMessage } = action;
      const job = state.fetchJobs.get(accountId);

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_FAILED,
          failureMessage,
        }),
      };
    }

    case ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED: {
      const { accountId, arfAccountViolations } = action;
      const job = state.fetchJobs.get(accountId);

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
          failureMessage: undefined,
        }),
        byId: new Map(state.byId).set(accountId, arfAccountViolations),
      };
    }

    default:
      return state;
  }
};
