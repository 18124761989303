import {
  ModalState,
  ModalType,
  SHOW_MODAL,
  ModalActionType,
  HIDE_MODAL,
  ModalStatus,
} from './types';

export const initialState: ModalState = {
  modalType: ModalType.None,
  modalProps: {},
  modalStatus: ModalStatus.Hidden,
};

export const modalReducer = (
  state = initialState,
  action: ModalActionType
): ModalState => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modalType: action.modal,
        modalProps: action.props,
        modalStatus: ModalStatus.Shown,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalType: ModalType.None,
        modalProps: {},
        modalStatus: ModalStatus.Hidden,
      };
    default:
      return state;
  }
};

export default modalReducer;
