import { ArrowLeftIcon, CheckIcon, InfoIcon, TrashIcon } from '@primer/octicons-react';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
  Button,
} from 'reactstrap';
import { IamRole as IamRoleModel } from '../../models';
import styles from './IamRole.module.scss';
import {
  DataFetchStatus,
  DeleteJob,
  DeleteJobStatus,
} from '../../store/shared/types';
import { RequestDetails as UpdateIamRoleDetails } from '../../operations/updateIamRole';
import { CircularProgress } from '@mui/material';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export interface ModelProps {
  iamRole?: IamRoleModel;
  MICreationStatus: DataFetchStatus;
  MIDeleteJobs: Map<string, DeleteJob>;
  iamRoleDeleteJobs: Map<string, DeleteJob>;
  accountSecurityLevel: string;
}

export interface DispatchProps {
  enableMachineIdentity: (
    auth: OktaAuth,
    accountId: string,
    iamRoleName: string,
    iamRoleId: string,
    iamRoleArn: string
  ) => void;
  disableMachineIdentity: (
    auth: OktaAuth,
    iamRoleArn: string,
    iamRoleId: string
  ) => void;
  openConfirmation: (f: () => void, s: string) => void;
  deleteRole: (auth: OktaAuth, iamRole: IamRoleModel) => void;
  updateRole: (auth: OktaAuth, details: UpdateIamRoleDetails) => void;
}

export type OwnProps = RouteComponentProps;

export type Props = OwnProps & ModelProps & DispatchProps;

export const IamRole: React.FC<Props> = (props) => {
  const iamRole = props.iamRole;

  const { oktaAuth } = useOktaAuth();

  const currentRoleTrustPolicyString = React.useMemo(
    () => JSON.stringify(iamRole?.trustPolicy ?? '', null, 4),
    [iamRole?.trustPolicy]
  );

  // access to text area value for live updates
  // setting the initial state to a possible empty string to satisfy useState
  const [trustPolicy, setTrustPolicy] = React.useState(
    currentRoleTrustPolicyString
  );

  const evaluateTrustPolicyValidity = React.useCallback((trustPolicy) => {
    try {
      JSON.parse(trustPolicy);
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const [trustPolicyIsValid, setTrustPolicyIsValid] = React.useState(
    evaluateTrustPolicyValidity(trustPolicy)
  );

  React.useEffect(() => {
    setTrustPolicyIsValid(evaluateTrustPolicyValidity(trustPolicy));
  }, [trustPolicy, evaluateTrustPolicyValidity]);

  const [machineIdentityChecked, setMachineIdentityChecked] = React.useState(
    iamRole?.isMachineIdentity
  );

  if (!iamRole) {
    return (
      <Redirect
        to={{
          pathname: props.match.path.replace(/\/name\/:roleName\/?/, ''),
          search: props.location.search,
        }}
      />
    );
  }

  const toggleMi = () => {
    setMachineIdentityChecked(!machineIdentityChecked);
  };

  const updateMiStatus = () => {
    // create the MI if it is going from unchecked to checked state and vice versa
    if (machineIdentityChecked) {
      props.enableMachineIdentity(
        oktaAuth,
        iamRole.accountId,
        iamRole.name,
        iamRole.id,
        iamRole.arn
      );
    } else {
      props.disableMachineIdentity(oktaAuth, iamRole.arn, iamRole.id);
    }
  };

  // enables the trust policy text area to reflect user input
  const handleTrustPolicyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTrustPolicy(e.target.value);
  };
  // saves new trust policy and updates text area to display value in state
  const saveUpdatedTrustPolicy = () => {
    props.updateRole(oktaAuth, {
      arn: iamRole.arn,
      id: iamRole.id,
      name: iamRole.name,
      trustPolicy: JSON.parse(trustPolicy),
      isMachineIdentity: iamRole.isMachineIdentity,
    });
    // on success, the IamRole state isn't updated fast enough to
    // reflect the new policy, so the text area will show user input until the
    // component is reloaded
  };
  const handleSave = () => {
    if (trustPolicy != currentRoleTrustPolicyString) {
      saveUpdatedTrustPolicy();
    }
    if (iamRole.isMachineIdentity != machineIdentityChecked) {
      updateMiStatus();
    }
  };

  const isPending =
    props.MICreationStatus === DataFetchStatus.DATA_FETCH_PENDING;
  const deleteJob = props.MIDeleteJobs.get(iamRole.arn);
  const deletePending =
    deleteJob && deleteJob.status === DeleteJobStatus.DATA_DELETE_PENDING;
  const iamDeleteJob = props.iamRoleDeleteJobs.get(iamRole.id);
  const isDeletePending =
    iamDeleteJob && iamDeleteJob.status === DeleteJobStatus.DATA_DELETE_PENDING;
  const deleteLoading = () => {
    return (
      <div className={styles.deleteLoad}>
        <StyledCircularProgress size={300} />
      </div>
    );
  };
  const isReadyToSubmit =
    !isPending &&
    trustPolicyIsValid &&
    (iamRole.isMachineIdentity != machineIdentityChecked ||
      trustPolicy != currentRoleTrustPolicyString);

  const handleBack = () => {
    props.history.push({
      pathname: props.match.path.replace(/\/new\/?/, ''),
      search: props.location.search,
    });
  };

  return (
    <>
      <Button color="secondary" onClick={handleBack}>
        <ArrowLeftIcon size="small" /> <span>Back</span>
      </Button>{' '}
      <Button
        onClick={handleSave}
        color="primary"
        className={styles.saveButton}
        disabled={!isReadyToSubmit}
        id="saveButton"
      >
        {isPending ? (
          <Spinner color="light" size="sm" />
        ) : (
          <CheckIcon size="small" />
        )}{' '}
        <span>Save</span>
      </Button>
      <Button
        className={styles.deleteButton}
        onClick={() => {
          props.openConfirmation(() => {
            props.deleteRole(oktaAuth, iamRole);
          }, iamRole.name);
        }}
      >
        Delete{' '}
        {isDeletePending ? (
          <Spinner color="light" size="sm" />
        ) : (
          <TrashIcon size="small" />
        )}
      </Button>
      {isDeletePending ? deleteLoading() : null}
      <p />
      <StyledForm>
        <FormGroup row={true}>
          <Label sm={3}>
            <b>Role Name:</b>
          </Label>{' '}
          <Col sm={9} className={styles.iamRoleName}>
            {iamRole.name}
          </Col>
        </FormGroup>
        <FormGroup row={true}>
          <Label sm={3}>
            <b>Role ARN:</b>
          </Label>{' '}
          <Col sm={9} className={styles.iamRoleArn}>
            {iamRole.arn}
          </Col>
        </FormGroup>
        <FormGroup check={true} inline={true}>
          <Label check={true}>
            <>
              <b>Enable ALKS Access (Machine Identity)</b>{' '}
              {isPending && <Spinner size="sm" />}
              {deletePending && <Spinner size="sm" />}
              <span
                data-tooltip-id="machineIdentityInfo"
                data-tooltip-place="bottom"
                data-tooltip-class-name={styles.tooltip}
                >
                  <InfoIcon />
              </span>
              <Tooltip id="machineIdentityInfo">
                <p>
                  Checking this box will tell ALKS to update this role to a
                  machine identity, which means this role will be allowed to
                  manage roles through ALKS for this account.
                </p>
                To enable a role to manage roles for mutiple accounts, create a
                machine identity role in each account in which you would like to
                manage roles and have each of those machine identity roles trust
                a central role.
              </Tooltip>
              <Input
                id="isMachineIdentity"
                type="checkbox"
                checked={machineIdentityChecked}
                onChange={toggleMi}
                className={styles.iamRoleMachineIdentityCheckbox}
              />
            </>
          </Label>{' '}
        </FormGroup>
        <p />
        <FormGroup>
          <Label>
            <b>Trust Policy:</b>
          </Label>{' '}
          <pre>
            <StyledInput
              id="trustPolicy"
              rows={10}
              disabled={props.accountSecurityLevel === '1' ? false : true}
              type="textarea"
              className={styles.iamRoleTrustPolicy}
              value={trustPolicy}
              onChange={handleTrustPolicyChange}
            />
          </pre>
        </FormGroup>
      </StyledForm>
    </>
  );
};

const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const StyledForm = styled(Form)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }

  &&:disabled,
  &&[readonly] {
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;

export default IamRole;
