import * as React from 'react';
import { Badge } from 'reactstrap';
import { reservedTags } from '../../models';
import styles from './Tag.module.scss';
import { Tag } from '../../models';
import { useTheme } from '../../styles/useTheme';
import { XIcon } from '@primer/octicons-react';

export interface OwnProps {
  tag: Tag;
  color?: string;
  closeable?: boolean;
}

export interface DispatchProps {
  onClose: (tag: Tag) => void;
}

export type Props = OwnProps & DispatchProps;

/**
 * Class to represent a Tag
 */
export const TagBadge: React.FC<Props> = ({
  tag,
  color,
  closeable,
  onClose,
}) => {
  const [theme] = useTheme();

  const handleClose = onClose.bind(null, tag);
  const showCloseButton = closeable ?? !reservedTags[tag.key];

  return (
    <Badge
      color="none"
      style={{
        backgroundColor:
          color ??
          (reservedTags[tag.key] ? theme.darkPrimaryColor : theme.primaryColor),
      }}
    >
      {tag.key}
      {showCloseButton && (
        <button
          onClick={handleClose}
          aria-label="Dismiss"
          type="button"
          className={styles.close}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <XIcon />
        </button>
      )}
    </Badge>
  );
};

export default TagBadge;
