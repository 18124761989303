import memoize from 'memoizee';
import { IamRole } from '../models';

/**
 * Gets a list of IAM roles whose name matches a filter string
 *
 * @param iamRoles - the list of IAM roles to search
 * @param filter - the string to filter by
 * @return a list of IAM roles matching the filter string
 */
export const getMatchingIamRoles = memoize(
  <T extends IamRole>(iamRoles: T[], filter: string): T[] =>
    iamRoles.filter((iamRole) =>
      iamRole.name.toLowerCase().includes(filter.toLowerCase())
    )
);

export default getMatchingIamRoles;
