import { createSelector } from 'reselect';
import { RoleView, getRoleView } from '../models';
import { getRoles, getRolePreferences } from './common';

/**
 * Get the combined role and role preference objects from state
 */
export const getRoleViews = createSelector(
  [getRoles, getRolePreferences],
  (roles, rolePreferences): Map<string, RoleView> =>
    Array.from(roles.values()).reduce(
      (roleViews, role) =>
        roleViews.set(role.id, getRoleView(role, rolePreferences[role.id])),
      new Map<string, RoleView>()
    )
);

export default getRoleViews;
