import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import { unregister } from './registerServiceWorker';
import configureStore from './store';
import App from './App';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root') as HTMLElement
);

// TODO: Remove this line when all clients have unregistered their service workers (after a couple weeks from this commit date)
unregister();
