import * as React from 'react';
import './Fireworks.css';

export const Fireworks: React.FC = () => {
  const [hidden, setHidden] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setHidden(true);
    }, 6000);
  }, []);

  return hidden ? null : (
    <div className="pyro">
      <div className="before" />
      <div className="after" />
    </div>
  );
};

export default Fireworks;
