import { createSelector } from 'reselect';
import { getIamRoles } from './common';
import { getActiveAccountId } from './getActiveAccountId';
import { getActiveIamRoleName } from './getActiveIamRoleName';
import { IamRole } from '../models';

/**
 * Gets the IAM Role record object for the active IAM Role
 */
export const getActiveIamRole = createSelector(
  [getActiveIamRoleName, getActiveAccountId, getIamRoles],
  (iamRoleName, accountId, iamRoles): IamRole | undefined => {
    return (
      Array.from(iamRoles.values())
        .filter(
          (iamRole) =>
            iamRole.accountId === accountId && iamRole.name === iamRoleName
        )
        /*  In the off chance that AWS ever returns two IAM roles for the same
          account with the same name but different role IDs, this sort
          guarantees that we always get the same IAM role regardless of the
          order in which it was received from AWS */
        .sort((a, b) => a.id.localeCompare(b.id))
        .shift()
    );
  }
);

export default getActiveIamRole;
