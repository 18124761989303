import { AppState } from '../store';
import { connect } from 'react-redux';
import RoleDurationPreference, {
  ModelProps,
  DispatchProps,
} from '../components/preferences/RoleDurationPreference';
import { setPreferredRoleDuration } from '../store/preferences/actions';
import { getActiveAccountRoles } from '../selectors/getActiveAccountRoles';
import { Dispatch } from '../store/shared/types';
import { toast } from 'react-toastify';

const mapStateToProps = (state: AppState): ModelProps => ({
  accountRoles: Array.from(getActiveAccountRoles(state).values()),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSaveDuration: (roleId: string, roleName: string, duration: number) => {
    dispatch(setPreferredRoleDuration(roleId, duration));
    toast(`${roleName} has been set to ${duration}hr preferred role duration`, {
      type: 'info',
      autoClose: 4000,
    });
  },
});

export const VisibleRoleDurationPreference = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleDurationPreference);

export default VisibleRoleDurationPreference;
