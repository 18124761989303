import { Job } from '../shared/types';
import { ArfAccountViolation } from '../../models';

export const ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED =
  'ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED';
export type ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED = typeof ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED;

export const ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED =
  'ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED';
export type ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED = typeof ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED;

export const ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED =
  'ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED';
export type ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED = typeof ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED;

interface ArfAccountViolationsStartedAction {
  type: ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED;
  accountId: string;
}

interface ArfAccountViolationsSucceededAction {
  type: ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED;
  accountId: string;
  arfAccountViolations: ArfAccountViolation[];
}

interface ArfAccountViolationsFailedAction {
  type: ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED;
  accountId: string;
  failureMessage: string;
}

export type ArfAccountViolationsActionType =
  | ArfAccountViolationsStartedAction
  | ArfAccountViolationsSucceededAction
  | ArfAccountViolationsFailedAction;

export interface ArfAccountViolationsState {
  fetchJobs: Map<string, Job>;
  byId: Map<string, ArfAccountViolation[]>;
}
