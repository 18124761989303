import * as t from 'io-ts';

export const ArfPolicy = t.type({
  href: t.string,
  id: t.string,
  name: t.string,
  version: t.number,
  engine_version: t.string,
  body_template: t.record(t.string, t.unknown),
  tags: t.array(t.string),
  created: t.string,
  updated: t.string,
});

export type ArfPolicy = t.TypeOf<typeof ArfPolicy>;

export default ArfPolicy;
