import { connect } from 'react-redux';
import { AppState } from '../store';
import { loadData } from '../util/loadData';
import arfAccountRemediationsLoader from '../util/dataLoaders/arfAccountRemediationsLoader';
import getActiveArfAccountRemediations from '../selectors/getActiveArfAccountRemediations';
import ArfAccountRemediations from '../components/old_arf/ArfAccountRemediations';

const mapStateToProps = (state: AppState) => {
  const arfAccountRemediations = getActiveArfAccountRemediations(state);
  return {
    arfAccountRemediations,
  };
};

export const ActiveArfAccountRemediations = loadData(
  [arfAccountRemediationsLoader],
  connect(mapStateToProps)(ArfAccountRemediations)
);

export default ActiveArfAccountRemediations;
