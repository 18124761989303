import { getActiveAccount } from './getActiveAccount';
import { RoleView } from '../models';
import { createSelector } from 'reselect';
import getRoleViews from './getRoleViews';

export const getActiveAccountRoles = createSelector(
  [getActiveAccount, getRoleViews],
  (activeAccount, roles): Map<string, RoleView> =>
    activeAccount
      ? activeAccount.roles.reduce(
          (activeAccountRoles, roleId) =>
            roles.has(roleId)
              ? activeAccountRoles.set(roleId, roles.get(roleId) as RoleView)
              : activeAccountRoles,
          new Map<string, RoleView>()
        )
      : new Map<string, RoleView>()
);

export default getActiveAccountRoles;
