import {
  combineReducers,
  createStore,
  applyMiddleware,
  Middleware,
} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { accountReducer } from './accounts/reducer';
import { roleReducer } from './roles/reducer';
import { modalReducer } from './modal/reducer';
import { keyReducer } from './keys/reducer';
import {
  preferenceReducer,
  initialState as PreferenceInitialState,
} from './preferences/reducer';
import { searchReducer } from './search/reducer';
import {
  announcementReducer,
  initialState as AnnouncementInitialState,
} from './announcement/reducer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { history } from './history';
import { iamRoleReducer } from './iamRoles/reducer';
import { roleTypeReducer } from './roleTypes/reducer';
import { userInfoReducer } from './userInfo/reducer';
import { DataFetchStatus } from './shared/types';
import thunkErrorHandler from './middlewares/thunkErrorHandler';
import { arfAccountReducer } from './arfAccount/reducer';
import { arfAccountViolationsReducer } from './arfAccountViolations/reducer';
import { arfAccountRemediationsReducer } from './arfAccountRemediations/reducer';
import { userAccessReducer } from './userManagement/reducer';
import { costDataReducer } from './costManagement/reducer';
import { registerUserPreferenceListener } from './preferences/userPreferenceListener';

const dataReducer = combineReducers({
  accounts: accountReducer,
  roles: roleReducer,
  keys: keyReducer,
  iamRoles: iamRoleReducer,
  roleTypes: roleTypeReducer,
  userInfo: userInfoReducer,
  arfAccount: arfAccountReducer,
  arfAccountViolations: arfAccountViolationsReducer,
  arfAccountRemediations: arfAccountRemediationsReducer,
  users: userAccessReducer,
  costData: costDataReducer,
});

const uiReducer = combineReducers({
  modal: modalReducer,
  preferences: preferenceReducer,
  search: searchReducer,
  announcement: announcementReducer,
});

const rootReducer = combineReducers({
  data: dataReducer,
  ui: uiReducer,
  router: connectRouter(history),
});

export type AppState = ReturnType<typeof rootReducer>;

const middleWares: Middleware[] = [
  thunkErrorHandler,
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  middleWares.push(logger);
}

export default function configureStore(initialState?: AppState) {
  const preferenceState = PreferenceInitialState;
  const announcementState = AnnouncementInitialState;
  initialState = Object.assign(
    {
      ui: {
        preferences: preferenceState,
        announcement: {
          ...announcementState,
          currentAnnouncement: null,
          fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
          show: false,
        },
      },
    },
    initialState
  );

  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleWares)
  );

  registerUserPreferenceListener(store);

  return store;
}
