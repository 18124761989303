import memoize from 'memoizee';
import { AccountView } from '../models';
import { filterAccounts } from './filterAccounts';
import { parseTerms } from './parseTerms';

/**
 * Gets a list of accounts whose name or ID matches a filter string
 * Matches by either the account id, account alias, or account friendly name, all which are case insensitive
 *
 * @param accounts - the list of accounts to search
 * @param filter - the string to filter by
 * @param searchTags - the tags currently being filtered on
 * @package excludeTags - whether to exclude tags or not
 * @return a list of accounts matching the filter string and tags
 */
export const getMatchingAccounts = memoize(
  (accounts: AccountView[], filter: string): AccountView[] =>
    filterAccounts(accounts, parseTerms(filter))
);

export default getMatchingAccounts;
