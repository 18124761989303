import { AnyAction } from 'redux';
import { ThunkAction as ReduxThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '..';
import * as t from 'io-ts';

export enum DataFetchStatus {
  DATA_FETCH_UNSTARTED = 'DATA_FETCH_UNSTARTED',
  DATA_FETCH_SUCCEEDED = 'DATA_FETCH_SUCCEEDED',
  DATA_FETCH_PENDING = 'DATA_FETCH_PENDING',
  DATA_FETCH_FAILED = 'DATA_FETCH_FAILED',
}

const isDataFetchStatus = (u: unknown): u is DataFetchStatus =>
  typeof u === 'string' && u in DataFetchStatus;

export const DataFetchStatusType = new t.Type(
  'DataFetchStatus',
  isDataFetchStatus,
  (i, c) => (isDataFetchStatus(i) ? t.success(i) : t.failure(i, c)),
  (a) => a
);

export enum DeleteJobStatus {
  DATA_DELETE_UNSTARTED = 'DATA_DELETE_UNSTARTED',
  DATA_DELETE_SUCCEEDED = 'DATA_DELETE_SUCCEEDED',
  DATA_DELETE_PENDING = 'DATA_DELETE_PENDING',
  DATA_DELETE_FAILED = 'DATA_DELETE_FAILED',
}

export enum UpdateJobStatus {
  DATA_UPDATE_UNSTARTED = 'DATA_UPDATE_UNSTARTED',
  DATA_UPDATE_SUCCEEDED = 'DATA_UPDATE_SUCCEEDED',
  DATA_UPDATE_PENDING = 'DATA_UPDATE_PENDING',
  DATA_UPDATE_FAILED = 'DATA_UPDATE_FAILED',
}

export type Dispatch = ThunkDispatch<AppState, null, AnyAction>;

/**
 * An Asynchronous action that accepts a dispatch function and returns a promise
 * which yields the type specified by `R`
 */
export type ThunkAction<R> = ReduxThunkAction<
  Promise<R>,
  AppState,
  null,
  AnyAction
>;

export interface Job {
  status: DataFetchStatus;
  failureMessage?: string;
}

export interface DeleteJob {
  status: DeleteJobStatus;
  failureMessage?: string;
}

export interface UpdateJob {
  status: UpdateJobStatus;
  failureMessage?: string;
}
