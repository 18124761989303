import { ThunkAction } from '../store/shared/types';
import {
  iamRoleCreateMachineIdentityStarted,
  iamRoleCreateMachineIdentityFailed,
  iamRoleCreateMachineIdentitySucceeded,
} from '../store/iamRoles/actions';
import { toast } from 'react-toastify';
import ALKS, { MachineIdentity } from 'alks.js';
import * as config from '../config';
import { OktaAuth } from '@okta/okta-auth-js';
import { getErrorMessage } from '../util/getErrorMessage';

/**
 * Creates a machine identity for a role
 *
 * @param jobId - The ID to use for the create MI role job
 * @param auth - the Okta auth object from state
 * @param accountId - the ID of the account of the role
 * @param iamRoleName - the name of the role
 * @param iamRoleId - the id of the role
 * @param iamRoleArn - the arn of the role
 */
export const createRoleMachineIdentity = (
  jobId: number,
  auth: OktaAuth,
  accountId: string,
  iamRoleName: string,
  iamRoleId: string,
  iamRoleArn: string
): ThunkAction<void> => async (dispatch) => {
  dispatch(
    iamRoleCreateMachineIdentityStarted(
      jobId,
      accountId,
      iamRoleName,
      iamRoleArn
    )
  );

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(iamRoleCreateMachineIdentityFailed(jobId, reason));
    throw e;
  }

  let machineIdentity: MachineIdentity;
  try {
    const alks = ALKS.create({
      baseUrl: config.default.alks.baseUrl,
      accessToken,
    });

    machineIdentity = await alks.addRoleMachineIdentity({
      roleARN: iamRoleArn,
    });
  } catch (e) {
    const reason = `An error occured when creating a machine identity with arn ${iamRoleArn}: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(iamRoleCreateMachineIdentityFailed(jobId, reason));
    throw e;
  }

  toast(
    `Machine identity created successfully: ${machineIdentity.machineIdentityArn}`,
    {
      type: 'success',
    }
  );
  dispatch(iamRoleCreateMachineIdentitySucceeded(jobId, iamRoleArn, iamRoleId));
};

export default createRoleMachineIdentity;
