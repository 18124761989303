import { HomePage } from '../components/main/HomePage';
import { accountAndRoleLoader } from '../util/dataLoaders/accountAndRoleLoader';
import { loadData } from '../util/loadData';
import { userInfoLoader } from '../util/dataLoaders/userInfoLoader';
import { preferenceLoader } from '../util/dataLoaders/preferenceLoader';

export const ConnectedHomePage = loadData(
  [accountAndRoleLoader, userInfoLoader],
  loadData([preferenceLoader], HomePage)
);

export default ConnectedHomePage;
