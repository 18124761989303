import { createSelector } from 'reselect';
import { getUserAccessById } from './common';
import { User } from '../models';
import { getActiveAccount } from './getActiveAccount';

/**
 * returns the users for the active account
 */
export const getActiveAccountUsers = createSelector(
  [getUserAccessById, getActiveAccount],
  (usersById, account): User[] =>
    account
      ? account.users
          .map((userId) => usersById.get(userId))
          .filter((user): user is User => !!user)
      : []
);

export default getActiveAccountUsers;
