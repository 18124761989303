import { DefaultTheme } from 'styled-components';
import { Tag } from '../../models';
import {
  PreferenceActionType,
  PREFERENCE_ACCOUNT_STARRED,
  PREFERENCE_ACCOUNT_UNSTARRED,
  PREFERENCE_TOGGLE_ACCOUNT_STARRED,
  PREFERENCE_SET_PREFERRED_ROLE,
  PREFERENCE_SET_PREFERRED_ROLE_DURATION,
  PREFERENCE_ADD_TAG,
  PREFERENCE_REMOVE_TAG,
  PREFERENCE_BULK_ADD_TAGS,
  ADD_GLOBAL_TAG,
  REMOVE_GLOBAL_TAG,
  READ_ANNOUNCEMENT,
  PREFERENCES_ARE_LOADING,
  PREFERENCES_FAILED,
  PREFERENCES_SUCCEEDED,
  PUT_PREFERENCES_FAILED,
  PUT_PREFERENCES_STARTED,
  PUT_PREFERENCES_SUCCEEDED,
  SET_THEME,
  PreferenceState,
} from './types';

export const accountStarred = (accountId: string): PreferenceActionType => ({
  type: PREFERENCE_ACCOUNT_STARRED,
  accountId,
});

export const accountUnstarred = (accountId: string): PreferenceActionType => ({
  type: PREFERENCE_ACCOUNT_UNSTARRED,
  accountId,
});

export const toggleAccountStarred = (
  accountId: string
): PreferenceActionType => ({
  type: PREFERENCE_TOGGLE_ACCOUNT_STARRED,
  accountId,
});

export const setPreferredRole = (
  accountId: string,
  roleId: string
): PreferenceActionType => ({
  type: PREFERENCE_SET_PREFERRED_ROLE,
  accountId,
  roleId,
});

export const setPreferredRoleDuration = (
  roleId: string,
  duration: number
): PreferenceActionType => ({
  type: PREFERENCE_SET_PREFERRED_ROLE_DURATION,
  roleId,
  duration,
});

export const addTag = (accountId: string, tag: Tag): PreferenceActionType => ({
  type: PREFERENCE_ADD_TAG,
  accountId,
  tag,
});

export const removeTag = (
  accountId: string,
  tag: Tag
): PreferenceActionType => ({
  type: PREFERENCE_REMOVE_TAG,
  accountId,
  tag,
});

export const addTags = (
  tagsByAccountId: Record<string, Record<string, Tag>>
): PreferenceActionType => ({
  type: PREFERENCE_BULK_ADD_TAGS,
  tagsByAccountId,
});

export const addGlobalTag = (tag: Tag): PreferenceActionType => ({
  type: ADD_GLOBAL_TAG,
  tag,
});

export const removeGlobalTag = (tag: Tag): PreferenceActionType => ({
  type: REMOVE_GLOBAL_TAG,
  tag,
});

export const readAnnouncement = (
  announcementId: string
): PreferenceActionType => ({
  type: READ_ANNOUNCEMENT,
  announcementId,
});

export const preferencesFetchStarted = (): PreferenceActionType => ({
  type: PREFERENCES_ARE_LOADING,
});

export const preferencesFetchHasFailed = (
  reason: string
): PreferenceActionType => ({
  type: PREFERENCES_FAILED,
  failureMessage: reason,
});

export const preferencesFetchHasSucceeded = (
  preferences: PreferenceState
): PreferenceActionType => ({
  type: PREFERENCES_SUCCEEDED,
  preferences,
});

export const putPreferenceStarted = (): PreferenceActionType => ({
  type: PUT_PREFERENCES_STARTED,
});

export const putPreferenceFailed = (reason: string): PreferenceActionType => ({
  type: PUT_PREFERENCES_FAILED,
  failureMessage: reason,
});

export const putPreferenceSucceeded = (): PreferenceActionType => ({
  type: PUT_PREFERENCES_SUCCEEDED,
});

export const setTheme = (theme: DefaultTheme): PreferenceActionType => ({
  type: SET_THEME,
  theme,
});
