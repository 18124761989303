import { AppState } from '../store';
import NewRoleButton from '../components/iamRole/NewRoleButton';
import { getActiveAccount } from '../selectors/getActiveAccount';
import { getRoles } from '../selectors/common';
import { connect } from 'react-redux';
import { getIamCapableRole } from '../util/getIamCapableRole';

const mapStateToProps = (state: AppState) => {
  const activeAccount = getActiveAccount(state);
  const iamCapableRole =
    activeAccount && getIamCapableRole(activeAccount, getRoles(state));

  return {
    activeAccount,
    iamCapableRole,
  };
};

export const VisibleNewRoleButton = connect(mapStateToProps)(NewRoleButton);

export default VisibleNewRoleButton;
