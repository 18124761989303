import newrelic from '../newrelic';
import { toast } from 'react-toastify';

interface AlksError {
  errors: string[];
}

const isAlksError = (error: any): error is AlksError => {
  return error.hasOwnProperty('errors') && Array.isArray(error.errors);
};

export const logError = (error: Error) => {
  console.error(error);
  newrelic.noticeError(error);
  if (error.message) {
    toast(error.message, { type: 'error', autoClose: 20000 });
  } else if (isAlksError(error)) {
    error.errors.forEach((errorMessage) => {
      toast(errorMessage, { type: 'error', autoClose: 20000 });
    });
  }
};
