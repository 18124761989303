import { createSelector } from 'reselect';
import { getAccountViews } from './getAccountViews';
import { AccountView } from '../models';

export const getStarredAccounts = createSelector(
  [getAccountViews],
  (accountViews): AccountView[] =>
    Array.from(accountViews.values()).filter(
      (accountView) => accountView.starred
    )
);

export default getStarredAccounts;
