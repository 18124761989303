import * as React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { AccountView } from '../../models';

export interface ModelProps {
  account: AccountView | undefined;
}

export interface DispatchProps {
  onStarClick: (accountId: string) => void;
}

export type Props = ModelProps & DispatchProps;

export class StarAccountPreference extends React.Component<Props> {
  public render() {
    if (!this.props.account) {
      return null;
    }
    return (
      <FormGroup>
        <Input
          type="switch"
          id="starAccountToggle"
          aria-labelledby="starAccountToggleLabel"
          checked={this.props.account.starred}
          onChange={this.onStarClick(this.props.account.id)}
        />
      </FormGroup>
    );
  }

  private onStarClick = (accountId: string) => () => {
    this.props.onStarClick(accountId);
  };
}

export default StarAccountPreference;
