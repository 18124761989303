import { Role, Key } from '../../models';
import {
  ROLES_ARE_LOADING,
  ROLES_FAILED,
  ROLES_SUCCEEDED,
  NEW_KEY,
} from './types';

export const rolesFetchStarted = () => ({
  type: ROLES_ARE_LOADING,
});

export const rolesFetchHasSucceeded = (roles: Role[]) => ({
  type: ROLES_SUCCEEDED,
  roles,
});

export const rolesFetchHasFailed = (reason: string) => ({
  type: ROLES_FAILED,
  failureMessage: reason,
});

export const addKeyToRole = (key: Key, roleId: string) => ({
  type: NEW_KEY,
  key,
  roleId,
});
