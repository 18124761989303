import * as React from 'react';
import SearchBar from '../accountList/SearchBar';
import { User, SkypieaAccount, ExportUser } from '../../models';
import { CSVLink } from 'react-csv';
import {
  FormGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  Label,
} from 'reactstrap';
import {
  GearIcon,
  LinkIcon,
  DesktopDownloadIcon,
  PersonIcon,
} from '@primer/octicons-react';
import styles from './UserManagement.module.scss';
import * as config from '../../config';
import styled from 'styled-components';
import { Table } from '../table/Table';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { CollapsibleTableRow } from '../table/CollapsibleTableRow';
import Gravatar from 'react-gravatar';

export interface ModelProps {
  users: User[];
  accountId: string | undefined;
  accountName: string | undefined;
  skypieaAccount: SkypieaAccount | null;
}

export interface DispatchProps {
  updateFilterText: (filterText: string) => void;
}

export type Props = ModelProps & DispatchProps;

export const UserManagement: React.FC<Props> = ({
  users,
  accountId,
  accountName,
  updateFilterText,
  skypieaAccount,
}) => {
  const [hideSecurity, setHideSecurity] = React.useState(true);
  const toggleHideSecurity = (_e: React.SyntheticEvent) => {
    setHideSecurity(!hideSecurity);
  };
  const [hideAllAccess, setHideAllAccess] = React.useState(true);
  const toggleHideAllAccess = (_e: React.SyntheticEvent) => {
    setHideAllAccess(!hideAllAccess);
  };
  const accountOwners = skypieaAccount && skypieaAccount.accountOwners;
  const accountOwnerUsers = (accountOwners ?? [])
    .map((owner) =>
      users.find(
        (user) =>
          user.sAMAccountName.toLowerCase() ===
          owner.samAccountName.toLowerCase()
      )
    )
    .filter((user): user is User => !!user);

  if (!accountId) {
    return null;
  }

  const filteredUsers =
    hideSecurity || hideAllAccess
      ? users.filter((user) => {
          const userRoles = [...(user.roles.get(accountId) as string[])];
          if (hideSecurity) {
            const index = userRoles.indexOf('Security');
            if (index !== -1) {
              userRoles.splice(index, 1);
            }
          }
          if (hideAllAccess && userRoles.includes('CBO_USERS')) {
            return false;
          }
          // check if they still have roles after removing Security and CBO_USERS roles
          return userRoles.length > 0;
        })
      : users;

  const exportHeaders = [
    { label: 'AD Name', key: 'sAMAccountName' },
    { label: 'Full Name', key: 'displayName' },
    { label: 'Email', key: 'email' },
    { label: 'Position', key: 'title' },
    { label: 'Roles', key: 'role' },
  ];

  const exportData: ExportUser[] = [];
  filteredUsers.forEach((user) => {
    // @ts-ignore
    user.roles.get(accountId).forEach((key) => {
      // Create new "row" per role.
      const exportRow: ExportUser = {
        sAMAccountName: user.sAMAccountName,
        displayName: user.displayName,
        email: user.email,
        title: user.title,
        role: key,
      };
      exportData.push(exportRow);
    });
  });

  let snowUrl = config.default.userManagement.snowFormAddUser;
  // add query params too snowUrl if we have activeAccount
  if (accountName) {
    // check if the account name contains the word 'lab' otherwise it's a regular account
    const accType = accountName?.includes('lab') ? 'lab_account' : 'regular';
    snowUrl = snowUrl + '&sysparm_acctype=' + accType;
    // append the account name
    snowUrl = snowUrl + '&sysparm_account=' + accountName;
  }

  return (
    <UserManagementDiv>
      <h1>Manage Users</h1>
      <div>
        <p style={{ display: 'inline-block' }} className="lead">
          Find users who have access to this account
        </p>
        <UncontrolledDropdown
          setActiveFromChild={true}
          className={styles.gearMenu}
        >
          <StyledDropdownToggle
            tag="a"
            caret={true}
            tabIndex={0}
            aria-label="More"
          >
            <GearIcon size={24} />
          </StyledDropdownToggle>
          <StyledDropdownMenu>
            <StyledDropdownItem
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              className="snowForm"
              href={snowUrl}
            >
              Add/Remove User <LinkIcon size={16} />
            </StyledDropdownItem>
            <StyledDropdownItem>
              <StyledCSVLink
                headers={exportHeaders}
                data={exportData}
                filename={
                  skypieaAccount?.label.replace(/\s/g, '') + '-users.csv'
                }
                target="_blank"
              >
                Export to CSV <DesktopDownloadIcon size={16} />
              </StyledCSVLink>
            </StyledDropdownItem>
          </StyledDropdownMenu>
        </UncontrolledDropdown>
      </div>
      <SearchBar
        filterText={''}
        onFilterTextChange={updateFilterText}
        aria-label="filter users"
      />
      <div>
        <FormGroup>
          <div>
            <StyledCheckbox
              id="hideSecurityUsers"
              type="checkbox"
              checked={hideSecurity}
              onChange={toggleHideSecurity}
              label="Hide Security Users"
            />
            <Label for="hideSecurityUsers" style={{ marginLeft: '10px' }}>
              Hide Security Users
            </Label>
          </div>
          <div>
            <StyledCheckbox
              id="hideCboUsers"
              type="checkbox"
              checked={hideAllAccess}
              onChange={toggleHideAllAccess}
              label="Hide CBO Users"
            />
            <Label for="hideCboUsers" style={{ marginLeft: '10px' }}>
              Hide CBO Users
            </Label>
          </div>
        </FormGroup>
      </div>
      <br />

      <h3>Account Owners</h3>
      <UserTable
        users={accountOwnerUsers}
        accountId={accountId}
        defaultPageSize={5}
      />
      <br />
      <h3>Account Users</h3>
      <UserTable
        users={filteredUsers}
        accountId={accountId}
        defaultPageSize={10}
      />
    </UserManagementDiv>
  );
};

interface UserTableProps {
  users: User[];
  accountId: string;
  defaultPageSize?: number;
}

const UserTable: React.FC<UserTableProps> = ({
  users,
  accountId,
  defaultPageSize = 10,
}) => {
  return (
    <Table defaultPageSize={defaultPageSize}>
      <TableHead>
        <TableRow>
          <TableCell width={'15%'}>Network ID</TableCell>
          <TableCell width={'20%'}>Name</TableCell>
          <TableCell width={'20%'}>Email</TableCell>
          <TableCell width={'20%'}>Title</TableCell>
          <TableCell width={'25%'}>Department</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, i) => (
          <CollapsibleTableRow
            key={i}
            collapseContent={
              user.roles.get(accountId)?.length ? (
                <div>
                  <h6>Roles:</h6>
                  <List>
                    {(user.roles.get(accountId) as string[]).map((role, j) => (
                      <ListItem key={`${i}-${j}`}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={role} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ) : (
                <h6>No Roles In This Account</h6>
              )
            }
          >
            <TableCell>
              <Gravatar
                email={user.email}
                size={30}
                default="robohash"
                style={{ borderRadius: 15 }}
              />{' '}
              {user.sAMAccountName}
            </TableCell>
            <TableCell>{user.displayName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.title}</TableCell>
            <TableCell>{user.department}</TableCell>
          </CollapsibleTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const StyledCheckbox = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }

  &&:checked {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

const UserManagementDiv = styled.div`
  color: ${({ theme }) => theme.textColor};
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&:hover {
    color: ${({ theme }) => theme.navbar.dropdown.hover.textColor};
    background-color: ${({ theme }) =>
      theme.navbar.dropdown.hover.backgroundColor};
  }
`;

const StyledCSVLink = styled(CSVLink)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&:hover {
    color: ${({ theme }) => theme.navbar.dropdown.hover.textColor};
    background-color: ${({ theme }) =>
      theme.navbar.dropdown.hover.backgroundColor};
  }
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  && {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export default UserManagement;
