import * as React from 'react';
import { Badge } from 'reactstrap';
import { reservedTags } from '../../models';
import { useTheme } from '../../styles/useTheme';
import styles from './Tag.module.scss';
import { XIcon } from '@primer/octicons-react';

export interface OwnProps {
  value: string; // text to display
  include: boolean;
  onClose: () => void;
}

export type Props = OwnProps;

/**
 * Class to represent a Search Tag
 * This is very similar to a Tag component and this only exists because
 * Tags conditionally render nonimmutable tags for reservered key words and we do not want
 * that behavior for tags showing in the search bar
 */
export const SearchTag: React.FC<Props> = ({ value, include, onClose }) => {
  const [theme] = useTheme();
  const color = reservedTags[value]
    ? theme.darkPrimaryColor
    : theme.primaryColor;
  return (
    <Badge style={{ backgroundColor: color }} color="none">
      {include ? '' : '- '}
      {value}
      <button
        onClick={onClose}
        aria-label="Dismiss"
        type="button"
        className={styles.close}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <XIcon />
      </button>
    </Badge>
  );
};

export default SearchTag;
