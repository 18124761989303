import {
  ARF_ACCOUNT_FETCH_STARTED,
  ARF_ACCOUNT_FETCH_SUCCEEDED,
  ARF_ACCOUNT_FETCH_FAILED,
  ArfAccountActionType,
} from './types';
import { ArfAccount } from '../../models';

export const arfAccountFetchStarted = (
  accountId: string
): ArfAccountActionType => ({
  type: ARF_ACCOUNT_FETCH_STARTED,
  accountId,
});

export const arfAccountFetchSucceeded = (
  accountId: string,
  arfAccount: ArfAccount
): ArfAccountActionType => ({
  type: ARF_ACCOUNT_FETCH_SUCCEEDED,
  accountId,
  arfAccount: arfAccount,
});

export const arfAccountFetchFailed = (
  accountId: string,
  reason: string
): ArfAccountActionType => ({
  type: ARF_ACCOUNT_FETCH_FAILED,
  accountId,
  failureMessage: reason,
});
