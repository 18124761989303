import { createSelector } from 'reselect';
import { Key } from '../models';
import { getMostRecentKey } from '../util/getMostRecentKey';
import { getKeys, getRoles } from './common';

/**
 * Gets a map of role IDs to their role's most recently generated key, or
 * undefined if no key has been generated for that role
 */
export const getMostRecentKeys = createSelector(
  [getRoles, getKeys],
  (roles, keys): Map<string, Key> =>
    Array.from(roles.values()).reduce((map, role) => {
      const key = getMostRecentKey(role, keys);
      return key ? map.set(role.id, key) : map;
    }, new Map())
);

export default getMostRecentKeys;
