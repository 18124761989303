import * as React from 'react';
import KeyModalExport from './KeyModalExport';
import loadValueToClipboard from '../../util/loadValueToClipboard';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  InputGroup,
  Alert,
  Tooltip,
} from 'reactstrap';
import styles from './KeyModal.module.scss';
import styled from 'styled-components';

const KeyModal = ({
  closeModal,
  title,
  accessKey,
  secretKey,
  sessionToken,
  consoleUrl,
}) => {
  const [accessCopyTipOpen, setAccessCopyTipOpen] = React.useState(false);
  const [secretCopyTipOpen, setSecretCopyTipOpen] = React.useState(false);
  const [sessionCopyTipOpen, setSessionCopyTipOpen] = React.useState(false);

  const accessCopyToggle = () => {
    setAccessCopyTipOpen(!accessCopyTipOpen);
    setTimeout(() => setAccessCopyTipOpen(false), 1500);
  };

  const secretCopyToggle = () => {
    setSecretCopyTipOpen(!secretCopyTipOpen);
    setTimeout(() => setSecretCopyTipOpen(false), 1500);
  };

  const sessionCopyToggle = () => {
    setSessionCopyTipOpen(!sessionCopyTipOpen);
    setTimeout(() => setSessionCopyTipOpen(false), 1500);
  };

  const copyAccessKeyHandler = React.useCallback(
    () => loadValueToClipboard(accessKey),
    [accessKey]
  );
  const copySecretKeyHandler = React.useCallback(
    () => loadValueToClipboard(secretKey),
    [secretKey]
  );
  const copySessionTokenHandler = React.useCallback(
    () => loadValueToClipboard(sessionToken),
    [sessionToken]
  );

  return (
    <>
      <StyledModalHeader toggle={closeModal}>{title}</StyledModalHeader>
      <StyledModalBody>
        <Label for="accessKey">Access Key</Label>
        <InputGroup>
          <StyledInput
            type="text"
            name="accessKey"
            id="accessKey"
            readOnly={true}
            value={accessKey}
          />
          <Button id="accessCopy" onClick={copyAccessKeyHandler}>
            Copy
          </Button>
          <Tooltip
            target="accessCopy"
            isOpen={accessCopyTipOpen}
            toggle={accessCopyToggle}
            trigger="click"
          >
            Copied
          </Tooltip>
        </InputGroup>
        <br />
        <Label for="secretKey">Secret Key</Label>
        <InputGroup>
          <StyledInput
            type="text"
            name="secretKey"
            id="secretKey"
            readOnly={true}
            value={secretKey}
          />
          <Button id="secretCopy" onClick={copySecretKeyHandler}>
            Copy
          </Button>
          <Tooltip
            target="secretCopy"
            isOpen={secretCopyTipOpen}
            toggle={secretCopyToggle}
            trigger="click"
          >
            Copied
          </Tooltip>
        </InputGroup>
        <br />
        <Label for="sessionToken">Session Token</Label>
        <InputGroup>
          <StyledInput
            type="text"
            name="sessionToken"
            id="sessionToken"
            readOnly={true}
            value={sessionToken}
          />
          <Button id="sessionCopy" onClick={copySessionTokenHandler}>
            Copy
          </Button>
          <Tooltip
            target="sessionCopy"
            isOpen={sessionCopyTipOpen}
            toggle={sessionCopyToggle}
            trigger="click"
          >
            Copied
          </Tooltip>
        </InputGroup>
        <hr />
        <KeyModalExport
          awsAccessKey={accessKey}
          awsSecretKey={secretKey}
          awsSessionToken={sessionToken}
        />
      </StyledModalBody>
      <StyledModalFooter className={styles.footer}>
        <Alert color="warning" fade={false} className={styles.consoleAlert}>
          Warning: console link will expire in 15 minutes
        </Alert>
        <div className={styles.footerButtons}>
          <ConsoleButton
            href={consoleUrl}
            className={styles.consoleButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            AWS Console
          </ConsoleButton>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </StyledModalFooter>
    </>
  );
};

const StyledModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledModalFooter = styled(ModalFooter)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};

    &&:disabled,
    &&[readonly] {
      background-color: ${({ theme }) => theme.disabledColor};
    }
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: ${(props) => props.theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};

  && .close {
    color: ${({ theme }) => theme.textColor};
    text-shadow: 0 1px 0 ${({ theme }) => theme.modal.backgroundColor};
  }
`;

export const ConsoleButton = styled.a`
  && {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export default KeyModal;
