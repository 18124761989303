import { createSelector } from 'reselect';
import { getLocationPath } from './common';

/**
 * Gets the IAM role name from the current URL if it exists, undefined otherwise
 */
export const getActiveIamRoleName = createSelector([getLocationPath], (path):
  | string
  | undefined => {
  const fields = path.split('/');
  const i = fields.findIndex((field) => field === 'iamRoles');
  const iamRoleName =
    i > -1 && fields.length > i + 2 ? fields[i + 2] : undefined;
  return iamRoleName === '' ? undefined : iamRoleName;
});

export default getActiveIamRoleName;
