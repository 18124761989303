import { RoleType } from '../../models';
import { DataFetchStatus } from '../shared/types';

export const ROLE_TYPES_ARE_LOADING = 'ROLE_TYPES_ARE_LOADING';
export type ROLE_TYPES_ARE_LOADING = typeof ROLE_TYPES_ARE_LOADING;

export const ROLE_TYPES_FAILED = 'ROLE_TYPES_FAILED';
export type ROLE_TYPES_FAILED = typeof ROLE_TYPES_FAILED;

export const ROLE_TYPES_SUCCEEDED = 'ROLE_TYPES_SUCCEEDED';
export type ROLE_TYPES_SUCCEEDED = typeof ROLE_TYPES_SUCCEEDED;

interface RoleTypesLoadingAction {
  type: ROLE_TYPES_ARE_LOADING;
}

interface RoleTypesFailedAction {
  type: ROLE_TYPES_FAILED;
  failureMessage: string;
}

interface RoleTypesSucceededAction {
  type: ROLE_TYPES_SUCCEEDED;
  roleTypes: RoleType[];
}

export type RoleTypesAction =
  | RoleTypesLoadingAction
  | RoleTypesFailedAction
  | RoleTypesSucceededAction;

export interface RoleTypeState {
  byId: Map<string, RoleType>;
  fetchStatus: DataFetchStatus;
  failureMessage?: string;
}
