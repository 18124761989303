import * as React from 'react';
import {
  FormGroup,
  Input,
  Button,
  InputGroup,
  Form,
} from 'reactstrap';
import { AccountView, convertToTag, Tag } from '../../models';
import { PlusIcon, TagIcon } from '@primer/octicons-react';
import { VisibleImportExportTagPreference } from '../../containers/VisibleImportExportTagPreference';
import styled from 'styled-components';
import { ActiveAccountTagBadge } from '../../containers/ActiveAccountTagBadge';

export interface ModelProps {
  account: AccountView | undefined;
  globalTags: Record<string, number>;
}

export interface DispatchProps {
  addTag: (accountId: string, tag: Tag) => void;
  removeTag: (accountId: string, tag: Tag) => void;
}

export type Props = ModelProps & DispatchProps;

export const TagPreference: React.FC<Props> = (props) => {
  const [input, setInput] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  if (!props.account) {
    return null;
  }

  const accId = props.account.id;
  const addTag = (accId: string, tagKey: string) => (e: React.FormEvent) => {
    e.preventDefault();
    if (tagKey !== '') {
      props.addTag(accId, convertToTag(tagKey));
      setInput('');
    }
  };

  return (
    <Form onSubmit={addTag(accId, input)}>
      <FormGroup>
        <InputGroup>
          <StyledInput
            list="tags"
            value={input}
            onChange={(e) => setInput(e.currentTarget.value)}
            aria-label="tag name"
            placeholder="Add a tag..."
          />
          <datalist id="tags">
            {Object.keys(props.globalTags).map((e) => {
              return <option key={e} value={e} />;
            })}
          </datalist>
          <Button onClick={addTag(accId, input)} aria-label="add tag">
            <PlusIcon />
          </Button>
        </InputGroup>
        <br />
        <Button size="sm" onClick={toggle}>
          Import/Export Tags
        </Button>
        <VisibleImportExportTagPreference
          isModalOpen={modalOpen}
          modalToggle={toggle}
        />
        <hr className="my-2" />
        <TagIcon size="medium" />
        <div>
          {Object.values(props.account.tags).map((tag) => {
            return (
              <span key={tag.key}>
                <ActiveAccountTagBadge key={tag.key} tag={tag} />{' '}
              </span>
            );
          })}
        </div>
      </FormGroup>
    </Form>
  );
};

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default TagPreference;
