import { connect } from 'react-redux';
import { NewIamRole, OwnProps, Props } from '../components/iamRole/NewIamRole';
import { AppState } from '../store';
import { Dispatch } from '../store/shared/types';
import { createIamRole, RequestDetails } from '../operations/createIamRole';
import {
  getRoles,
  getRoleTypes,
  getIamRoleFormJobId,
} from '../selectors/common';
import { getActiveAccount } from '../selectors/getActiveAccount';
import { iamRoleFormJobIdUpdated } from '../store/iamRoles/actions';
import { getIamRoleFormJobStatus } from '../selectors/getIamRoleFormJobStatus';
import { getActiveAccountId } from '../selectors/getActiveAccountId';
import { loadData } from '../util/loadData';
import { roleTypeLoader } from '../util/dataLoaders/roleTypeLoader';
import { iamRoleLoader } from '../util/dataLoaders/iamRoleLoader';
import { getIamRolesForActiveAccount } from '../selectors/getIamRolesForActiveAccount';
import { getIamCapableRole } from '../util/getIamCapableRole';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState) => {
  const activeAccount = getActiveAccount(state);
  const iamCapableRole =
    activeAccount && getIamCapableRole(activeAccount, getRoles(state));

  return {
    activeAccount,
    iamCapableRole,
    roleTypes: getRoleTypes(state),
    iamRoleFormJobId: getIamRoleFormJobId(state),
    creationStatus: getIamRoleFormJobStatus(state),
    accountId: getActiveAccountId(state),
    iamRoles: getIamRolesForActiveAccount(state),
  };
};
type MappedPropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (
    jobId: number,
    auth: OktaAuth,
    accountId: string,
    nonIamRoleName: string,
    details: RequestDetails
  ) => dispatch(createIamRole(jobId, auth, accountId, nonIamRoleName, details)),
  setIamRoleFormJobId: (jobId: number) =>
    dispatch(iamRoleFormJobIdUpdated(jobId)),
});
type MappedPropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (
  stateProps: MappedPropsFromState,
  dispatchProps: MappedPropsFromDispatch,
  ownProps: OwnProps
): Props => {
  const newDispatchProps = {
    ...dispatchProps,
    onSubmit: (auth: OktaAuth, details: RequestDetails) =>
      stateProps.activeAccount && stateProps.iamCapableRole
        ? dispatchProps.onSubmit(
            stateProps.iamRoleFormJobId,
            auth,
            stateProps.activeAccount.id,
            stateProps.iamCapableRole.name,
            details
          )
        : undefined,
  };

  return {
    ...ownProps,
    ...stateProps,
    ...newDispatchProps,
  };
};

export const ConnectedNewIamRole = loadData(
  [roleTypeLoader, iamRoleLoader],
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewIamRole)
);

export default ConnectedNewIamRole;
