import {
  keyFetchStarted,
  keyFetchHasSucceeded,
  keyFetchHasFailed,
} from '../store/keys/actions';
import { addKeyToRole } from '../store/roles/actions';
import ALKS from 'alks.js';
import * as config from '../config';
import * as Model from '../models';
import { OktaAuth } from '@okta/okta-auth-js';
import { getErrorMessage } from '../util/getErrorMessage';
import { toast } from 'react-toastify';

export const fetchKeys = (
  auth: OktaAuth,
  account: Model.Account,
  role: Model.Role,
  duration: number
) => {
  return async (dispatch: any): Promise<Model.Key> => {
    dispatch(keyFetchStarted(account.id, role.id));

    // TODO - [RWB] explore creating a true `Int` type, like discussed here: https://spin.atomicobject.com/2018/11/05/using-an-int-type-in-typescript/
    if (duration < 1 || duration > 36) {
      dispatch(
        keyFetchHasFailed(
          role.id,
          `Invalid key duration: ${duration} must be between 1 and 36`
        )
      );
      throw new Error(
        `Invalid key duration: ${duration} must be between 1 and 36`
      );
    }

    let accessToken: string | undefined;
    try {
      accessToken = auth.getAccessToken();
      if (!accessToken) {
        throw new Error('access token is undefined');
      }
    } catch (e) {
      const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
        e
      )}`;
      toast(reason, { type: 'error' });
      dispatch(keyFetchHasFailed(role.id, reason));
      throw e;
    }

    const alks = ALKS.create({
      baseUrl: config.default.alks.baseUrl,
      accessToken,
    });

    let credentials: ALKS.Key;
    try {
      credentials = await alks.getIAMKeys({
        account: `${account.id}/ALKS${role.name}`,
        role: role.name,
        sessionTime: duration,
      });
    } catch (e) {
      dispatch(keyFetchHasFailed(role.id, getErrorMessage(e)));
      throw new Error(getErrorMessage(e));
    }

    const creationTime = new Date(Date.now());
    const expirationTime = new Date(creationTime);
    expirationTime.setHours(expirationTime.getHours() + duration);

    const key: Model.Key = {
      id: -1,
      roleId: role.id,
      accessKey: credentials.accessKey,
      secretKey: credentials.secretKey,
      sessionToken: credentials.sessionToken,
      consoleUrl: credentials.consoleURL,
      createdAt: creationTime,
      expiresAt: expirationTime,
    };

    dispatch(keyFetchHasSucceeded(key));
    dispatch(addKeyToRole(key, role.id));
    return key;
  };
};
