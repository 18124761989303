import memoize from 'memoizee';
import { AccountView, Role } from '../models';
import { getRolePriority } from '../util/getRolePriority';

/**
 * Gets the highest priority IAM role for an account or undefined if it doesn't have any IAM roles
 *
 * @param account - the account whose preferred role should be retrieved
 * @param roles - a map of role IDs to roles
 * @return the account's highest priority role
 */
export const getHighestPriorityIAMRole = memoize(
  (account: AccountView, roles: Map<string, Role>): Role | undefined => {
    const role = account.roles
      .map((roleId) => roles.get(roleId))
      .filter((role): role is Role => !!role)
      .sort((a, b) => getRolePriority(a) - getRolePriority(b))
      .shift();

    return role;
  }
);

export default getHighestPriorityIAMRole;
