import { ArfAccount } from '../models';
import { getActiveAccountId } from './getActiveAccountId';
import { getArfAccountById } from './common';
import { createSelector } from 'reselect';

export const getActiveArfAccount = createSelector(
  [getArfAccountById, getActiveAccountId],
  (arfAccounts, accountId): ArfAccount | undefined => {
    return accountId ? arfAccounts?.get(accountId) : undefined;
  }
);

export default getActiveArfAccount;
