import { AccountView, Term, score } from '../models';
import memoize from 'memoizee';
import { getExclusionaryTerms } from './getExclusionaryTerms';
import { compareAccounts } from './compareAccounts';
import { calculateScore } from './calculateScore';

/**
 * Filters a list of accounts by a list of search terms
 * @return the filtered list of accounts
 */
export const filterAccounts = memoize(
  (accounts: AccountView[], filterTerms: Term[]): AccountView[] => {
    const exclusionaryTermNames = getExclusionaryTerms(
      filterTerms
    ).map((term) => term.tag.toLowerCase());

    const accountsMinusExclusions = accounts.filter(
      (account) =>
        !Object.values(account.tags).some((tag) =>
          exclusionaryTermNames.includes(tag.key.toLowerCase())
        )
    );

    // If filter contains only exclusions, return every account that isn't excluded
    if (filterTerms.length === exclusionaryTermNames.length) {
      return accountsMinusExclusions;
    }
    // Otherwise return only accounts that match some other term
    else {
      return (
        accountsMinusExclusions
          // calculate a score for each account
          .map((account) => ({
            ...account,
            [score]: calculateScore(account, filterTerms),
          }))
          // remove accounts with a zero score (meaning they didn't match)
          .filter((account) => account[score] > 0)
          // sort the accounts
          .sort((a, b) => compareAccounts(a, b))
      );
    }
  }
);

export default filterAccounts;
