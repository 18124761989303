import * as React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import styled from 'styled-components';

export const Adblock: React.FC = () => {
  return (
    <div>
      <StyledCard className="text-center">
        <CardBody>
          <CardTitle tag="h5">Welcome!</CardTitle>
          <CardSubtitle>
            It looks like you're using an adblocker &#9785;
          </CardSubtitle>
        </CardBody>
        <CardBody>
          <CardSubtitle>Please whitelist us or consider donating.</CardSubtitle>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            size="sm"
            tag="a"
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            target="_blank"
            rel="noopener noreferrer"
            outline={true}
          >
            Become a ALKS Supporter
          </Button>
        </CardFooter>
      </StyledCard>
    </div>
  );
};

const StyledCard = styled(Card)`
  && {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.users.card.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default Adblock;
