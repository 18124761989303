import { createSelector } from 'reselect';
import { getIamRoleFormJob } from './getIamRoleFormJob';
import { DataFetchStatus } from '../store/shared/types';

/**
 * Gets the status of the job that is currently being tracked by the New IAM Role form
 */
export const getIamRoleFormJobStatus = createSelector(
  [getIamRoleFormJob],
  (job): DataFetchStatus =>
    job ? job.status : DataFetchStatus.DATA_FETCH_UNSTARTED
);

export default getIamRoleFormJobStatus;
