import { fetchIamRoles } from '../../operations/fetchIamRoles';
import { getActiveAccountId } from '../../selectors/getActiveAccountId';
import { DataFetchStatus } from '../../store/shared/types';
import { DataLoader } from '../loadData';

export const iamRoleLoader: DataLoader = (state, dispatch, auth) => {
  const accountId = getActiveAccountId(state);
  if (!accountId) {
    throw new Error('No active account is found');
  }
  const activeAccountFetchJob = state.data.iamRoles.fetchJobs.get(accountId);
  const iamRoleFetchStatus = activeAccountFetchJob
    ? activeAccountFetchJob.status
    : DataFetchStatus.DATA_FETCH_UNSTARTED;

  switch (iamRoleFetchStatus) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchIamRoles(auth, accountId));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      const message = activeAccountFetchJob
        ? activeAccountFetchJob.failureMessage
        : 'Failed to fetch IAM Roles but no reason was given';
      throw new Error(message);
    }
    default: {
      throw new Error('invalid value for IAM role fetch status');
    }
  }
};

export default iamRoleLoader;
