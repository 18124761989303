import { connect } from 'react-redux';
import NavigationBar from '../components/main/NavigationBar';
import { showAnnouncement } from '../store/announcement/actions';
import { AppState } from '../store';
import { loadData } from '../util/loadData';
import { announcementsLoader } from '../util/dataLoaders/announcementsLoader';
import { logout } from '../operations/logout';
import {
  getAnnouncements,
  getAnnouncementsRead,
  getEmail,
  getUsername,
} from '../selectors/common';
import { readAnnouncement } from '../store/preferences/actions';
import { preferenceLoader } from '../util/dataLoaders/preferenceLoader';
import { userInfoLoader } from '../util/dataLoaders/userInfoLoader';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState) => ({
  announcements: Object.values(getAnnouncements(state)),
  announcementsRead: getAnnouncementsRead(state),
  username: getUsername(state),
  email: getEmail(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showAnnouncement: (announcementId: string) => {
    dispatch(showAnnouncement(announcementId));
    dispatch(readAnnouncement(announcementId));
  },
  logout: (auth: OktaAuth) => dispatch(logout(auth)),
});

export const VisibleNavigationBar = loadData(
  [announcementsLoader, userInfoLoader],
  loadData(
    [preferenceLoader],
    connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
  )
);

export default VisibleNavigationBar;
