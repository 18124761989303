import memoize from 'memoizee';

export const getErrorMessage = memoize((e: unknown): string => {
  if (e instanceof Error) {
    return e.message;
  }
  if (typeof e === 'object' && !!e) {
    try {
      return JSON.stringify(e);
    } catch (jsonError) {
      // fall through
    }
  }
  return String(e);
});

export default getErrorMessage;
