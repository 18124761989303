import { AppState } from '../store';
import AdvancedSearch, {
  ModelProps,
  DispatchProps,
} from '../components/accountList/AdvancedSearch';
import { getGlobalTags } from '../selectors/common';
import { addSearchTag, removeSearchTag } from '../store/search/actions';
import { connect } from 'react-redux';
import { getUniqueTagTerms } from '../selectors/getUniqueTagTerms';

const mapStateToProps = (state: AppState): ModelProps => ({
  tags: getUniqueTagTerms(state),
  globalTags: getGlobalTags(state),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  addTag: (tag: string, include: boolean) =>
    dispatch(addSearchTag(tag, include)),
  removeTag: (tag: string) => dispatch(removeSearchTag(tag)),
});

export const VisibleAdvancedSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearch);

export default VisibleAdvancedSearch;
