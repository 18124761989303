import {
  USER_INFO_FAILED,
  USER_INFO_IS_LOADING,
  USER_INFO_SUCCEEDED,
  UserInfoActionType,
} from './types';

export const userInfoFetchStarted = (): UserInfoActionType => ({
  type: USER_INFO_IS_LOADING,
});

export const userInfoFetchSucceeded = (
  username: string,
  email: string
): UserInfoActionType => ({
  type: USER_INFO_SUCCEEDED,
  username,
  email,
});

export const userInfoFetchFailed = (reason: string): UserInfoActionType => ({
  type: USER_INFO_FAILED,
  failureMessage: reason,
});
