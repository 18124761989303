import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { VisibleIamRoleList } from '../../containers/VisibleIamRoleList';
import { VisibleNewRoleButton } from '../../containers/VisibleNewRoleButton';
import SearchBar from '../accountList/SearchBar';
import styles from './FilterableIamRoleList.module.scss';

export type Props = RouteComponentProps;

export const FilterableIamRoleList: React.FC<Props> = (props) => {
  const [filterText, setFilterText] = React.useState('');

  return (
    <div className={styles.filterableIamRoleList}>
      <div className={styles.header}>
        <div className={styles.searchBar}>
          <SearchBar
            filterText={filterText}
            onFilterTextChange={setFilterText}
            autoFocus={true}
            aria-label="filter IAM roles"
          />
        </div>
        <div className={styles.newButton}>
          <VisibleNewRoleButton {...props} />
        </div>
      </div>
      <div className={styles.iamRoleList}>
        <VisibleIamRoleList {...props} filterText={filterText} />
      </div>
    </div>
  );
};

export default FilterableIamRoleList;
