import { LinkExternalIcon, TrashIcon } from '@primer/octicons-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import urljoin from 'url-join';
import styles from './IamRoleList.module.scss';
import { IamRole, Role, RoleView } from '../../models';
import { DeleteJob, DeleteJobStatus } from '../../store/shared/types';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Table } from '../table/Table';
import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';

export interface OwnProps extends RouteComponentProps {
  // TODO: move this filter into redux state
  filterText: string;
}

export interface ModelProps {
  iamRoles: IamRole[];
  deleteJobs: Map<string, DeleteJob>;
  iamCapableRole: Role | undefined;
  roleViews: Map<string, RoleView>;
}

export interface DispatchProps {
  openConfirmation: (f: () => void, s: string) => void;
  deleteRole: (auth: OktaAuth, iamRole: IamRole) => void;
}

export type Props = ModelProps & DispatchProps & OwnProps;

const KEY_CODE_ENTER = 13;
const KEY_CODE_SPACE = 32;

export const IamRoleList: React.FC<Props> = ({
  match,
  location,
  iamRoles,
  history,
  openConfirmation,
  deleteRole,
  deleteJobs,
  iamCapableRole,
  roleViews,
}) => {
  const { oktaAuth } = useOktaAuth();

  let duration = 1;
  if (iamCapableRole) {
    const roleView = roleViews.get(iamCapableRole.id);
    if (roleView) {
      duration = roleView.duration;
    }
  }

  const onClick = (iamRole) => {
    // this function is uncovered in tests since its difficult to cover and would only test to see if the function was called
    history.push({
      pathname: urljoin(match.path, 'name', iamRole.name),
      search: location.search,
    });
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>IAM Role Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {iamRoles.map((iamRole) => {
          const deleteJob = deleteJobs.get(iamRole.id);
          const pending =
            deleteJob &&
            deleteJob.status === DeleteJobStatus.DATA_DELETE_PENDING;
          const iamDestination = encodeURIComponent(
            `https://console.aws.amazon.com/iam/home#/roles/${iamRole.name}`
          );
          const iamUrl = `console?accountId=${iamCapableRole?.accountId}&role=${iamCapableRole?.name}&duration=${duration}&destination=${iamDestination}`;
          return (
            <TableRow key={iamRole.id}>
              <TableCell
                aria-label={iamRole.name}
                tabIndex={0}
                role="button"
                onKeyDown={(e) =>
                  (e.keyCode === KEY_CODE_ENTER ||
                    e.keyCode === KEY_CODE_SPACE) &&
                  onClick(iamRole)
                }
                onClick={() => onClick(iamRole)}
                className={styles.clickableTableCell}
              >
                <span className={styles.iamRoleName}>
                  {iamRole.name}
                </span>
                <span className={styles.iamRoleButtons}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation(); 
                      window.open(iamUrl);
                    }}
                    aria-label='View in AWS IAM Console'
                    className={styles.iamBtn}
                  >
                    View in AWS IAM Console <LinkExternalIcon size="small" />
                  </Button>
                  <Button
                    className={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      // this function is uncovered in tests since its difficult to cover and would only test to see if the function was called
                      openConfirmation(() => {
                        deleteRole(oktaAuth, iamRole);
                      }, iamRole.name);
                    }}
                    aria-label={`Delete ${iamRole.name}`}
                  >
                    Delete{' '}
                    {pending ? (
                      <Spinner size="sm" />
                    ) : (
                      <TrashIcon size="small" />
                    )}
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default IamRoleList;
