import ALKS from 'alks.js';
import * as config from '../config';
import { Account, reservedTags, Role, Tag } from '../models';
import {
  accountsFetchHasSucceeded,
  accountsFetchStarted,
  accountsFetchHasFailed,
} from '../store/accounts/actions';
import {
  rolesFetchHasSucceeded,
  rolesFetchStarted,
  rolesFetchHasFailed,
} from '../store/roles/actions';
import { ThunkAction } from '../store/shared/types';
import { hashCode } from '../util/hashCode';
import { addTags } from '../store/preferences/actions';
import { OktaAuth } from '@okta/okta-auth-js';
import { getErrorMessage } from '../util/getErrorMessage';

export const fetchAccountsAndRoles = (auth: OktaAuth): ThunkAction<void> => {
  return async (dispatch) => {
    dispatch(accountsFetchStarted());
    dispatch(rolesFetchStarted());

    let accessToken: string | undefined;
    try {
      accessToken = auth.getAccessToken();
      if (!accessToken) {
        throw new Error('access token is undefined');
      }
    } catch (e) {
      const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
        e
      )}`;
      dispatch(accountsFetchHasFailed(reason));
      dispatch(rolesFetchHasFailed(reason));
      throw e;
    }

    let alksAccounts: ALKS.Account[];

    try {
      const alks = ALKS.create({
        baseUrl: config.default.alks.baseUrl,
        accessToken,
      });

      alksAccounts = await alks.getAccounts();
    } catch (e) {
      const reason = `An error occured when fetching accounts and roles from ALKS: ${getErrorMessage(
        e
      )}`;
      dispatch(accountsFetchHasFailed(reason));
      dispatch(rolesFetchHasFailed(reason));
      throw e;
    }

    const roles: Role[] = alksAccounts.map((account) => ({
      id: `role-${hashCode(account.account)}`,
      accountId: account.account.substring(0, 12),
      name: account.role,
      keys: [],
      maxKeyDuration: Math.min(account.maxKeyDuration, 12),
      iamEnabled: account.iamKeyActive,
    }));

    const accounts: Account[] = Object.values(
      alksAccounts.reduce((acc: { [key: string]: Account }, alksAccount) => {
        const splitAccount: string[] = alksAccount.account.split(' - ');
        const alias: string = splitAccount.length > 1 ? splitAccount[1] : '';
        const accountId: string = alksAccount.account.substring(0, 12);

        if (!acc[accountId]) {
          acc[accountId] = {
            id: accountId,
            name: alias,
            securityLevel: alksAccount.securityLevel,
            roles: roles
              .filter((role) => role.accountId === accountId)
              .map((role) => role.id),
            skypieaAccount: alksAccount.skypieaAccount,
            users: [],
          };
        }

        return acc;
      }, {})
    );

    dispatch(rolesFetchHasSucceeded(roles));
    dispatch(accountsFetchHasSucceeded(accounts));

    // Populate a few app-owned tags for each account
    const tagsByAccountId = accounts.reduce(
      (acc: Record<string, Record<string, Tag>>, account: Account) => {
        const label = account.skypieaAccount?.label.toLowerCase();

        // Skip accounts that have not been fully set up yet
        if (!label) {
          return acc;
        }

        const tagsByTagKey: Record<string, Tag> = {};

        // Apply reserved tags based on account type
        if (account.name.includes('awscoxautolabs')) {
          const tag = reservedTags['labs'];
          tagsByTagKey[tag.key] = tag;
        } else if (label.includes('non prod') || account.name.endsWith('np')) {
          const tag = reservedTags['nonprod'];
          tagsByTagKey[tag.key] = tag;
        } else if (label.includes('pre prod') || account.name.endsWith('pp')) {
          const tag = reservedTags['preprod'];
          tagsByTagKey[tag.key] = tag;
        } else if (label.includes('prod')) {
          const tag = reservedTags['prod'];
          tagsByTagKey[tag.key] = tag;
        }

        // Apply reserved tags based on account security level
        if (account.securityLevel === '1') {
          const tag = reservedTags['l1'];
          tagsByTagKey[tag.key] = tag;
        } else if (account.securityLevel === '2') {
          const tag = reservedTags['l2'];
          tagsByTagKey[tag.key] = tag;
        }

        return { ...acc, [account.id]: tagsByTagKey };
      },
      {}
    );
    dispatch(addTags(tagsByAccountId));
  };
};
