import { ArfDirective } from './ArfDirective';
import { ArfPolicy } from './ArfPolicy';
import { ArfSlackChannel } from './ArfSlackChannel';
import * as t from 'io-ts';

export const ArfAccount = t.type({
  href: t.string,
  id: t.string,
  aws_account_id: t.string,
  aws_account_alias: t.string,
  security_level: t.number,
  account_type: t.string,
  enabled: t.boolean,
  channels: t.array(ArfSlackChannel),
  policies: t.array(ArfPolicy),
  directives: t.array(ArfDirective),
  tags: t.array(t.string),
  metadata: t.any,
  created: t.string,
  updated: t.string,
});

export type ArfAccount = t.TypeOf<typeof ArfAccount>;

/**
 * Sets default values for any missing fields on the input object
 */
export function applyDefaultArfAccountProperties(
  arfAccount: Partial<ArfAccount>
): Partial<ArfAccount> {
  const newArfAccount = { ...arfAccount };

  newArfAccount.channels = arfAccount.channels ?? [];
  newArfAccount.policies = arfAccount.policies ?? [];
  newArfAccount.directives = arfAccount.directives ?? [];
  newArfAccount.tags = arfAccount.tags ?? [];

  return newArfAccount;
}

export default ArfAccount;
