import { DataFetchStatus } from '../shared/types';
import {
  USER_INFO_FAILED,
  USER_INFO_IS_LOADING,
  USER_INFO_SUCCEEDED,
  UserInfoActionType,
  UserInfoState,
} from './types';

const initialState: UserInfoState = {
  username: '',
  email: '',
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
};

export const userInfoReducer = (
  state = initialState,
  action: UserInfoActionType
): UserInfoState => {
  switch (action.type) {
    case USER_INFO_IS_LOADING:
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
        failureMessage: undefined,
      };

    case USER_INFO_FAILED:
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
        failureMessage: action.failureMessage,
      };

    case USER_INFO_SUCCEEDED: {
      return {
        ...state,
        username: action.username,
        email: action.email,
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        failureMessage: undefined,
      };
    }

    default:
      return state;
  }
};
