import { ModalActionType, ModalType, SHOW_MODAL, HIDE_MODAL } from './types';

export const showModal = (modal: ModalType, props: any): ModalActionType => ({
  type: SHOW_MODAL,
  modal,
  props,
});

export const hideModal = (): ModalActionType => ({
  type: HIDE_MODAL,
});

export const showConfirmationModal = (
  message: string,
  title: string,
  func: () => void
): ModalActionType => ({
  type: SHOW_MODAL,
  modal: ModalType.Confirm,
  props: {
    message,
    title,
    func,
  },
});
