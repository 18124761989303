import { ThunkAction } from '../store/shared/types';
import {
  putPreferenceFailed,
  putPreferenceStarted,
  putPreferenceSucceeded,
} from '../store/preferences/actions';
import * as config from '../config';
import { PreferenceState } from '../store/preferences/types';
import { fetchWithTimeout } from '../util/fetchWithTimeout';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';

export const putUserPreference = (
  auth: OktaAuth,
  userId: string,
  preferences: PreferenceState
): ThunkAction<void> => async (dispatch) => {
  dispatch(putPreferenceStarted());

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    dispatch(putPreferenceFailed(reason));
    throw e;
  }

  let response: Response;
  const endpoint = `${config.default.service.url}${config.default.service.userPreferenceService.path}${userId}`;
  const body = JSON.stringify(preferences);

  try {
    response = await fetchWithTimeout(endpoint, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Api-Key': config.default.service.apiKey,
        'Content-Type': 'application/json',
      },
      body,
    });
  } catch (e) {
    dispatch(putPreferenceFailed(getErrorMessage(e)));
    throw e;
  }

  if (response.status === 200) {
    dispatch(putPreferenceSucceeded());
  } else {
    dispatch(
      putPreferenceFailed(
        `Failed to put user preferences, received ${response.status} status`
      )
    );
  }
};
