import {
  ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED,
  ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED,
  ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED,
  ArfAccountViolationsActionType,
} from './types';
import { ArfAccountViolation } from '../../models';

export const arfAccountViolationsFetchStarted = (
  accountId: string
): ArfAccountViolationsActionType => ({
  type: ARF_ACCOUNT_VIOLATIONS_FETCH_STARTED,
  accountId,
});

export const arfAccountViolationsFetchSucceeded = (
  accountId: string,
  arfAccountViolations: ArfAccountViolation[]
): ArfAccountViolationsActionType => ({
  type: ARF_ACCOUNT_VIOLATIONS_FETCH_SUCCEEDED,
  accountId,
  arfAccountViolations: arfAccountViolations,
});

export const arfAccountViolationsFetchFailed = (
  accountId: string,
  reason: string
): ArfAccountViolationsActionType => ({
  type: ARF_ACCOUNT_VIOLATIONS_FETCH_FAILED,
  accountId,
  failureMessage: reason,
});
