import { createSelector } from 'reselect';
import { AccountView } from '../models';
import { getAccountViews } from './getAccountViews';

/**
 * Gets the default account from the redux state
 */
export const getDefaultAccount = createSelector([getAccountViews], (accounts):
  | AccountView
  | undefined => {
  const sortedAccounts = Array.from(accounts.values()).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const starred = sortedAccounts.filter((a) => a.starred);
  const unstarred = sortedAccounts.filter((a) => !a.starred);
  return [...starred, ...unstarred].shift();
});

export default getDefaultAccount;
