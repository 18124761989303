import newrelic from '../newrelic';

/**
 * Logs action and sends to New Relic on tab clicks
 * @param tab
 * @param user
 */
export const logClick = (tab: string, user: string) => {
  const actionName = tab + 'ClickAction'; // e.g. CostTabClickAction
  newrelic.addPageAction(actionName, {
    user,
  });
};
