import * as Model from '../../models';
import { DataFetchStatus } from '../shared/types';

export const KEYS_ARE_LOADING = 'KEYS_ARE_LOADING';
export type KEYS_ARE_LOADING = typeof KEYS_ARE_LOADING;

export const KEYS_SUCCEEDED = 'KEYS_SUCCEEDED';
export type KEYS_SUCCEEDED = typeof KEYS_SUCCEEDED;

export const KEYS_FAILED = 'KEYS_FAILED';
export type KEYS_FAILED = typeof KEYS_FAILED;

interface KeysAreLoadingAction {
  type: KEYS_ARE_LOADING;
  accountId: string;
  roleId: string;
}

interface KeysSucceeded {
  type: KEYS_SUCCEEDED;
  key: Model.Key;
}

interface KeysFailed {
  type: KEYS_FAILED;
  roleId: string;
  failureMessage: string;
}

export type KeysActionType = KeysAreLoadingAction | KeysSucceeded | KeysFailed;

export interface KeyState {
  fetchStatus: DataFetchStatus;
  byId: Map<number, Model.Key>;
  allIds: number[];
}
