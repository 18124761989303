import { fetchArfAccountViolations } from '../../operations/fetchArfAccountViolations';
import { getActiveAccountId } from '../../selectors/getActiveAccountId';
import { DataFetchStatus } from '../../store/shared/types';
import { DataLoader } from '../loadData';

export const arfAccountViolationsLoader: DataLoader = (
  state,
  dispatch,
  auth
) => {
  const accountId = getActiveAccountId(state);
  if (!accountId) {
    throw new Error('No active account is found');
  }

  const activeAccountViolationsFetchJob = state.data.arfAccountViolations.fetchJobs.get(
    accountId
  );
  const arfAccountViolationsFetchStatus = activeAccountViolationsFetchJob
    ? activeAccountViolationsFetchJob.status
    : DataFetchStatus.DATA_FETCH_UNSTARTED;
  switch (arfAccountViolationsFetchStatus) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchArfAccountViolations(auth, accountId));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      const message = activeAccountViolationsFetchJob
        ? activeAccountViolationsFetchJob.failureMessage
        : 'Failed to fetch ARF Account Violations Data but no reason was given';
      throw new Error(message);
    }
    default: {
      throw new Error('invalid value for ARF account violations fetch status');
    }
  }
};

export default arfAccountViolationsLoader;
