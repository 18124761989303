export interface RoleData {
  name: string;
  accountId: string;
  path: string;
}

/**
 * Returns the name of a role from a role ARN
 *
 * @param arn - the role arn
 * @return the role name
 */
export const parseRoleArn = (arn: string): RoleData => {
  const colonSections = arn.split(':');
  const pathSections = colonSections[colonSections.length - 1].split('/');
  return {
    name: pathSections[pathSections.length - 1],
    accountId: colonSections[4],
    path: pathSections.slice(1, pathSections.length - 1).join('/'),
  };
};

export default parseRoleArn;
