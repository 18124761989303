import * as React from 'react';
import styles from './Failure.module.scss';

export interface OwnProps {
  reason?: string; // the reason for failure
}

export type Props = OwnProps;

export const Failure: React.FC<OwnProps> = ({ reason }) => {
  const message = reason || 'An unexpected error occured';
  return (
    <section className={styles.failureContainer}>
      <section className={styles.failure}>
        <h2>Failure</h2>
        <p>{message}</p>
      </section>
    </section>
  );
};

export default Failure;
