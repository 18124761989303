import { AccountView, Tag, Term, TEXT } from '../models';
import memoize from 'memoizee';

export const accountMatchesTerm = memoize(
  (account: AccountView, term: Term): boolean => {
    if (term.type === TEXT) {
      // If term matches account name
      if (
        !!account.name &&
        account.name.toLowerCase().includes(term.text.toLowerCase())
      ) {
        return true;
      }

      // If term matches any of the account's tags
      if (
        Object.values(account.tags).reduce(
          (acc: boolean, tag: Tag) => tag.key.includes(term.text) || acc,
          false
        )
      ) {
        return true;
      }

      // If term matches at least 4 characters of the account ID
      if (term.text.length > 4 && account.id.includes(term.text)) {
        return true;
      }

      // If term matches SkypieaAccount label
      if (
        !!account.skypieaAccount &&
        !!account.skypieaAccount.label &&
        account.skypieaAccount.label
          .toLowerCase()
          .includes(term.text.toLowerCase())
      ) {
        return true;
      }
    } else {
      // Return false if tag term is exclusive (exclusion based on tags is handled elsewhere)
      if (!term.include) {
        return false;
      }

      // If the term exactly matches any tags on the account (ie. "+blu" doesn't match the tag "blue")
      if (
        Object.values(account.tags)
          .map((tag) => tag.key.toLowerCase())
          .includes(term.tag.toLowerCase())
      ) {
        return true;
      }
    }
    // Return false if nothing matches
    return false;
  }
);

export default accountMatchesTerm;
