import { connect } from 'react-redux';
import Account, {
  DispatchProps,
  ModelProps,
} from '../components/account/Account';
import * as Model from '../models';
import { AppState } from '../store';
import { showKeyModal } from '../operations/showKeyModal';
import { setPreferredRole } from '../store/preferences/actions';
import { getActiveAccount } from '../selectors/getActiveAccount';
import { getActiveAccountRoles } from '../selectors/getActiveAccountRoles';
import { getKeysFetchStatus } from '../selectors/common';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState): ModelProps => {
  return {
    account: getActiveAccount(state),
    accountRoles: Array.from(getActiveAccountRoles(state).values()),
    fetchKeyStatus: getKeysFetchStatus(state),
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getKeys: (
    auth: OktaAuth,
    account: Model.Account,
    role: Model.Role,
    duration: number
  ) => dispatch(showKeyModal(auth, account, role, duration)),
  setPreferredRole: (accountId: string, roleId: string) =>
    dispatch(setPreferredRole(accountId, roleId)),
});

const ActiveAccount = connect(mapStateToProps, mapDispatchToProps)(Account);

export default ActiveAccount;
