import * as React from 'react';
import loadValueToClipboard from '../../util/loadValueToClipboard';
import {
  Button,
  ButtonDropdown,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Nav,
  NavItem,
  NavLink,
  Tooltip,
} from 'reactstrap';
import styled from 'styled-components';

export interface OwnProps {
  awsAccessKey: string;
  awsSecretKey: string;
  awsSessionToken: string;
}

export type Props = OwnProps;

export enum ExportTypes {
  Windows = 'Windows',
  Linux = 'Linux',
  Powershell = 'Powershell',
  CredentialsFile = 'Credentials File',
}

export const DefaultTabs = [ExportTypes.Windows, ExportTypes.Linux];
export const OtherTabItems = [
  ExportTypes.Powershell,
  ExportTypes.CredentialsFile,
];

const determineDefaultExportType = (): ExportTypes => {
  if (navigator.userAgent.includes('Windows')) {
    return ExportTypes.Windows;
  }
  return ExportTypes.Linux;
};

const initialState = {
  selectedExportType: determineDefaultExportType(),
  isOtherDropDownOpen: false,
  isCopyDropDownOpen: false,
  tooltipIsOpen: false,
};

export type State = Readonly<typeof initialState>;

class KeyModalExport extends React.Component<Props, State> {
  public readonly state: State = initialState;

  public render() {
    const styles = {
      copyButtonStyle: {
        float: 'right',
        padding: '3px 0px 0px 0px',
      } as React.CSSProperties,

      textAreaStyle: {
        wrap: 'off',
        whiteSpace: 'pre',
        height: '8em',
      } as React.CSSProperties,
    };

    const {
      selectedExportType,
      isOtherDropDownOpen,
      isCopyDropDownOpen,
    } = this.state;

    const navItems = Object.values(ExportTypes)
      .filter((val: ExportTypes) => DefaultTabs.includes(val))
      .map((val: ExportTypes) => (
        <NavItem id={val} key={val}>
          <StyledNavLink
            href="#"
            active={val === selectedExportType}
            onClick={this.changeExportType(val)}
          >
            {val}
          </StyledNavLink>
        </NavItem>
      ));

    const otherItems = Object.values(ExportTypes)
      .filter((val: ExportTypes) => OtherTabItems.includes(val))
      .map((val: ExportTypes) => (
        <StyledDropdownItem
          id={val.replace(/\s/g, '')} // Remove white space for id's (ex: Credentials File -> CredentialsFile)
          key={val}
          onClick={this.changeExportType(val)}
          active={val === selectedExportType}
        >
          {val}
        </StyledDropdownItem>
      ));

    const exportText = this.generateExportText();

    const copyExportTextHandler = () => {
      loadValueToClipboard(exportText);
    };

    return (
      <>
        <Label for="exportText">Environment Variables</Label>
        <StyledNav tabs={true}>
          {navItems}
          <Dropdown
            id="otherTab"
            nav={true}
            isOpen={isOtherDropDownOpen}
            toggle={this.toggleOtherDropDown}
          >
            <StyledDropdownToggle nav={true} caret={true}>
              Other
            </StyledDropdownToggle>
            <StyledDropdownMenu>{otherItems}</StyledDropdownMenu>
          </Dropdown>
        </StyledNav>
        <StyledInput
          type="textarea"
          name="exportText"
          id="exportText"
          value={exportText}
          style={styles.textAreaStyle}
          readOnly={true}
        />
        <ButtonDropdown
          isOpen={isCopyDropDownOpen}
          toggle={this.toggleCopyDropDown}
          style={styles.copyButtonStyle}
        >
          <Button id="copyButton" onClick={copyExportTextHandler}>
            Copy
          </Button>
          <Tooltip
            target="copyButton"
            isOpen={this.state.tooltipIsOpen}
            toggle={this.toggle}
            trigger="click"
          >
            Copied
          </Tooltip>
          <DropdownToggle caret={true} />
          <StyledDropdownMenu>
            <StyledDropdownItem
              id="downloadButton"
              onClick={downloadTextAsFile('exportText')}
            >
              Download
            </StyledDropdownItem>
          </StyledDropdownMenu>
        </ButtonDropdown>
      </>
    );
  }

  public generateExportText(): string {
    const { selectedExportType } = this.state;
    const { awsAccessKey, awsSecretKey, awsSessionToken } = this.props;

    switch (selectedExportType) {
      case ExportTypes.Windows: {
        return `set AWS_ACCESS_KEY_ID=${awsAccessKey}
set AWS_SECRET_ACCESS_KEY=${awsSecretKey}
set AWS_SESSION_TOKEN=${awsSessionToken}
set AWS_DEFAULT_REGION=us-east-1`;
      }
      case ExportTypes.Linux: {
        return `export AWS_ACCESS_KEY_ID=${awsAccessKey}
export AWS_SECRET_ACCESS_KEY=${awsSecretKey}
export AWS_SESSION_TOKEN=${awsSessionToken}
export AWS_DEFAULT_REGION=us-east-1`;
      }
      case ExportTypes.Powershell: {
        return `$env:AWS_ACCESS_KEY_ID="${awsAccessKey}"
$env:AWS_SECRET_ACCESS_KEY="${awsSecretKey}"
$env:AWS_SESSION_TOKEN="${awsSessionToken}"
$env:AWS_DEFAULT_REGION="us-east-1"`;
      }
      case ExportTypes.CredentialsFile: {
        return `[default]
aws_access_key_id=${awsAccessKey}
aws_secret_access_key=${awsSecretKey}
aws_session_token=${awsSessionToken}`;
      }
      default: {
        return '';
      }
    }
  }

  private toggle = () => {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen });
    setTimeout(
      () => this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen }),
      1500
    );
  };

  private changeExportType = (exportType: ExportTypes) => (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.stopPropagation();
    this.setState({
      selectedExportType: exportType,
    });
  };

  private toggleOtherDropDown = () => {
    this.setState({ isOtherDropDownOpen: !this.state.isOtherDropDownOpen });
  };

  private toggleCopyDropDown = () => {
    this.setState({ isCopyDropDownOpen: !this.state.isCopyDropDownOpen });
  };
}

// this block is uncovered in tests cause its too difficult to test
const downloadTextAsFile = (elementId: string) => (
  e: React.MouseEvent<HTMLAnchorElement>
) => {
  e.stopPropagation();
  const inputElement = document.getElementById(elementId) as HTMLInputElement;
  const file = new Blob([inputElement.value], {
    type: 'application/octet-stream',
  });
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = 'credentials';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};

    &&:disabled,
    &&[readonly] {
      background-color: ${({ theme }) => theme.disabledColor};
    }
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&:hover {
    color: ${({ theme }) => theme.navbar.dropdown.hover.textColor};
    background-color: ${({ theme }) =>
      theme.navbar.dropdown.hover.backgroundColor};
  }
`;

export const StyledNavLink = styled(NavLink)`
  && {
    color: ${({ theme }) => theme.textColor};

    :hover {
      border-color: ${({ theme }) => theme.borderColor};
      border-bottom-color: rgba(0, 0, 0, 0);
    }
  }

  &&.active {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.card.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};
    border-bottom-color: ${({ theme }) => theme.card.backgroundColor};
  }
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  &&&& {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.card.backgroundColor};

    :hover {
      border-color: ${({ theme }) => theme.borderColor};
      border-bottom-color: rgba(0, 0, 0, 0);
    }
  }
`;

const StyledNav = styled(Nav)`
  && {
    border-bottom-color: rgba(0, 0, 0, 0);
  }
`;

export default KeyModalExport;
