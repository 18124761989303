import { AppState } from '../store';
import { connect } from 'react-redux';
import Announcement, {
  ModelProps,
  DispatchProps,
} from '../components/main/Announcement';
import { hideAnnouncement } from '../store/announcement/actions';
import { shouldShowAnnouncement } from '../selectors/shouldShowAnnouncement';
import { Dispatch } from '../store/shared/types';
import { getCurrentAnnouncement } from '../selectors/getCurrentAnnouncement';
import { announcementsLoader } from '../util/dataLoaders/announcementsLoader';
import { loadData } from '../util/loadData';

const mapStateToProps = (state: AppState): ModelProps => ({
  show: shouldShowAnnouncement(state),
  announcement: getCurrentAnnouncement(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  hideAnnouncement: () => dispatch(hideAnnouncement()),
});

export const VisibleAnnouncement = loadData(
  [announcementsLoader],
  connect(mapStateToProps, mapDispatchToProps)(Announcement)
);

export default VisibleAnnouncement;
