import { OktaAuth } from '@okta/okta-auth-js';
import * as React from 'react';
import { DataFetchStatus } from '../../store/shared/types';
import { useOktaAuth } from '@okta/okta-react';

export interface ModelProps {
  accountIds: string[];
  fetchStatuses: Map<string, DataFetchStatus>;
}

export interface DispatchProps {
  fetchIamRoles: (auth: OktaAuth, accountId: string) => void;
}

export type Props = ModelProps & DispatchProps;

/**
 * Initiates a fetch for iam roles for each account indicated by `accountIds`
 */
export const IamRolesFetcher: React.FC<Props> = ({
  accountIds,
  fetchStatuses,
  fetchIamRoles,
}) => {
  const { oktaAuth } = useOktaAuth();

  accountIds
    .map((id) => ({
      id,
      status: fetchStatuses.get(id) || DataFetchStatus.DATA_FETCH_UNSTARTED,
    }))
    .forEach(({ id, status }) => {
      status === DataFetchStatus.DATA_FETCH_UNSTARTED &&
        fetchIamRoles(oktaAuth, id);
    });
  return null;
};

export default IamRolesFetcher;
