import { createSelector } from 'reselect';
import { getCreateIamRoleJobs, getIamRoleFormJobId } from './common';
import { CreateJob } from '../store/iamRoles/types';

/**
 * Gets the IAM role creation job that is currently being tracked by the New IAM Role form
 */
export const getIamRoleFormJob = createSelector(
  [getCreateIamRoleJobs, getIamRoleFormJobId],
  (jobs, jobId): CreateJob | undefined => jobs.get(jobId)
);

export default getIamRoleFormJob;
