import * as React from 'react';
import { Alert } from 'reactstrap';
import styles from './Announcement.module.css';
import { Announcement as News } from '../../models';

export interface ModelProps {
  show: boolean;
  announcement: News | null;
}

export interface DispatchProps {
  hideAnnouncement: () => void;
}

export type Props = ModelProps & DispatchProps;

export const Announcement: React.FC<Props> = (props) =>
  props.announcement && (
    <div className={styles.announcementWrapper}>
      <Alert
        className={styles.announcement}
        color="danger"
        isOpen={props.show}
        toggle={props.hideAnnouncement}
      >
        {props.announcement && props.announcement.message}{' '}
        {props.announcement && props.announcement.link && (
          <a
            href={props.announcement.link}
            className="alert-link"
            target="_blank"
            tabIndex={1}
          >
            {props.announcement.linkText}
          </a>
        )}
      </Alert>
    </div>
  );

export default Announcement;
