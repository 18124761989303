import { AppState } from '../store';
import RolePreference, {
  ModelProps,
  DispatchProps,
} from '../components/preferences/RolePreference';
import { connect } from 'react-redux';
import { getActiveAccountRoles } from '../selectors/getActiveAccountRoles';
import { getActiveAccount } from '../selectors/getActiveAccount';
import { setPreferredRole } from '../store/preferences/actions';
import { toast } from 'react-toastify';

const mapStateToProps = (state: AppState): ModelProps => ({
  roles: Array.from(getActiveAccountRoles(state).values()),
  account: getActiveAccount(state),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  onSaveRole: (accountId: string, roleId: string) => {
    dispatch(setPreferredRole(accountId, roleId));
    toast('Role preference saved', { type: 'info', autoClose: 4000 });
  },
});

export const VisibleRolePreference = connect(
  mapStateToProps,
  mapDispatchToProps
)(RolePreference);

export default VisibleRolePreference;
