import { DataFetchStatus } from '../../store/shared/types';
import { DataLoader } from '../loadData';
import { fetchUserPreference } from '../../operations/fetchUserPreference';

export const preferenceLoader: DataLoader = (state, dispatch, auth) => {
  const status = state.ui.preferences.fetchStatus;
  const userId = state.data.userInfo.username;

  switch (status) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchUserPreference(auth, userId));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      // Don't crash the application if preferences fail to fetch. Instead, proceed with the default preferences
      // (the userPreferenceListener knows not to overwrite a user's preferences with the default prefs in this case)
      return true;
    }
    default: {
      throw new Error('invalid value for preference fetch status');
    }
  }
};

export default preferenceLoader;
