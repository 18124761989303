import {
  arfAccountViolationsFetchStarted,
  arfAccountViolationsFetchFailed,
  arfAccountViolationsFetchSucceeded,
} from '../store/arfAccountViolations/actions';
import * as config from '../config';
import { ThunkAction } from '../store/shared/types';
import { ArfAccountViolation, arfErrorToString, isArfError } from '../models';
import { fetch } from '../util/fetch';
import { OktaAuth } from '@okta/okta-auth-js';
import getErrorMessage from '../util/getErrorMessage';
import { toast } from 'react-toastify';

export type ArfResponse = ArfAccountViolation[] | undefined;

export const fetchArfAccountViolations = (
  auth: OktaAuth,
  accountId: string
): ThunkAction<void> => async (dispatch) => {
  dispatch(arfAccountViolationsFetchStarted(accountId));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
  } catch (e) {
    const reason = `An error occured when requesting an access token from Okta: ${getErrorMessage(
      e
    )}`;
    dispatch(arfAccountViolationsFetchFailed(accountId, reason));
    throw e;
  }

  const endpoint = `${config.default.service.url}${config.default.service.arf.path}/accounts/id/${accountId}/violations`;
  let response: Response;
  try {
    response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Api-Key': `${config.default.service.apiKey}`,
      },
    });
  } catch (e) {
    const reason = `An error occured while requesting the ARF violations data for account ${accountId}: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountViolationsFetchFailed(accountId, reason));
    throw e;
  }

  if (!response.ok) {
    const reason = `Received http status code while fetching ARF violations data: ${response.status}`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountViolationsFetchFailed(accountId, reason));
    throw new Error(reason);
  }

  const responseJson = await response.json();
  if (!responseJson) {
    const reason = `No response body was returned when fetching ARF violations data`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountViolationsFetchFailed(accountId, reason));
    throw new Error(reason);
  }

  if (isArfError(responseJson)) {
    const reason = `Received error from ARF while fetching violations data: ${responseJson.errorMessage}`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountViolationsFetchFailed(accountId, reason));
    console.error(`Error from ARF: ${arfErrorToString(responseJson)}`);
    throw new Error(reason);
  }

  if (Array.isArray(responseJson)) {
    const arfAccountViolationsData: ArfAccountViolation[] = responseJson;
    dispatch(
      arfAccountViolationsFetchSucceeded(accountId, arfAccountViolationsData)
    );
  } else {
    const reason = `The ARF violations data fetched for ${accountId} does not match the expected type structure`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountViolationsFetchFailed(accountId, reason));
    throw new Error(reason);
  }
};
