import { Term, TAG, TEXT } from '../models';
import memoize from 'memoizee';

const MAX_SEARCH_TERMS = Math.log2(Number.MAX_SAFE_INTEGER);

/**
 * Generates a list of search term objects from a filter string
 */
export const parseTerms = memoize(
  (filter: string): Term[] =>
    filter
      .trim() // remove extra whitespace
      .split(/\s+/) // extract terms separated by whitespace
      .slice(0, MAX_SEARCH_TERMS) // limit number of search terms
      .reverse() // reverse so that a term's score increases as its index decreases
      .map((term) => ({ text: term }))
      .map((term) =>
        term.text.startsWith('+') || term.text.startsWith('-')
          ? {
              ...term,
              type: TAG,
              tag: term.text.substring(1),
              include: term.text.startsWith('+'),
            }
          : {
              ...term,
              type: TEXT,
            }
      )
      .map((term, index) => ({
        ...term,
        score: Math.pow(2, index),
      })) as Term[]
);

export default parseTerms;
