import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import * as config from './config';
import { Nav } from './components/main/Nav';
import styles from './Routes.module.scss';
import { TokenManagement } from './components/main/TokenManagement';
import { ConnectedHomePage } from './containers/ConnectedHomePage';
import VisibleClickThruAction from './containers/VisibleClickThruAction';
import Error from './components/main/Error';
import history from './store/history';
import { oktaAuth } from './store/oktaAuth';

export type Props = unknown;

export const Routes: React.FC<Props> = () => {
  const restoreOriginalUri = React.useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      history.replace(
        toRelativeUrl(originalUri || '/', window.location.origin)
      );
    },
    []
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className={styles.routes}>
        <Switch>
          <Route
            exact={true}
            path={config.default.routes.oktaCallback}
            component={LoginCallback}
          />
          <Route
            exact={true}
            path={config.default.routes.error}
            component={Error}
          />
          <Route exact={true} path={config.default.routes.console}>
            <SecureRoute
              path={config.default.routes.console}
              component={VisibleClickThruAction}
            />
          </Route>
          <Route>
            <nav className={styles.nav}>
              <SecureRoute path={config.default.routes.index} component={Nav} />
            </nav>
            <div className={styles.content}>
              <Switch>
                <SecureRoute
                  path={config.default.routes.tokenManagement}
                  component={TokenManagement}
                />
                <SecureRoute
                  path={config.default.routes.index}
                  component={ConnectedHomePage}
                />
              </Switch>
            </div>
          </Route>
        </Switch>
      </div>
    </Security>
  );
};

export default Routes;
