import { connect } from 'react-redux';
import { AppState } from '../store';
import { ArfAccountDetails } from '../components/old_arf/ArfAccountDetails';
import { loadData } from '../util/loadData';
import getActiveArfAccount from '../selectors/getActiveArfAccount';
import arfAccountLoader from '../util/dataLoaders/arfAccountLoader';

const mapStateToProps = (state: AppState) => {
  const arfAccount = getActiveArfAccount(state);
  return {
    arfAccount,
  };
};

export const ActiveArfAccount = loadData(
  [arfAccountLoader],
  connect(mapStateToProps)(ArfAccountDetails)
);

export default ActiveArfAccount;
