import {
  SearchActionType,
  SearchState,
  ACCOUNT_SEARCH,
  ADD_SEARCH_TAG,
  REMOVE_SEARCH_TAG,
} from './types';

export const initialState: SearchState = {
  accountSearchText: '',
};

export const searchReducer = (
  state = initialState,
  action: SearchActionType
): SearchState => {
  switch (action.type) {
    case ACCOUNT_SEARCH: {
      const { accountSearchText } = action;
      return {
        ...state,
        accountSearchText,
      };
    }

    case ADD_SEARCH_TAG: {
      const { tag, include } = action;

      const terms = state.accountSearchText
        .trim()
        .split(/\s+/)
        .filter((term) => term !== '');
      const tagText = `${include ? '+' : '-'}${tag}`;
      if (
        !terms.map((term) => term.toLowerCase()).includes(tagText.toLowerCase())
      ) {
        terms.push(tagText);
        return {
          ...state,
          accountSearchText: terms.join(' '),
        };
      }

      return state;
    }

    case REMOVE_SEARCH_TAG: {
      const { tag } = action;

      const terms = state.accountSearchText.trim().split(/\s+/);
      if (terms.map((term) => term.toLowerCase()).includes(tag.toLowerCase())) {
        return {
          ...state,
          accountSearchText: terms
            .filter((term) => term.toLowerCase() !== tag.toLowerCase())
            .join(' '),
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default searchReducer;
