import * as config from '../config';
import { User } from '../models';
import { ThunkAction } from '../store/shared/types';
import {
  userAccessFetchStarted,
  userAccessFetchFailed,
  userAccessFetchSucceeded,
} from '../store/userManagement/actions';
import ALKS, { AlksError } from 'alks.js';
import { populateUsersForAccounts } from '../store/accounts/actions';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';

export const fetchUserAccess = (
  auth: OktaAuth,
  accountId: string
): ThunkAction<void> => async (dispatch) => {
  dispatch(userAccessFetchStarted(accountId));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    dispatch(userAccessFetchFailed(accountId, reason));
    throw e;
  }

  const alks = ALKS.create({
    baseUrl: config.default.alks.baseUrl,
    accessToken,
  });

  // Initiate both alks calls so that they execute in parallel, then handle their results synchronously
  const userAccessByRolePromise = alks.getUserAccessByRole({ accountId });
  const accountOwnersPromise = alks.getAccountOwners({ accountId });

  let alksUsers: Record<string, ALKS.User[]>;
  try {
    alksUsers = await userAccessByRolePromise;
  } catch (e) {
    // sometimes new accounts don't have data ready in Skypiea so ALKS is unaware about it
    if (e instanceof AlksError && e.status === 404) {
      alksUsers = {};
    } else {
      const reason = `An error occured when fetching user access from ALKS: ${getErrorMessage(
        e
      )}`;
      dispatch(userAccessFetchFailed(accountId, reason));
      throw e;
    }
  }

  let alksAccountOwners: ALKS.User[];
  try {
    alksAccountOwners = await accountOwnersPromise;
  } catch (e) {
    // sometimes new accounts don't have data ready in Skypiea so ALKS is unaware about it
    if (e instanceof AlksError && e.status === 404) {
      alksAccountOwners = [];
    } else {
      const reason = `An error occured when fetching account owners from ALKS: ${getErrorMessage(
        e
      )}`;
      dispatch(userAccessFetchFailed(accountId, reason));
      throw e;
    }
  }

  // Convert ALKS users to this app's user model
  const usersByRole: Record<string, User[]> = Object.entries(alksUsers)
    .map(
      ([roleName, users]) =>
        [
          roleName,
          users.map((user) => ({
            sAMAccountName: user.sAMAccountName,
            displayName: user.displayName,
            email: user.email,
            title: user.title,
            department: user.department,
            roles: new Map(),
          })),
        ] as [string, User[]]
    )
    .reduce(
      (acc, [roleName, users]) => ({
        ...acc,
        [roleName]: users,
      }),
      {}
    );

  // Flip the map to get a map of userInfo IDs to their roles
  const rolesByUserId: Map<string, string[]> = Object.entries(
    usersByRole
  ).reduce(
    (map, [roleName, userList]) =>
      userList.reduce(
        (acc, user) =>
          acc.set(user.sAMAccountName, [
            ...(acc.get(user.sAMAccountName) || []),
            roleName,
          ]),
        map
      ),
    new Map<string, string[]>()
  );

  // Get a list of account owners
  const accountOwners: User[] = alksAccountOwners.map((alksAccountOwner) => ({
    ...alksAccountOwner,
    roles: new Map(),
  }));

  // Aggregates all users from each role and the list of account owners into a list of users, possibly containing duplicates
  const duplicateUsers: User[] = [
    ...Object.values(usersByRole),
    accountOwners,
  ].reduce((acc, users) => [...acc, ...users], [] as User[]);

  // Get a list of all distinct users by removing duplicates
  const users: User[] = duplicateUsers.reduce(
    (acc, user) =>
      acc.find((u) => u.sAMAccountName === user.sAMAccountName)
        ? acc
        : [...acc, user],
    [] as User[]
  );

  // Populate the roles for each userInfo for this account
  users.forEach((user) => {
    user.roles.set(accountId, rolesByUserId.get(user.sAMAccountName) || []);
    user.displayName =
      typeof user.displayName == 'string' && user.displayName.length > 0
        ? user.displayName
        : user.sAMAccountName;
  });

  dispatch(userAccessFetchSucceeded(accountId, users));
  dispatch(populateUsersForAccounts(users, accountId));
};
