import * as t from 'io-ts';

export const ArfSlackChannel = t.type({
  href: t.string,
  id: t.string,
  name: t.string,
  slack_channel_id: t.string,
  created: t.string,
});

export type ArfSlackChannel = t.TypeOf<typeof ArfSlackChannel>;

export default ArfSlackChannel;
