import {
  SHOW_ANNOUNCEMENT,
  HIDE_ANNOUNCEMENT,
  ANNOUNCEMENTS_ARE_LOADING,
  ANNOUNCEMENTS_FAILED,
  ANNOUNCEMENTS_SUCCEEDED,
  AnnouncementActionType,
} from './types';
import { Announcement } from '../../models';

export const showAnnouncement = (
  announcementId: string
): AnnouncementActionType => ({
  type: SHOW_ANNOUNCEMENT,
  announcementId,
});

export const hideAnnouncement = (): AnnouncementActionType => ({
  type: HIDE_ANNOUNCEMENT,
});

export const announcementsFetchStarted = (): AnnouncementActionType => ({
  type: ANNOUNCEMENTS_ARE_LOADING,
});

export const announcementsFetchHasSucceeded = (
  announcements: Announcement[]
): AnnouncementActionType => ({
  type: ANNOUNCEMENTS_SUCCEEDED,
  announcements,
});

export const announcementsFetchHasFailed = (
  reason: string
): AnnouncementActionType => ({
  type: ANNOUNCEMENTS_FAILED,
  failureMessage: reason,
});
