import { fetchArfAccountRemediations } from '../../operations/fetchArfAccountRemediations';
import { getActiveAccountId } from '../../selectors/getActiveAccountId';
import { DataFetchStatus } from '../../store/shared/types';
import { DataLoader } from '../loadData';

export const arfAccountRemediationsLoader: DataLoader = (
  state,
  dispatch,
  auth
) => {
  const accountId = getActiveAccountId(state);
  if (!accountId) {
    throw new Error('No active account is found');
  }

  const activeAccountRemediationsFetchJob = state.data.arfAccountRemediations.fetchJobs.get(
    accountId
  );
  const arfAccountRemediationsFetchStatus = activeAccountRemediationsFetchJob
    ? activeAccountRemediationsFetchJob.status
    : DataFetchStatus.DATA_FETCH_UNSTARTED;
  switch (arfAccountRemediationsFetchStatus) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchArfAccountRemediations(auth, accountId));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      const message = activeAccountRemediationsFetchJob
        ? activeAccountRemediationsFetchJob.failureMessage
        : 'Failed to fetch ARF Account Remediations Data but no reason was given';
      throw new Error(message);
    }
    default: {
      throw new Error(
        'invalid value for ARF account remediations fetch status'
      );
    }
  }
};

export default arfAccountRemediationsLoader;
