import {
  DataFetchStatus,
  DeleteJobStatus,
  UpdateJobStatus,
} from '../shared/types';
import {
  IamRolesAction,
  IamRoleState,
  IAM_ROLES_ARE_LOADING,
  IAM_ROLES_SUCCEEDED,
  IAM_ROLES_FAILED,
  IAM_ROLES_ARE_DELETING,
  IAM_ROLES_DELETE_SUCCEEDED,
  IAM_ROLES_DELETE_FAILED,
  IAM_ROLE_CREATION_STARTED,
  IAM_ROLE_CREATION_FAILED,
  IAM_ROLE_CREATION_SUCCEEDED,
  IAM_ROLE_FORM_JOB_ID_UPDATED,
  IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED,
  IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED,
  IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED,
  IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED,
  IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED,
  IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED,
  IAM_ROLE_UPDATE_FAILED,
  IAM_ROLE_UPDATE_STARTED,
  IAM_ROLE_UPDATE_SUCCEEDED,
  CreateJob,
  CreateMIJob,
} from './types';
import { nextId } from '../../util/nextId';

const initialState: IamRoleState = {
  createJobs: new Map(),
  createMIJobs: new Map(),
  deleteMIJobs: new Map(),
  fetchJobs: new Map(),
  deleteJobs: new Map(),
  updateJobs: new Map(),
  byId: new Map(),
  allIds: [],
  iamRoleFormJobId: nextId(),
};

export const iamRoleReducer = (
  state = initialState,
  action: IamRolesAction
): IamRoleState => {
  switch (action.type) {
    case IAM_ROLES_ARE_LOADING: {
      const { accountId } = action;

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          status: DataFetchStatus.DATA_FETCH_PENDING,
        }),
      };
    }
    case IAM_ROLES_SUCCEEDED: {
      const { accountId, iamRoles } = action;

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        }),
        allIds: iamRoles.map((iamRole) => iamRole.id),
        byId: iamRoles.reduce(
          (byId, iamRole) => byId.set(iamRole.id, iamRole),
          new Map(state.byId)
        ),
      };
    }
    case IAM_ROLES_FAILED: {
      const { accountId, failureMessage } = action;

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          status: DataFetchStatus.DATA_FETCH_FAILED,
          failureMessage,
        }),
      };
    }
    case IAM_ROLES_ARE_DELETING: {
      const { iamRoleId } = action;

      return {
        ...state,
        deleteJobs: new Map(state.deleteJobs).set(iamRoleId, {
          status: DeleteJobStatus.DATA_DELETE_PENDING,
        }),
      };
    }
    case IAM_ROLES_DELETE_SUCCEEDED: {
      const { iamRoleId } = action;
      const newById = new Map(state.byId);
      newById.delete(iamRoleId);

      return {
        ...state,
        deleteJobs: new Map(state.deleteJobs).set(iamRoleId, {
          status: DeleteJobStatus.DATA_DELETE_SUCCEEDED,
        }),
        allIds: state.allIds.filter((id) => id !== iamRoleId),
        byId: newById,
      };
    }
    case IAM_ROLES_DELETE_FAILED: {
      const { iamRoleId, failureMessage } = action;

      return {
        ...state,
        deleteJobs: new Map(state.deleteJobs).set(iamRoleId, {
          status: DeleteJobStatus.DATA_DELETE_FAILED,
          failureMessage,
        }),
      };
    }
    case IAM_ROLE_CREATION_STARTED: {
      const job: CreateJob = {
        status: DataFetchStatus.DATA_FETCH_PENDING,
        id: action.jobId,
        accountId: action.accountId,
        roleName: action.roleName,
      };

      return {
        ...state,
        createJobs: new Map(state.createJobs).set(action.jobId, job),
      };
    }
    case IAM_ROLE_CREATION_FAILED: {
      const job: CreateJob | undefined = state.createJobs.get(action.jobId);

      // Don't update if the job doesn't exist or has already finished
      if (!job || job.status !== DataFetchStatus.DATA_FETCH_PENDING) {
        return state;
      }

      return {
        ...state,
        createJobs: new Map(state.createJobs).set(action.jobId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_FAILED,
          failureMessage: action.failureMessage,
        }),
      };
    }
    case IAM_ROLE_CREATION_SUCCEEDED: {
      const job: CreateJob | undefined = state.createJobs.get(action.jobId);

      // Don't update if the job doesn't exist or has already finished
      if (!job || job.status !== DataFetchStatus.DATA_FETCH_PENDING) {
        return state;
      }

      return {
        ...state,
        createJobs: new Map(state.createJobs).set(action.jobId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        }),
        byId: new Map(state.byId).set(action.iamRole.id, action.iamRole),
        allIds: [action.iamRole.id, ...state.allIds],
      };
    }
    case IAM_ROLE_UPDATE_STARTED: {
      return {
        ...state,
        updateJobs: new Map(state.updateJobs).set(action.roleId, {
          status: UpdateJobStatus.DATA_UPDATE_PENDING,
        }),
      };
    }
    case IAM_ROLE_UPDATE_FAILED: {
      const failureMessage = action.failureMessage || 'Unknown error';
      return {
        ...state,
        updateJobs: new Map(state.updateJobs).set(action.roleId, {
          status: UpdateJobStatus.DATA_UPDATE_FAILED,
          failureMessage,
        }),
      };
    }
    case IAM_ROLE_UPDATE_SUCCEEDED: {
      return {
        ...state,
        updateJobs: new Map(state.updateJobs).set(action.roleId, {
          status: UpdateJobStatus.DATA_UPDATE_SUCCEEDED,
        }),
        byId: new Map(state.byId).set(action.iamRole.id, action.iamRole),
      };
    }
    case IAM_ROLE_FORM_JOB_ID_UPDATED: {
      return {
        ...state,
        iamRoleFormJobId: action.jobId,
      };
    }

    case IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED: {
      const job: CreateMIJob = {
        status: DataFetchStatus.DATA_FETCH_PENDING,
        id: action.jobId,
        accountId: action.accountId,
        roleName: action.roleName,
        roleArn: action.roleArn,
      };

      return {
        ...state,
        createMIJobs: new Map(state.createMIJobs).set(action.jobId, job),
      };
    }

    case IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED: {
      const job: CreateMIJob | undefined = state.createMIJobs.get(action.jobId);

      // Don't update if job doesn't exist or has already been finished
      if (!job || job.status !== DataFetchStatus.DATA_FETCH_PENDING) {
        return state;
      }

      return {
        ...state,
        createMIJobs: new Map(state.createMIJobs).set(action.jobId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_FAILED,
          failureMessage: action.failureMessage,
        }),
      };
    }

    case IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED: {
      const job: CreateMIJob | undefined = state.createMIJobs.get(action.jobId);

      // Don't update if job doesn't exist or has already been finished
      if (!job || job.status !== DataFetchStatus.DATA_FETCH_PENDING) {
        return state;
      }

      const oldIamRole = state.byId.get(action.roleId);

      return {
        ...state,
        createMIJobs: new Map(state.createMIJobs).set(action.jobId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        }),
        byId: new Map(state.byId).set(action.roleId, {
          // I'm splatting it twice because for some reason the first one returns undefined
          // and oldIamRole could be undefined or IamRole
          ...state.byId[action.roleId],
          ...oldIamRole,
          isMachineIdentity: true,
        }),
      };
    }

    case IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED: {
      const { roleArn } = action;

      return {
        ...state,
        deleteMIJobs: new Map(state.deleteMIJobs).set(roleArn, {
          status: DeleteJobStatus.DATA_DELETE_PENDING,
        }),
      };
    }

    case IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED: {
      const { roleArn, failureMessage } = action;

      return {
        ...state,
        deleteMIJobs: new Map(state.deleteMIJobs).set(roleArn, {
          status: DeleteJobStatus.DATA_DELETE_FAILED,
          failureMessage,
        }),
      };
    }

    case IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED: {
      const { roleArn } = action;
      const oldIamRole = state.byId.get(action.roleId);

      return {
        ...state,
        deleteMIJobs: new Map(state.deleteMIJobs).set(roleArn, {
          status: DeleteJobStatus.DATA_DELETE_SUCCEEDED,
        }),
        byId: new Map(state.byId).set(action.roleId, {
          // I'm splatting it twice because for some reason the first one returns undefined
          // and oldIamRole could be undefined or IamRole
          ...state.byId[action.roleId],
          ...oldIamRole,
          isMachineIdentity: false,
        }),
      };
    }

    default: {
      return state;
    }
  }
};

export default iamRoleReducer;
