export const TAG = 'TAG';
export type TAG = typeof TAG;
export const TEXT = 'TEXT';
export type TEXT = typeof TEXT;

interface BaseTerm {
  text: string;
  score: number;
}

export interface TagTerm extends BaseTerm {
  type: TAG;
  tag: string;
  include: boolean;
}

export interface TextTerm extends BaseTerm {
  type: TEXT;
}

export type Term = TagTerm | TextTerm;
