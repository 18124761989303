import { IamRole } from '../../models';
import { DeleteJob, Job, UpdateJob } from '../shared/types';

export const IAM_ROLES_ARE_LOADING = 'IAM_ROLES_ARE_LOADING';
export type IAM_ROLES_ARE_LOADING = typeof IAM_ROLES_ARE_LOADING;

export const IAM_ROLES_SUCCEEDED = 'IAM_ROLES_SUCCEEDED';
export type IAM_ROLES_SUCCEEDED = typeof IAM_ROLES_SUCCEEDED;

export const IAM_ROLES_FAILED = 'IAM_ROLES_FAILED';
export type IAM_ROLES_FAILED = typeof IAM_ROLES_FAILED;

export const IAM_ROLES_ARE_DELETING = 'IAM_ROLES_ARE_DELETING';
export type IAM_ROLES_ARE_DELETING = typeof IAM_ROLES_ARE_DELETING;

export const IAM_ROLES_DELETE_SUCCEEDED = 'IAM_ROLES_DELETE_SUCCEEDED';
export type IAM_ROLES_DELETE_SUCCEEDED = typeof IAM_ROLES_DELETE_SUCCEEDED;

export const IAM_ROLES_DELETE_FAILED = 'IAM_ROLES_DELETE_FAILED';
export type IAM_ROLES_DELETE_FAILED = typeof IAM_ROLES_DELETE_FAILED;

export const IAM_ROLE_CREATION_STARTED = 'IAM_ROLE_CREATION_STARTED';
export type IAM_ROLE_CREATION_STARTED = typeof IAM_ROLE_CREATION_STARTED;

export const IAM_ROLE_CREATION_FAILED = 'IAM_ROLE_CREATION_FAILED';
export type IAM_ROLE_CREATION_FAILED = typeof IAM_ROLE_CREATION_FAILED;

export const IAM_ROLE_CREATION_SUCCEEDED = 'IAM_ROLE_CREATION_SUCCEEDED';
export type IAM_ROLE_CREATION_SUCCEEDED = typeof IAM_ROLE_CREATION_SUCCEEDED;

export const IAM_ROLE_FORM_JOB_ID_UPDATED = 'IAM_ROLE_FORM_JOB_ID_UPDATED';
export type IAM_ROLE_FORM_JOB_ID_UPDATED = typeof IAM_ROLE_FORM_JOB_ID_UPDATED;

export const IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED =
  'IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED';
export type IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED = typeof IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED;

export const IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED =
  'IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED';
export type IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED = typeof IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED;

export const IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED =
  'IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED';
export type IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED = typeof IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED;

export const IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED =
  'IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED';
export type IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED = typeof IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED;

export const IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED =
  'IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED';
export type IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED = typeof IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED;

export const IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED =
  'IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED';
export type IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED = typeof IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED;

export const IAM_ROLE_UPDATE_STARTED = 'IAM_ROLE_UPDATE_STARTED';
export type IAM_ROLE_UPDATE_STARTED = typeof IAM_ROLE_UPDATE_STARTED;

export const IAM_ROLE_UPDATE_FAILED = 'IAM_ROLE_UPDATE_FAILED';
export type IAM_ROLE_UPDATE_FAILED = typeof IAM_ROLE_UPDATE_FAILED;

export const IAM_ROLE_UPDATE_SUCCEEDED = 'IAM_ROLE_UPDATE_SUCCEEDED';
export type IAM_ROLE_UPDATE_SUCCEEDED = typeof IAM_ROLE_UPDATE_SUCCEEDED;

interface IamRolesLoadingAction {
  type: IAM_ROLES_ARE_LOADING;
  accountId: string;
}

interface IamRolesSuceededAction {
  type: IAM_ROLES_SUCCEEDED;
  iamRoles: IamRole[];
  accountId: string;
}

interface IamRolesFailedAction {
  type: IAM_ROLES_FAILED;
  failureMessage: string;
  accountId: string;
}

interface IamRolesDeletingAction {
  type: IAM_ROLES_ARE_DELETING;
  iamRoleId: string;
}

interface IamRolesDeleteSuceededAction {
  type: IAM_ROLES_DELETE_SUCCEEDED;
  iamRoleId: string;
}

interface IamRolesDeleteFailedAction {
  type: IAM_ROLES_DELETE_FAILED;
  iamRoleId: string;
  failureMessage: string;
}

interface IamRoleCreationStartedAction {
  type: IAM_ROLE_CREATION_STARTED;
  jobId: number;
  accountId: string;
  roleName: string;
}

interface IamRoleCreationFailedAction {
  type: IAM_ROLE_CREATION_FAILED;
  jobId: number;
  failureMessage: string;
}

interface IamRoleCreationSucceededAction {
  type: IAM_ROLE_CREATION_SUCCEEDED;
  jobId: number;
  iamRole: IamRole;
}

interface IamRoleUpdateStartedAction {
  type: IAM_ROLE_UPDATE_STARTED;
  roleId: string;
}

interface IamRoleUpdateFailedAction {
  type: IAM_ROLE_UPDATE_FAILED;
  roleId: string;
  failureMessage: string;
}

interface IamRoleUpdateSucceededAction {
  type: IAM_ROLE_UPDATE_SUCCEEDED;
  roleId: string;
  iamRole: IamRole;
}

interface IamRoleFormJobIdUpdatedAction {
  type: IAM_ROLE_FORM_JOB_ID_UPDATED;
  jobId: number;
}

interface IamRoleCreateMachineIdentityStartedAction {
  type: IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED;
  jobId: number;
  accountId: string;
  roleName: string;
  roleArn: string;
}

interface IamRoleCreateMachineIdentityFailedAction {
  type: IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED;
  jobId: number;
  failureMessage: string;
}

interface IamRoleCreateMachineIdentitySucceededAction {
  type: IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED;
  jobId: number;
  roleArn: string;
  roleId: string;
}

interface IamRoleDeleteMachineIdentityStartedAction {
  type: IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED;
  roleArn: string;
}

interface IamRoleDeleteMachineIdentityFailedAction {
  type: IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED;
  roleArn: string;
  failureMessage: string;
}

interface IamRoleDeleteMachineIdentitySucceededAction {
  type: IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED;
  roleArn: string;
  roleId: string;
}

export interface CreateJob extends Job {
  id: number;
  accountId: string;
  roleName: string;
}

export interface CreateMIJob extends Job {
  id: number;
  accountId: string;
  roleName: string;
  roleArn: string;
}

export type IamRolesAction =
  | IamRolesLoadingAction
  | IamRolesSuceededAction
  | IamRolesFailedAction
  | IamRolesDeletingAction
  | IamRolesDeleteSuceededAction
  | IamRolesDeleteFailedAction
  | IamRoleCreationStartedAction
  | IamRoleCreationFailedAction
  | IamRoleCreationSucceededAction
  | IamRoleFormJobIdUpdatedAction
  | IamRoleCreateMachineIdentityStartedAction
  | IamRoleCreateMachineIdentityFailedAction
  | IamRoleCreateMachineIdentitySucceededAction
  | IamRoleDeleteMachineIdentityStartedAction
  | IamRoleDeleteMachineIdentityFailedAction
  | IamRoleDeleteMachineIdentitySucceededAction
  | IamRoleUpdateStartedAction
  | IamRoleUpdateFailedAction
  | IamRoleUpdateSucceededAction;

export interface IamRoleState {
  createJobs: Map<number, CreateJob>;
  createMIJobs: Map<number, CreateMIJob>;
  deleteMIJobs: Map<string, DeleteJob>;
  deleteJobs: Map<string, DeleteJob>;
  updateJobs: Map<string, UpdateJob>;
  fetchJobs: Map<string, Job>;
  byId: Map<string, IamRole>;
  allIds: string[];
  iamRoleFormJobId: number;
}
