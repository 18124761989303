import memoize from 'memoizee';

export const populateTemplate = memoize(
  (template: string, variables: Record<string, string>): string => {
    let result = template;
    for (const [key, value] of Object.entries(variables)) {
      result = result.replace(new RegExp(`\\$\\{${key}\\}`, 'g'), value);
    }
    return result;
  }
);
