import { DataFetchStatus } from '../shared/types';
import {
  ROLES_ARE_LOADING,
  ROLES_FAILED,
  ROLES_SUCCEEDED,
  NEW_KEY,
  RolesActionType,
  RoleState,
} from './types';

const initialRoleState: RoleState = {
  allIds: [],
  byId: new Map(),
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
};

export const roleReducer = (
  state = initialRoleState,
  action: RolesActionType
): RoleState => {
  switch (action.type) {
    case ROLES_ARE_LOADING: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
        failureMessage: undefined,
      };
    }

    case ROLES_FAILED: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
        failureMessage: action.failureMessage,
      };
    }

    case ROLES_SUCCEEDED: {
      const roles = action.roles;
      return {
        ...state,
        allIds: roles.map((role) => role.id),
        byId: roles.reduce((m, role) => m.set(role.id, role), state.byId),
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        failureMessage: undefined,
      };
    }

    case NEW_KEY: {
      const { key, roleId } = action;
      const role = state.byId.get(roleId);
      if (!role) {
        return state;
      }
      return {
        ...state,
        byId: new Map(state.byId).set(roleId, {
          ...role,
          keys: [key.id, ...role.keys],
        }),
      };
    }

    default: {
      return state;
    }
  }
};
