import * as React from 'react';
import { connect } from 'react-redux';
import { Loading } from '../components/shared/Loading';
import { Failure } from '../components/shared/Failure';
import { Dispatch } from '../store/shared/types';
import { useOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppState } from '../store';

/**
 * A function that determines whether a piece of data is ready (and can dispatch actions if that data fetch needs to be started)
 */
export type DataLoader = (
  state: AppState,
  dispatch: Dispatch,
  auth: OktaAuth
) => boolean;

/**
 * The props for the Driver component
 */
interface DriverProps<WrappedComponentType extends React.ComponentType<any>> {
  state: AppState;
  dispatch: Dispatch;
  dataLoaders: DataLoader[];
  wrappedComponent: WrappedComponentType;
  wrappedComponentProps: React.ComponentProps<WrappedComponentType>;
}

/**
 * The driving component that runs all data loaders and either renders the provided component, or a component to indicate the status of data retrieval
 */
const Driver = <WrappedComponentType extends React.ComponentType<any>>({
  state,
  dispatch,
  dataLoaders,
  wrappedComponent,
  wrappedComponentProps,
}: DriverProps<WrappedComponentType>) => {
  // convert case since JSX requires components be uppercase, even though the style guide requires parameters be lowercase
  const WrappedComponent = wrappedComponent;

  // I don't love the idea of doing this. In the future we should consider refactoring dataLoaders into a series of react hooks - BW
  const { oktaAuth } = useOktaAuth();

  // If any data loader throws an error, render a failure component. If any data loader is still loading, render a loading component. Otherwise, render the provided component
  let ready: boolean;
  try {
    ready = dataLoaders.reduce(
      (acc: boolean, dataLoader) =>
        dataLoader(state, dispatch, oktaAuth) && acc,
      true
    );
  } catch (e) {
    return <Failure reason={(e as Error).message} />;
  }
  if (ready) {
    return <WrappedComponent {...wrappedComponentProps} />;
  } else {
    return <Loading />;
  }
};

const mapStateToProps = (state: AppState) => ({ state });

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

/**
 * The redux component that connects the Driver to redux state and the dispatch function
 */
const ReduxContainer = connect(mapStateToProps, mapDispatchToProps)(Driver);

/**
 * renders a component after all data loaders are ready
 *
 * @param dataLoaders - a list of data loaders that must run before the component gets rendered
 * @param wrappedComponent - the component to render when all data loaders are finished
 */
export const loadData = <WrappedComponentType extends React.ComponentType<any>>(
  dataLoaders: Array<DataLoader>,
  wrappedComponent: WrappedComponentType
) => (wrappedComponentProps: React.ComponentProps<WrappedComponentType>) => (
  <ReduxContainer
    dataLoaders={dataLoaders}
    wrappedComponent={wrappedComponent}
    wrappedComponentProps={wrappedComponentProps}
  />
);

export default loadData;
