import ALKS from 'alks.js';
import * as config from '../config';
import { ThunkAction } from '../store/shared/types';
import {
  iamRolesDeleteStarted,
  iamRolesDeleteHasFailed,
  iamRolesDeleteHasSucceeded,
} from '../store/iamRoles/actions';
import { IamRole } from '../models/index.js';
import { toast } from 'react-toastify';
import { OktaAuth } from '@okta/okta-auth-js';
import { getErrorMessage } from '../util/getErrorMessage';

/**
 * Deletes IAM Roles from ALKS and removes the role from the Redux store
 * @param auth - the Okta auth object
 * @param accountId - the ID of the account the role belongs to
 * @param nonIamRoleName - the role you are using to delete
 * @param iamRole - the role you are trying to delete
 */
export const deleteIamRoles = (
  auth: OktaAuth,
  accountId: string,
  nonIamRoleName: string,
  iamRole: IamRole
): ThunkAction<void> => async (dispatch) => {
  dispatch(iamRolesDeleteStarted(iamRole.id));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(`${iamRole.name} role failed to delete`, { type: 'error' });
    dispatch(iamRolesDeleteHasFailed(iamRole.id, reason));
    throw e;
  }

  // call the ALKS API to delete the role
  try {
    const alks = ALKS.create({
      baseUrl: config.default.alks.baseUrl,
      accessToken,
    });

    await alks.deleteRole({
      account: `${accountId}/ALKS${nonIamRoleName}`,
      role: nonIamRoleName,
      roleName: iamRole.name,
    });
  } catch (e) {
    const reason = `An error occured when deleting IAM roles from ALKS: ${getErrorMessage(
      e
    )}`;
    toast(`${iamRole.name} role failed to delete`, { type: 'error' });
    dispatch(iamRolesDeleteHasFailed(iamRole.id, reason));
    throw e;
  }

  // remove the role from state
  toast(`${iamRole.name} role was deleted`, { type: 'success' });
  dispatch(iamRolesDeleteHasSucceeded(iamRole.id));
};

export default deleteIamRoles;
