import {
  ACCOUNT_SEARCH,
  SearchActionType,
  ADD_SEARCH_TAG,
  REMOVE_SEARCH_TAG,
} from './types';

export const searchAccount = (accountSearchText: string): SearchActionType => ({
  type: ACCOUNT_SEARCH,
  accountSearchText,
});

export const addSearchTag = (
  tag: string,
  include: boolean
): SearchActionType => ({
  type: ADD_SEARCH_TAG,
  tag,
  include,
});

export const removeSearchTag = (tag: string): SearchActionType => ({
  type: REMOVE_SEARCH_TAG,
  tag,
});
