import * as React from 'react';
import { Input } from 'reactstrap';
import styles from './SearchBar.module.scss';
import { debounce } from 'lodash';
import styled from 'styled-components';

export interface OwnProps {
  autoFocus?: boolean;
  'aria-label'?: string;
}

export interface ModelProps {
  filterText: string;
}

export interface DispatchProps {
  onFilterTextChange: (filterText: string) => void;
}

export type Props = ModelProps & DispatchProps & OwnProps;

export const SearchBar: React.FC<Props> = (props) => {
  const [filterText, setFilterText] = React.useState(props.filterText);

  const debouncedUpdateText = React.useMemo(
    () => debounce(props.onFilterTextChange, 300),
    [props.onFilterTextChange]
  );

  const handleFilterTextChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      e.preventDefault();
      setFilterText(e.currentTarget.value);
      debouncedUpdateText(e.currentTarget.value);
    },
    [debouncedUpdateText]
  );

  React.useEffect(() => {
    setFilterText(props.filterText);
  }, [props.filterText]);

  return (
    <StyledInput
      id="filterBox"
      bsSize="sm"
      placeholder="filter..."
      autoComplete="off"
      value={filterText}
      onChange={handleFilterTextChange}
      autoFocus={props.autoFocus}
      className={styles.searchBar}
      aria-label={props['aria-label']}
    />
  );
};
// export type SearchBar = typeof SearchBar;

export const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.input.text.placeholder.textColor};
  }
`;

export default SearchBar;
