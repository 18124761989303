import {
  ROLE_TYPES_ARE_LOADING,
  ROLE_TYPES_FAILED,
  ROLE_TYPES_SUCCEEDED,
  RoleTypesAction,
} from './types';
import { RoleType } from '../../models';

export const roleTypesFetchStarted = (): RoleTypesAction => ({
  type: ROLE_TYPES_ARE_LOADING,
});

export const roleTypesFetchFailed = (reason: string): RoleTypesAction => ({
  type: ROLE_TYPES_FAILED,
  failureMessage: reason,
});

export const roleTypesFetchSucceeded = (
  roleTypes: RoleType[]
): RoleTypesAction => ({
  type: ROLE_TYPES_SUCCEEDED,
  roleTypes,
});
