import { AppState } from '../store';
import {
  Account,
  AccountPreferences,
  Role,
  Key,
  IamRole,
  RolePreferences,
  RoleType,
  Announcement,
  User,
  CostData,
  ArfAccount,
  ArfAccountViolation,
  ArfAccountRemediation,
} from '../models';
import { CreateJob, CreateMIJob } from '../store/iamRoles/types';
import { DeleteJob, DataFetchStatus, UpdateJob } from '../store/shared/types';
import { DefaultTheme } from 'styled-components';

/**
 * Gets the query string of the current URL
 */
export const getQueryString = (state: AppState): string =>
  state.router.location.search;

/**
 * Gets the path of the current URL
 */
export const getLocationPath = (state: AppState): string =>
  state.router.location.pathname;

/**
 * Gets the list of accounts from state
 */
export const getAccounts = (state: AppState): Map<string, Account> =>
  state.data.accounts.byId;

/**
 * Gets account preferences from state
 */
export const getAccountPreferences = (
  state: AppState
): { [key: string]: AccountPreferences } =>
  state.ui.preferences.preferenceState.accounts;

/**
 * Gets the list of roles from state
 */
export const getRoles = (state: AppState): Map<string, Role> =>
  state.data.roles.byId;

/**
 * Gets the list of role preferences
 */
export const getRolePreferences = (
  state: AppState
): { [key: string]: RolePreferences } =>
  state.ui.preferences.preferenceState.roles;

/**
 * Gets the list of keys from state
 */
export const getKeys = (state: AppState): Map<number, Key> =>
  state.data.keys.byId;

/**
 * Gets the DataFetchStatus for fetch keys
 */
export const getKeysFetchStatus = (state: AppState): DataFetchStatus =>
  state.data.keys.fetchStatus;

/**
 * Gets the account search text
 */
export const getAccountSearchText = (state: AppState): string =>
  state.ui.search.accountSearchText;

/**
 * Gets arf data for each account
 */
export const getArfAccountById = (state: AppState): Map<string, ArfAccount> =>
  state.data.arfAccount.byId;

export const getArfAccountViolationsById = (
  state: AppState
): Map<string, ArfAccountViolation[]> => state.data.arfAccountViolations.byId;

export const getArfAccountRemediationsById = (
  state: AppState
): Map<string, ArfAccountRemediation[]> =>
  state.data.arfAccountRemediations.byId;

/**
 * Gets the list of all tags across all accounts
 */
export const getGlobalTags = (state: AppState): Record<string, number> =>
  state.ui.preferences.preferenceState.globalTags;

/**
 * Gets the list of IAM roles from state
 */
export const getIamRoles = (state: AppState): Map<string, IamRole> =>
  state.data.iamRoles.byId;

/**
 * Gets the show announcement flag
 */
export const getAnnouncementShow = (state: AppState): boolean =>
  state.ui.announcement.show;

/**
 * Gets a map of role type name to role type
 */
export const getRoleTypes = (state: AppState): Map<string, RoleType> =>
  state.data.roleTypes.byId;

/**
 * Gets the list of role-creation jobs from state
 */
export const getCreateIamRoleJobs = (state: AppState): Map<number, CreateJob> =>
  state.data.iamRoles.createJobs;

/**
 * Gets the list of MI creation jobs from state
 */
export const getCreateIamRoleMIJobs = (
  state: AppState
): Map<number, CreateMIJob> => state.data.iamRoles.createMIJobs;

/**
 * Gets all delete MI jobs
 */
export const getDeleteMIJobs = (state: AppState): Map<string, DeleteJob> =>
  state.data.iamRoles.deleteMIJobs;

/**
 * Gets all update IamRole jobs.
 * * Note: This is a map of iamRole ID to job object, including operation status.
 */
export const getUpdateJobs = (state: AppState): Map<string, UpdateJob> =>
  state.data.iamRoles.updateJobs;

/**
 * Gets the job ID whose status is currently reflected by the New IAM Role form
 */
export const getIamRoleFormJobId = (state: AppState): number =>
  state.data.iamRoles.iamRoleFormJobId;

/**
 * Gets all delete jobs
 */
export const getDeleteJobs = (state: AppState): Map<string, DeleteJob> =>
  state.data.iamRoles.deleteJobs;

/**
 * Gets all announcements
 */
export const getAnnouncements = (
  state: AppState
): Record<string, Announcement> => state.ui.announcement.announcements;

/**
 * Gets all announcements
 */
export const getAnnouncementsRead = (
  state: AppState
): Record<string, boolean> =>
  state.ui.preferences.preferenceState.announcementsRead;

/**
 * Gets the current announcement ID
 */
export const getCurrentAnnouncementId = (state: AppState): string | null =>
  state.ui.announcement.currentAnnouncement;

/**
 * Gets all the users belonging to an account
 */
export const getUserAccessById = (state: AppState): Map<string, User> =>
  state.data.users.byUserId;

/**
 * Gets the user search texts
 */
export const getUserSearchText = (state: AppState): string =>
  state.data.users.userSearchText;

/**
 * Gets all the cost data by account id
 */
export const getCostDataById = (
  state: AppState
): Map<string, CostData | undefined> => state.data.costData.byId;

/**
 * Gets the current theme object
 */
export const getCurrentTheme = (state: AppState): DefaultTheme =>
  state.ui.preferences.preferenceState.theme;

/**
 * Gets the currently authenticated user's username
 */
export const getUsername = (state: AppState): string =>
  state.data.userInfo.username;

/**
 * Gets the currently authenticated user's email
 */
export const getEmail = (state: AppState): string => state.data.userInfo.email;
