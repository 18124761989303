import Account from '../../models/Account';
import { DataFetchStatus } from '../shared/types';
import Role from '../../models/Role';
import { User } from '../../models';

export const ACCOUNTS_ARE_LOADING = 'ACCOUNTS_ARE_LOADING';
export type ACCOUNTS_ARE_LOADING = typeof ACCOUNTS_ARE_LOADING;

export const ACCOUNTS_SUCCEEDED = 'ACCOUNTS_SUCCEEDED';
export type ACCOUNTS_SUCCEEDED = typeof ACCOUNTS_SUCCEEDED;

export const ACCOUNTS_FAILED = 'ACCOUNTS_FAILED';
export type ACCOUNTS_FAILED = typeof ACCOUNTS_FAILED;

export const POPULATE_ROLES_FOR_ACCOUNTS = 'UPDATE_ROLES_FOR_ACCOUNTS';
export type POPULATE_ROLES_FOR_ACCOUNTS = typeof POPULATE_ROLES_FOR_ACCOUNTS;

export const POPULATE_USERS_FOR_ACCOUNTS = 'POPULATE_USERS_FOR_ACCOUNTS';
export type POPULATE_USERS_FOR_ACCOUNTS = typeof POPULATE_USERS_FOR_ACCOUNTS;

interface AccountsLoadingAction {
  type: ACCOUNTS_ARE_LOADING;
}

interface AccountsSucceededAction {
  type: ACCOUNTS_SUCCEEDED;
  accounts: Account[];
}

interface AccountsFailedAction {
  type: ACCOUNTS_FAILED;
  failureMessage: string;
}

interface PopulateRolesForAccounts {
  type: POPULATE_ROLES_FOR_ACCOUNTS;
  roles: Role[];
}

interface PopulateUsersForAccounts {
  type: POPULATE_USERS_FOR_ACCOUNTS;
  users: User[];
  accountId: string;
}

export type AccountsActionType =
  | AccountsLoadingAction
  | AccountsSucceededAction
  | AccountsFailedAction
  | PopulateRolesForAccounts
  | PopulateUsersForAccounts;

export interface AccountState {
  fetchStatus: DataFetchStatus;
  byId: Map<string, Account>;
  allIds: string[];
  failureMessage?: string;
}
