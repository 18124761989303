import { createSelector } from 'reselect';
import { CreateMIJob } from '../store/iamRoles/types';
import { getCreateIamRoleMIJobs, getIamRoleFormJobId } from './common';

/**
 * Gets the IAM role MI creation job that is currently being tracked
 */
export const getIamRoleMIJob = createSelector(
  [getCreateIamRoleMIJobs, getIamRoleFormJobId],
  (jobs, jobId): CreateMIJob | undefined => jobs.get(jobId)
);

export default getIamRoleMIJob;
