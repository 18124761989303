import { createSelector } from 'reselect';
import { getActiveAccount } from './getActiveAccount';
import { SkypieaAccount } from '../models';

export const getActiveSkypieaAccount = createSelector(
  [getActiveAccount],
  (activeAccount): SkypieaAccount | null => {
    return activeAccount ? activeAccount.skypieaAccount : null;
  }
);
