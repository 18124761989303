import * as React from 'react';
import styled from 'styled-components';

export const useFlashLight = () => {
  const [mouseX, setMouseX] = React.useState(-1000);
  const [mouseY, setMouseY] = React.useState(-1000);

  const updateFlashlightPosition = React.useCallback(
    (e: React.MouseEvent) => {
      setMouseX(e.clientX);
      setMouseY(e.clientY);
    },
    [setMouseX, setMouseY]
  );

  const FlashLight: React.FC = () => {
    return (
      <FlashLightDiv style={{ top: mouseY + 'px', left: mouseX + 'px' }} />
    );
  };

  return { FlashLight, updateFlashlightPosition };
};

const FlashLightDiv = styled.div`
  && {
    position: absolute;
    width: 300px;
    margin-left: -150px;
    height: 300px;
    margin-top: -150px;
    border-radius: 150px;
    z-index: 10000;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 1);
    pointer-events: none;
  }
`;
