import { connect } from 'react-redux';
import { AppState } from './store';
import {
  createInstance,
  setLogger,
  OptimizelyProvider as Provider,
} from '@optimizely/react-sdk';
import { getUsername, getEmail } from './selectors/common';
import config from './config';

// Disable logging for Optimizely in prod
if (!config.optimizely.enableLogging) {
  setLogger(null);
}

const optimizelyClient = createInstance({ sdkKey: config.optimizely.sdkKey });

const mapStateToProps = (state: AppState) => ({
  username: getUsername(state),
  email: getEmail(state),
});
type MappedPropsFromState = ReturnType<typeof mapStateToProps> & {children: React.ReactNode};

const InternalOptimizelyProvider: React.FC<MappedPropsFromState> = ({
  username,
  email,
  children,
}) => {
  const user = {
    samAccountName: (typeof username === 'string'
      ? username
      : ''
    ).toLowerCase(),
    email: (typeof email === 'string' ? email : '').toLowerCase(),
  };

  return (
    <Provider
      optimizely={optimizelyClient}
      timeout={500}
      user={{
        id: user.samAccountName,
        attributes: {
          samAccountName: user.samAccountName,
          email: user.samAccountName,
        },
      }}
    >
      {children}
    </Provider>
  );
};

export const OptimizelyProvider = connect(mapStateToProps)(
  InternalOptimizelyProvider
);
