import * as React from 'react';
import { Button } from 'reactstrap';
import awsUrls from './awsConsoleUrls.json';
import { sample } from 'lodash';

export interface OwnProps {
  prefAccountId: string;
  prefRoleName: string;
  prefDuration: number;
}

export type Props = OwnProps;

export const FeelingLuckyConsole: React.FC<Props> = (props) => {
  const randomAWSConsolePage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    let randomURI = sample(awsUrls);
    randomURI = randomURI
      ? randomURI
      : 'https://console.aws.amazon.com/console/home';
    const encodedURI = encodeURIComponent(randomURI);
    const randomConsoleUrl = `console?accountId=${props.prefAccountId}&role=${props.prefRoleName}&duration=${props.prefDuration}&destination=${encodedURI}`;
    window.open(randomConsoleUrl);
  };

  return (
    <Button color="secondary" size="sm" onClick={randomAWSConsolePage}>
      I'm Feeling Lucky
    </Button>
  );
};

export default FeelingLuckyConsole;
