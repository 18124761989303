import ALKS from 'alks.js';
import * as config from '../config';
import { IamRole } from '../models';
import {
  iamRolesFetchHasFailed,
  iamRolesFetchHasSucceeded,
  iamRolesFetchStarted,
} from '../store/iamRoles/actions';
import { ThunkAction, DataFetchStatus } from '../store/shared/types';
import { hashCode } from '../util/hashCode';
import { parseRoleArn } from '../util/parseRoleArn';
import { OktaAuth } from '@okta/okta-auth-js';
import { getErrorMessage } from '../util/getErrorMessage';

/**
 * Fetches IAM Roles from ALKS and AWS and populates the Redux store with them
 *
 * @param auth - the Okta auth object
 * @param accountId - the ID of the account to fetch IAM roles for
 */
export const fetchIamRoles = (
  auth: OktaAuth,
  accountId: string
): ThunkAction<void> => async (dispatch, getState) => {
  // we are using getState because when the three components(StarredAccountIamRolesFetcher,ActiveAccountIamRolesFetcher,IamRoleLoader) that kick off fetchIamRoles
  // job, their piece of state is somehow stale and now getting the appropriate fetch status
  const status = getState().data.iamRoles.fetchJobs.get(accountId);
  if (status && status.status !== DataFetchStatus.DATA_FETCH_UNSTARTED) {
    return;
  }
  dispatch(iamRolesFetchStarted(accountId));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    dispatch(iamRolesFetchHasFailed(accountId, reason));
    throw e;
  }

  let alksIamRoles: ALKS.AwsAccountRole[];
  try {
    const alks = ALKS.create({
      baseUrl: config.default.alks.baseUrl,
      accessToken,
    });

    alksIamRoles = await alks.awsAccountRoles({
      account: accountId,
    });
  } catch (e) {
    const reason = `An error occured when fetching IAM roles from ALKS: ${getErrorMessage(
      e
    )}`;
    dispatch(iamRolesFetchHasFailed(accountId, reason));
    throw e;
  }

  const iamRoles: IamRole[] = alksIamRoles.map((alksIamRole) => {
    const iamRoleData = parseRoleArn(alksIamRole.roleArn);

    return {
      id: `iam-role-${hashCode(alksIamRole.roleArn)}`,
      name: iamRoleData.name,
      arn: alksIamRole.roleArn,
      accountId: iamRoleData.accountId,
      isMachineIdentity: alksIamRole.isMachineIdentity,
      trustPolicy: alksIamRole.assumeRolePolicyDocument,
    };
  });

  dispatch(iamRolesFetchHasSucceeded(accountId, iamRoles));
};

export default fetchIamRoles;
