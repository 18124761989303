import * as t from 'io-ts';

export const Tag = t.type({
  key: t.string,
  owner: t.union([t.literal('app'), t.literal('user')]),
});

export type Tag = t.TypeOf<typeof Tag>;

export const reservedTags: Record<string, Tag> = {
  prod: {
    key: 'prod',
    owner: 'app',
  },
  preprod: {
    key: 'preprod',
    owner: 'app',
  },
  nonprod: {
    key: 'nonprod',
    owner: 'app',
  },
  labs: {
    key: 'labs',
    owner: 'app',
  },
  l1: {
    key: 'l1',
    owner: 'app',
  },
  l2: {
    key: 'l2',
    owner: 'app',
  },
};

// Converts strings into Tag objects, or simply returns tags that are already Tag objects (useful for converting legacy tags into tag objects)
export const convertToTag = (tagOrTagKey: string | Tag): Tag => {
  if (typeof tagOrTagKey === 'string') {
    return (
      reservedTags[tagOrTagKey] ?? {
        key: tagOrTagKey,
        owner: 'user',
      }
    );
  }
  return tagOrTagKey;
};

export default Tag;
