import * as React from 'react';
import styled from 'styled-components';
import ActiveArfAccount from '../../containers/ActiveArfAccount';
import ActiveArfAccountViolations from '../../containers/ActiveArfAccountViolations';
import ActiveArfAccountRemediations from '../../containers/ActiveArfAccountRemediations';

export const Arf: React.FC = () => (
  <>
    <Header1>
      <img
        src="/clifford.png"
        alt="ARF's mascot"
        style={{ maxHeight: '128px', maxWidth: '128px' }}
      />{' '}
      ARF
    </Header1>
    <hr />
    <ActiveArfAccount />
    <hr />
    <ActiveArfAccountViolations />
    <hr />
    <ActiveArfAccountRemediations />
  </>
);

const Header1 = styled.h1`
  color: ${({ theme }) => theme.textColor};
`;
