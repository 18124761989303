import { connect } from 'react-redux';
import { AppState } from '../store';
import { IamRolesFetcher } from '../components/dataInitiation/IamRolesFetcher';
import { getStarredAccounts } from '../selectors/getStarredAccounts';
import { Dispatch } from '../store/shared/types';
import { fetchIamRoles } from '../operations/fetchIamRoles';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState) => {
  const accountIds = getStarredAccounts(state).map((account) => account.id);

  return {
    accountIds,
    fetchStatuses: accountIds.reduce(
      (map, id) => map.set(id, state.data.iamRoles.fetchJobs.get(id)),
      new Map()
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchIamRoles: (auth: OktaAuth, accountId: string) =>
    dispatch(fetchIamRoles(auth, accountId)),
});

export const StarredAccountIamRolesFetcher = connect(
  mapStateToProps,
  mapDispatchToProps
)(IamRolesFetcher);
