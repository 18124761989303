// tests were not written for this function because it's too hard to test
export const loadValueToClipboard = (
  value: string | undefined
): Promise<void> => {
  if (value === undefined || value.length === 0) {
    alert('There is no value present to copy to the clipboard');
    return Promise.resolve(undefined);
  }
  return navigator.clipboard.writeText(value);
};

export default loadValueToClipboard;
