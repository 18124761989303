import { connect } from 'react-redux';
import { AppState } from '../store';
import { IamRolesFetcher } from '../components/dataInitiation/IamRolesFetcher';
import { Dispatch } from '../store/shared/types';
import { fetchIamRoles } from '../operations/fetchIamRoles';
import { getActiveAccountId } from '../selectors/getActiveAccountId';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState) => {
  const activeAccountId = getActiveAccountId(state);

  return {
    accountIds: activeAccountId ? [activeAccountId] : [],
    fetchStatuses: activeAccountId
      ? new Map().set(
          activeAccountId,
          state.data.iamRoles.fetchJobs.get(activeAccountId)
        )
      : new Map(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchIamRoles: (auth: OktaAuth, accountId: string) =>
    dispatch(fetchIamRoles(auth, accountId)),
});

export const ActiveAccountIamRolesFetcher = connect(
  mapStateToProps,
  mapDispatchToProps
)(IamRolesFetcher);

export default ActiveAccountIamRolesFetcher;
