import { AccountPreferences, RolePreferences, Tag } from '../../models';
import * as t from 'io-ts';
import { DataFetchStatus } from '../shared/types';
import { DefaultTheme } from 'styled-components';
import { DefaultThemeCodec } from '../../styles/theme';

export const PREFERENCE_ACCOUNT_STARRED = 'PREFERENCE_ACCOUNT_STARRED';
export type PREFERENCE_ACCOUNT_STARRED = typeof PREFERENCE_ACCOUNT_STARRED;

export const PREFERENCE_ACCOUNT_UNSTARRED = 'PREFERENCE_ACCOUNT_UNSTARRED';
export type PREFERENCE_ACCOUNT_UNSTARRED = typeof PREFERENCE_ACCOUNT_UNSTARRED;

export const PREFERENCE_TOGGLE_ACCOUNT_STARRED =
  'PREFERENCE_TOGGLE_ACCOUNT_STARRED';
export type PREFERENCE_TOGGLE_ACCOUNT_STARRED = typeof PREFERENCE_TOGGLE_ACCOUNT_STARRED;

export const PREFERENCE_SET_PREFERRED_ROLE = 'PREFERENCE_SET_PREFERRED_ROLE';
export type PREFERENCE_SET_PREFERRED_ROLE = typeof PREFERENCE_SET_PREFERRED_ROLE;

export const PREFERENCE_SET_PREFERRED_ROLE_DURATION =
  'PREFERENCE_SET_PREFERRED_ROLE_DURATION';
export type PREFERENCE_SET_PREFERRED_ROLE_DURATION = typeof PREFERENCE_SET_PREFERRED_ROLE_DURATION;

export const PREFERENCE_ADD_TAG = 'PREFERENCE_ADD_TAG';
export type PREFERENCE_ADD_TAG = typeof PREFERENCE_ADD_TAG;

export const PREFERENCE_REMOVE_TAG = 'PREFERENCE_REMOVE_TAG';
export type PREFERENCE_REMOVE_TAG = typeof PREFERENCE_REMOVE_TAG;

export const PREFERENCE_BULK_ADD_TAGS = 'PREFERENCE_BULK_ADD_TAGS';
export type PREFERENCE_BULK_ADD_TAGS = typeof PREFERENCE_BULK_ADD_TAGS;

export const ADD_GLOBAL_TAG = 'ADD_GLOBAL_TAG';
export type ADD_GLOBAL_TAG = typeof ADD_GLOBAL_TAG;

export const REMOVE_GLOBAL_TAG = 'REMOVE_GLOBAL_TAG';
export type REMOVE_GLOBAL_TAG = typeof REMOVE_GLOBAL_TAG;

export const READ_ANNOUNCEMENT = 'READ_ANNOUNCEMENT';
export type READ_ANNOUNCEMENT = typeof READ_ANNOUNCEMENT;

export const PREFERENCES_ARE_LOADING = 'PREFERENCES_ARE_LOADING';
export type PREFERENCES_ARE_LOADING = typeof PREFERENCES_ARE_LOADING;

export const PREFERENCES_SUCCEEDED = 'PREFERENCES_SUCCEEDED';
export type PREFERENCES_SUCCEEDED = typeof PREFERENCES_SUCCEEDED;

export const PREFERENCES_FAILED = 'PREFERENCES_FAILED';
export type PREFERENCES_FAILED = typeof PREFERENCES_FAILED;

export const PUT_PREFERENCES_STARTED = 'PUT_PREFERENCES_STARTED';
export type PUT_PREFERENCES_STARTED = typeof PUT_PREFERENCES_STARTED;

export const PUT_PREFERENCES_SUCCEEDED = 'PUT_PREFERENCES_SUCCEEDED';
export type PUT_PREFERENCES_SUCCEEDED = typeof PUT_PREFERENCES_SUCCEEDED;

export const PUT_PREFERENCES_FAILED = 'PUT_PREFERENCES_FAILED';
export type PUT_PREFERENCES_FAILED = typeof PUT_PREFERENCES_FAILED;

export const SET_THEME = 'SET_THEME';
export type SET_THEME = typeof SET_THEME;

interface PreferenceAccountStarredAction {
  type: PREFERENCE_ACCOUNT_STARRED;
  accountId: string;
}

interface PreferenceAccountUnstarredAction {
  type: PREFERENCE_ACCOUNT_UNSTARRED;
  accountId: string;
}

interface PreferenceToggleAccountStarred {
  type: PREFERENCE_TOGGLE_ACCOUNT_STARRED;
  accountId: string;
}

interface PreferenceSetPreferredRole {
  type: PREFERENCE_SET_PREFERRED_ROLE;
  accountId: string;
  roleId: string;
}

interface PreferenceSetPreferredRoleDuration {
  type: PREFERENCE_SET_PREFERRED_ROLE_DURATION;
  roleId: string;
  duration: number;
}

interface PreferenceAddTag {
  type: PREFERENCE_ADD_TAG;
  accountId: string;
  tag: Tag;
}

interface PreferenceRemoveTag {
  type: PREFERENCE_REMOVE_TAG;
  accountId: string;
  tag: Tag;
}

interface PreferenceBulkAddTags {
  type: PREFERENCE_BULK_ADD_TAGS;
  tagsByAccountId: Record<string, Record<string, Tag>>;
}

interface AddGlobalTag {
  type: ADD_GLOBAL_TAG;
  tag: Tag;
}

interface RemoveGlobalTag {
  type: REMOVE_GLOBAL_TAG;
  tag: Tag;
}

interface ReadAnnouncement {
  type: READ_ANNOUNCEMENT;
  announcementId: string;
}

interface PreferencesLoadingAction {
  type: PREFERENCES_ARE_LOADING;
}

interface PreferencesFailedAction {
  type: PREFERENCES_FAILED;
  failureMessage: string;
}

interface PreferencesSucceededAction {
  type: PREFERENCES_SUCCEEDED;
  preferences: PreferenceState;
}

interface PutPreferencesStartedAction {
  type: PUT_PREFERENCES_STARTED;
}

interface PutPreferencesFailedAction {
  type: PUT_PREFERENCES_FAILED;
  failureMessage: string;
}

interface PutPreferencesSucceededAction {
  type: PUT_PREFERENCES_SUCCEEDED;
}

interface SetThemeAction {
  type: SET_THEME;
  theme: DefaultTheme;
}

export type PreferenceActionType =
  | PreferenceAccountStarredAction
  | PreferenceAccountUnstarredAction
  | PreferenceToggleAccountStarred
  | PreferenceSetPreferredRole
  | PreferenceSetPreferredRoleDuration
  | PreferenceAddTag
  | PreferenceRemoveTag
  | PreferenceBulkAddTags
  | AddGlobalTag
  | RemoveGlobalTag
  | ReadAnnouncement
  | PreferencesLoadingAction
  | PreferencesFailedAction
  | PreferencesSucceededAction
  | PutPreferencesStartedAction
  | PutPreferencesFailedAction
  | PutPreferencesSucceededAction
  | SetThemeAction;

export const PreferenceState = t.interface({
  accounts: t.record(t.string, AccountPreferences),
  roles: t.record(t.string, RolePreferences),
  globalTags: t.record(t.string, t.number),
  announcementsRead: t.record(t.string, t.boolean),
  theme: DefaultThemeCodec,
});

export type PreferenceState = t.TypeOf<typeof PreferenceState>;

export interface PreferenceReduxState {
  preferenceState: PreferenceState;
  fetchStatus: DataFetchStatus;
  putStatus: DataFetchStatus;
  failureMessage?: string;
  putFailureMessage?: string;
}
