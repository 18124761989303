import { connect } from 'react-redux';
import ModalRoot from '../components/modals/ModalRoot';
import { AppState } from '../store';
import { hideModal } from '../store/modal/actions';
import { Dispatch } from '../store/shared/types';

const mapStateToProps = (state: AppState) => ({
  ...state.ui.modal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(hideModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
