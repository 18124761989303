import { createSelector } from 'reselect';
import { getIamRoles } from './common';
import { getActiveAccountId } from './getActiveAccountId';
import { IamRole } from '../models';

/**
 * Gets all IAM Role record objects for the active account
 */
export const getIamRolesForActiveAccount = createSelector(
  [getActiveAccountId, getIamRoles],
  (accountId, iamRoles): IamRole[] => {
    return Array.from(iamRoles.values())
      .filter((iamRole) => iamRole.accountId === accountId)
      .sort((a, b) => a.id.localeCompare(b.id));
  }
);

export default getIamRolesForActiveAccount;
