import { User } from '../../models';
import { Job } from '../shared/types';

export const USER_ACCESS_FETCH_STARTED = 'USER_ACCESS_FETCH_STARTED';
export type USER_ACCESS_FETCH_STARTED = typeof USER_ACCESS_FETCH_STARTED;

export const USER_ACCESS_FETCH_FAILED = 'USER_ACCESS_FETCH_FAILED';
export type USER_ACCESS_FETCH_FAILED = typeof USER_ACCESS_FETCH_FAILED;

export const USER_ACCESS_FETCH_SUCCEEDED = 'USER_ACCESS_FETCH_SUCCEEDED';
export type USER_ACCESS_FETCH_SUCCEEDED = typeof USER_ACCESS_FETCH_SUCCEEDED;

export const USER_SEARCH = 'USER_SEARCH';
export type USER_SEARCH = typeof USER_SEARCH;

export const POPULATE_ROLES_FOR_USER = 'POPULATE_ROLES_FOR_USER';
export type POPULATE_ROLES_FOR_USER = typeof POPULATE_ROLES_FOR_USER;

interface UserAccessStartedAction {
  type: USER_ACCESS_FETCH_STARTED;
  accountId: string;
}

interface UserAccessFailedAction {
  type: USER_ACCESS_FETCH_FAILED;
  failureMessage: string;
  accountId: string;
}

interface UserAccessSucceededAction {
  type: USER_ACCESS_FETCH_SUCCEEDED;
  users: User[];
  accountId: string;
}

interface UserSearchAction {
  type: USER_SEARCH;
  userSearchText: string;
}

interface PopulateRolesForUserAction {
  type: POPULATE_ROLES_FOR_USER;
  sAMAccountName: string;
  accountId: string;
  roles: string[];
}

export type UserAccessAction =
  | UserAccessStartedAction
  | UserAccessFailedAction
  | UserAccessSucceededAction
  | UserSearchAction
  | PopulateRolesForUserAction;

export interface UserAccessState {
  fetchJobs: Map<string, Job>;
  byUserId: Map<string, User>;
  userSearchText: string;
}
