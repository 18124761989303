import * as React from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import styled from 'styled-components';
import { RoleView } from '../../models';

export interface ModelProps {
  accountRoles: RoleView[];
}

export interface DispatchProps {
  onSaveDuration: (
    accountId: string,
    roleName: string,
    duration: number
  ) => void;
}

export type Props = ModelProps & DispatchProps;

const DURATIONS = [1, 2, 6, 12];

class RoleDurationPreference extends React.Component<Props> {
  public render() {
    const { accountRoles } = this.props;
    let selectedDuration: number;

    return (
      <FormGroup>
        {accountRoles.map((role) => {
          selectedDuration = role.duration;
          const roleDurations = [
            ...DURATIONS.filter((duration) => role.maxKeyDuration > duration),
            role.maxKeyDuration,
          ];

          const onChangeHandler = this.onSaveDuration(role);
          return (
            <div key={role.id}>
              <Row>
                <Col md="3" id={`${role.name}-role-duration-label`}>
                  {role.name}
                </Col>
                <Col md="9">
                  <StyledInput
                    defaultValue={String(selectedDuration)}
                    key={role.name}
                    type="select"
                    aria-labelledby={`${role.name}-role-duration-label`}
                    onChange={onChangeHandler}
                  >
                    {roleDurations.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value} hr
                        </option>
                      );
                    })}
                  </StyledInput>
                </Col>
              </Row>
            </div>
          );
        })}
      </FormGroup>
    );
  }

  private onSaveDuration = (role: RoleView) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const element = e.target;
    const value = element.value;
    this.props.onSaveDuration(role.id, role.name, Number(value));
  };
}

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default RoleDurationPreference;
