import { DataLoader } from '../loadData';
import { DataFetchStatus } from '../../store/shared/types';
import { fetchUserInfo } from '../../operations/fetchUserInfo';

export const userInfoLoader: DataLoader = (state, dispatch, auth) => {
  const status = state.data.userInfo.fetchStatus;

  switch (status) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchUserInfo(auth));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      throw new Error(state.data.userInfo.failureMessage);
    }
    default: {
      throw new Error('invalid value for username fetch status');
    }
  }
};

export default userInfoLoader;
