import { Role, Key } from '../models';

/**
 * Gets the most recently generated key for a role
 *
 * @param role - the role to get a key for
 * @param keys - a map of key IDs to keys
 * @return the most recently generated key for the given role, or undefined if
 * no keys have been generated for the role
 */
export const getMostRecentKey = (
  role: Role,
  keys: Map<number, Key>
): Key | undefined =>
  role.keys.length > 0 ? keys.get(role.keys[0]) : undefined;

export default getMostRecentKey;
