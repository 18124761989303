import * as t from 'io-ts';
import Tag from './Tag';

export const AccountPreferences = t.intersection([
  t.interface({
    starred: t.boolean,
  }),
  t.partial({
    preferredRole: t.string,
  }),
  t.interface({
    tags: t.record(t.string, Tag),
  }),
]);

export type AccountPreferences = t.TypeOf<typeof AccountPreferences>;

const accountPreferenceDefaults: AccountPreferences = {
  starred: false,
  preferredRole: undefined,
  tags: {},
};

export const applyDefaultAccountPreferences = (
  prefs: Partial<AccountPreferences> = {}
) => ({
  ...accountPreferenceDefaults,
  ...prefs,
});

export default AccountPreferences;
