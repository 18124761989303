import { OAuthResponseType, OktaAuth, StorageType } from '@okta/okta-auth-js';
import urljoin from 'url-join';
import * as config from '../config';

export const oktaAuth = new OktaAuth({
  issuer: config.default.okta.issuer,
  clientId: config.default.okta.clientId,
  redirectUri: urljoin(
    window.location.origin,
    config.default.okta.redirectPath
  ),
  responseType: config.default.okta.responseType as OAuthResponseType[],
  scopes: config.default.okta.scopes,
  pkce: false,
  storageManager: {
    token: {
      storageTypes: [config.default.okta.storageType as StorageType],
    },
    cache: {
      storageTypes: [config.default.okta.storageType as StorageType],
    },
    transaction: {
      storageTypes: [config.default.okta.storageType as StorageType],
    },
  },
  postLogoutRedirectUri: config.default.routes.index,
});
