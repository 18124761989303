import * as React from 'react';
import {
  Button,
  InputGroup,
  Input,
  InputGroupText,
  UncontrolledTooltip,
  Spinner,
  Tooltip,
  UncontrolledCollapse,
  Alert,
} from 'reactstrap';
import {
  QuestionIcon,
  DiffAddedIcon,
  DiffRemovedIcon,
} from '@primer/octicons-react';
import * as config from '../../config';
import loadValueToClipboard from '../../util/loadValueToClipboard';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';

export type Props = unknown;

export const TokenManagement: React.FC<Props> = () => {
  const oktaUrl = config.default.okta.alksOauthUrl;
  const authDocUrl = config.default.docs.authentication;
  const tokenAuthUrl = config.default.docs.token_authentication;

  // Tooltip
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toolTipToggle1 = () => setTooltipOpen(!tooltipOpen);

  // Second Tooltip
  const [tooltipOpen2, setTooltipOpen2] = React.useState(false);
  const toolTipToggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [spinnerShown, setSpinnerShown] = React.useState(true);
  const toggleSpinnerShown = () => {
    setSpinnerShown(!spinnerShown);
  };

  // Advanced Section
  const [advancedOpen, setAdvancedOpen] = React.useState(true);
  const toggleAdvancedOpen = () => setAdvancedOpen(!advancedOpen);

  // Alert
  const [alertOpen, setAlertOpen] = React.useState(false);
  const showAlert = () => {
    setAlertOpen(!alertOpen);
    window.setTimeout(() => {
      setAlertOpen(alertOpen);
    }, 10000);
  };

  const refreshToken = React.useMemo(() => Cookies.get('refresh_token'), []);
  const accessToken = React.useMemo(() => Cookies.get('access_token'), []);

  const copyRefreshTokenHandler = React.useCallback(
    () => loadValueToClipboard(refreshToken),
    [refreshToken]
  );
  const copyAccessTokenHandler = React.useCallback(
    () => loadValueToClipboard(accessToken),
    [accessToken]
  );

  return (
    <TokenManagementDiv className="container" style={{ marginTop: 10 }}>
      <Alert color="warning" isOpen={alertOpen}>
        <h5 className="alert-heading">Warning!</h5>
        <p>
          Since this token is still a proxy for your access, it is sensitive and
          should be treated with the same care you would treat your username and
          password. It's best not to generate these tokens unless you understand
          what you are doing.
        </p>
      </Alert>
      <h1>Okta Token Generation</h1>
      <h4>Hover the token type for usage information.</h4>

      <div>
        {/* Refresh Token Input Group */}
        <small style={{ marginTop: 10 }}>
          A long term proxy for user access creds.
        </small>
        <InputGroup>
          <StyledInputGroupText id="refreshTokenText">
            Refresh Token &nbsp;
            <QuestionIcon size={'small'} />
          </StyledInputGroupText>
          <Tooltip
            target="refreshTokenText"
            placement="right"
            autohide={false}
            isOpen={tooltipOpen2}
            toggle={toolTipToggle2}
          >
            This token allows properly configured applications, like the ALKS
            CLI, to access ALKS on your behalf without the need to store your
            username and password. Additionally, this token can be revoked
            without requiring changes to your username or password.
          </Tooltip>
          <StyledInput id="refreshInput" value={refreshToken} readOnly={true} />
          <Button
            onClick={copyRefreshTokenHandler}
            onClickCapture={showAlert}
            id="refreshCopy"
            disabled={isEmpty(refreshToken)}
          >
            Copy
          </Button>
          <UncontrolledTooltip placement="top" target="refreshCopy">
            {' '}
            Copy to clipboard{' '}
          </UncontrolledTooltip>
        </InputGroup>

        <small onClick={toggleAdvancedOpen} id="toggler" style={{cursor: 'pointer'}}>
          {advancedOpen ? (
            <DiffAddedIcon size={'small'} />
          ) : (
            <DiffRemovedIcon />
          )}{' '}
          {' Advanced...'}
        </small>
        <UncontrolledCollapse toggler="toggler">
          {/* Access Token Input Group */}
          <small>A temporary session in ALKS.</small>
          <InputGroup>
            <StyledInputGroupText id="accessTokenText">
              Access Token &nbsp;
              <QuestionIcon size={'small'} />
            </StyledInputGroupText>
            <Tooltip
              placement="right"
              autohide={false}
              target="accessTokenText"
              isOpen={tooltipOpen}
              toggle={toolTipToggle1}
            >
              {' '}
              Valid as long as you are logged in (or about 30 minutes) and is
              helpful if you'd like to test the ALKS API in Swagger.
            </Tooltip>
            <StyledInput id="accessInput" value={accessToken} readOnly={true} />
            <Button
              onClick={copyAccessTokenHandler}
              id="accessCopy"
              disabled={isEmpty(accessToken)}
            >
              Copy
            </Button>
            <UncontrolledTooltip placement="top" target="accessCopy">
              {' '}
              Copy to clipboard{' '}
            </UncontrolledTooltip>
          </InputGroup>
        </UncontrolledCollapse>

        <br />

        {/* Generate Tokens Button */}
        <Button
          style={{ marginTop: 10 }}
          onClick={toggleSpinnerShown}
          id="generateToken"
          color="primary"
          href={oktaUrl}
          disabled={!spinnerShown}
        >
          Generate Tokens <Spinner hidden={spinnerShown} size="sm" />
        </Button>

        {/* More Information */}
        <hr className="my-2" />
        <small>For more information</small>
        <ul>
          <li>
            <a href={authDocUrl} target="_blank" rel="noopener noreferrer">
              <small>Authentication</small>
            </a>
          </li>
          <li>
            <a href={tokenAuthUrl} target="_blank" rel="noopener noreferrer">
              <small>Token Authentication</small>
            </a>
          </li>
        </ul>
      </div>
    </TokenManagementDiv>
  );
};

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};

    &&:disabled,
    &&[readonly] {
      background-color: ${({ theme }) => theme.disabledColor};
    }
  }
`;

const TokenManagementDiv = styled.div`
  color: ${({ theme }) => theme.textColor};
`;

const StyledInputGroupText = styled(InputGroupText)`
  && {
    background-color: ${({ theme }) => theme.disabledColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default TokenManagement;
