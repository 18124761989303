import * as React from 'react';
import { Progress } from 'reactstrap';

export interface State {
  percent: number;
  quote: string;
}

const loadingText = [
  'Wobbling to 299%',
  'They see me loading, they waiting',
  'Is this thing on?',
  'Dusting the cobwebs',
  'Loading your digital hug',
];

const randomText = () => {
  const r = Math.floor(Math.random() * (loadingText.length - 1));
  return loadingText[r];
};

export class Loading extends React.Component<object, State> {
  private interval: NodeJS.Timeout | undefined;

  public constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      quote: randomText(),
    };
  }

  public componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        percent: this.state.percent < 100 ? this.state.percent + 20 : 0,
      });
    }, 500);
  }

  public componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public render() {
    return <Progress value={this.state.percent}>{this.state.quote}</Progress>;
  }
}

export default Loading;
