import {
  ARF_ACCOUNT_REMEDIATIONS_FETCH_STARTED,
  ARF_ACCOUNT_REMEDIATIONS_FETCH_SUCCEEDED,
  ARF_ACCOUNT_REMEDIATIONS_FETCH_FAILED,
  ArfAccountRemediationsActionType,
} from './types';
import { ArfAccountRemediation } from '../../models';

export const arfAccountRemediationsFetchStarted = (
  accountId: string
): ArfAccountRemediationsActionType => ({
  type: ARF_ACCOUNT_REMEDIATIONS_FETCH_STARTED,
  accountId,
});

export const arfAccountRemediationsFetchSucceeded = (
  accountId: string,
  arfAccountRemediations: ArfAccountRemediation[]
): ArfAccountRemediationsActionType => ({
  type: ARF_ACCOUNT_REMEDIATIONS_FETCH_SUCCEEDED,
  accountId,
  arfAccountRemediations: arfAccountRemediations,
});

export const arfAccountRemediationsFetchFailed = (
  accountId: string,
  reason: string
): ArfAccountRemediationsActionType => ({
  type: ARF_ACCOUNT_REMEDIATIONS_FETCH_FAILED,
  accountId,
  failureMessage: reason,
});
