/**
 * Credit for JS implementation of hashCode goes to @hyamamoto on github -> https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
 */

/**
 * deterministically computes a hash code from a string
 *
 * @param s - the string to be hashed
 * @return a number generated from the string
 */
export const hashCode = (s: string): number => {
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return h;
};

export default hashCode;
