import { AppState } from '../store';
import StarAccountPreference, {
  ModelProps,
  DispatchProps,
} from '../components/preferences/StarAccountPreference';
import { getActiveAccount } from '../selectors/getActiveAccount';
import { connect } from 'react-redux';
import { toggleAccountStarred } from '../store/preferences/actions';

const mapStateToProps = (state: AppState): ModelProps => ({
  account: getActiveAccount(state),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  onStarClick: (accountId: string) => dispatch(toggleAccountStarred(accountId)),
});

export const VisibleStarAccountPreference = connect(
  mapStateToProps,
  mapDispatchToProps
)(StarAccountPreference);

export default VisibleStarAccountPreference;
