import { createSelector } from 'reselect';
import { getCurrentAnnouncementId, getAnnouncements } from './common';
import { Announcement } from '../models';

export const getCurrentAnnouncement = createSelector(
  [getCurrentAnnouncementId, getAnnouncements],
  (currentAnnouncementId, announcements): Announcement | null =>
    currentAnnouncementId !== null &&
    announcements.hasOwnProperty(currentAnnouncementId)
      ? announcements[currentAnnouncementId]
      : null
);

export default getCurrentAnnouncement;
