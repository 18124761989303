import * as React from 'react';
import { Card, ListGroup } from 'reactstrap';
import styled from 'styled-components';
import { AccountView, Key, RoleView } from '../../models';
import AccountItem from './AccountItem';

// Props for action creators
export interface DispatchProps {
  onStarClick: (id: string) => void;
  onAccountClick: (id: string) => void;
}

// Props from app state
export interface ModelProps {
  starredAccounts: AccountView[];
  unstarredAccounts: AccountView[];
  preferredRoles: Map<string, RoleView>;
  mostRecentKeys: Map<string, Key>;
  selectedAccountId: string | undefined;
}

export type Props = DispatchProps & ModelProps;

export const AccountList: React.FC<Props> = React.memo((props) => {
  const {
    starredAccounts,
    unstarredAccounts,
    preferredRoles,
    mostRecentKeys,
    selectedAccountId,
    onAccountClick,
    onStarClick,
  } = props;

  const handleStarClick = React.useCallback(
    (id: string) => {
      onStarClick(id);
    },
    [onStarClick]
  );

  const handleAccountClick = React.useCallback(
    (id: string) => {
      onAccountClick(id);
    },
    [onAccountClick]
  );

  return (
    <div id="account_list">
      {starredAccounts.length > 0 && (
        <AccountListCard className="mb-2" id="starred_accounts">
          <ListGroup flush={true}>
            {starredAccounts.map((account) => {
              const role = preferredRoles.get(account.id);
              const key = role && mostRecentKeys.get(role.id);
              return role ? (
                <AccountItem
                  key={account.id}
                  account={account}
                  preferredRole={role}
                  sessionKey={key}
                  onStarClick={handleStarClick}
                  onAccountClick={handleAccountClick}
                  isSelected={account.id === selectedAccountId}
                />
              ) : null;
            })}
          </ListGroup>
        </AccountListCard>
      )}
      <AccountListCard id="accounts">
        <ListGroup flush={true}>
          {unstarredAccounts.map((account) => {
            const role = preferredRoles.get(account.id);
            const key = role && mostRecentKeys.get(role.id);
            return role ? (
              <AccountItem
                key={account.id}
                account={account}
                preferredRole={role}
                sessionKey={key}
                onStarClick={handleStarClick}
                onAccountClick={handleAccountClick}
                isSelected={account.id === selectedAccountId}
              />
            ) : null;
          })}
        </ListGroup>
      </AccountListCard>
    </div>
  );
});

const AccountListCard = styled(Card)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default AccountList;
