import {
  AnnouncementActionType,
  AnnouncementState,
  SHOW_ANNOUNCEMENT,
  HIDE_ANNOUNCEMENT,
  ANNOUNCEMENTS_ARE_LOADING,
  ANNOUNCEMENTS_FAILED,
  ANNOUNCEMENTS_SUCCEEDED,
} from './types';
import { DataFetchStatus } from '../shared/types';
import { Announcement } from '../../models';
import { hashCode } from '../../util/hashCode';

export const initialState: AnnouncementState = {
  show: false,
  announcements: {},
  currentAnnouncement: null,
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
};

// Quick type alias to reduce long type declarations
type Announcements = Record<number, Announcement>;

export const announcementReducer = (
  state = initialState,
  action: AnnouncementActionType
): AnnouncementState => {
  switch (action.type) {
    case ANNOUNCEMENTS_ARE_LOADING: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
        failureMessage: undefined,
      };
    }
    case ANNOUNCEMENTS_FAILED: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
        failureMessage: action.failureMessage,
      };
    }
    case ANNOUNCEMENTS_SUCCEEDED: {
      const newAnnouncements: Announcements = action.announcements
        // filter out malformed announcements
        .filter(
          (
            announcement: any
          ): announcement is { message: string; linkText: string } =>
            announcement &&
            typeof announcement.message === 'string' &&
            typeof announcement.linkText === 'string'
        )
        // generate the announcement id
        .map((announcement: { message: string; linkText: string }) => ({
          id: `${hashCode(
            announcement.message + '::' + announcement.linkText
          )}`,
          ...announcement,
        }))
        // filter out announcements that don't fit Announcement model (mostly done so Typescript will recognize it as a list of Announcement objects)
        .filter((announcement: object): announcement is Announcement =>
          Announcement.is(announcement)
        )
        // convert list to a record of announcement id -> announcement
        .reduce(
          (announcements: Announcements, announcement: Announcement) => ({
            ...announcements,
            [announcement.id]: announcement,
          }),
          {}
        );

      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        failureMessage: undefined,
        announcements: Object.values(state.announcements).reduce(
          (announcements: Announcements, announcement: Announcement) => ({
            ...announcements,
            [announcement.id]: announcement,
          }),
          newAnnouncements
        ),
      };
    }

    case SHOW_ANNOUNCEMENT: {
      const announcement = state.announcements[action.announcementId];

      if (!announcement) {
        return state;
      }

      return {
        ...state,
        show: true,
        announcements: {
          ...state.announcements,
          [announcement.id]: {
            ...announcement,
          },
        },
        currentAnnouncement: action.announcementId,
      };
    }

    case HIDE_ANNOUNCEMENT: {
      return {
        ...state,
        show: false,
        currentAnnouncement: null,
      };
    }

    default:
      return state;
  }
};

export default announcementReducer;
