import * as React from 'react';
import {
  NavLink as RouterLink,
  NavLinkProps,
  RouteComponentProps,
} from 'react-router-dom';
import qs from 'query-string';

export interface ModelProps {
  location: RouteComponentProps['location'];
}

export type Props = NavLinkProps & ModelProps;

/**
 * An extension of React Router's Link component that preserves the `account` query param
 */
export const Link: React.FC<Props> = ({ location, to, ...rest }) => {
  const accountId = qs.parse(location.search).account;

  if (!accountId) {
    return <RouterLink to={to} {...rest} location={location} />;
  }

  // fill in the `account` query parameter if it is not specified
  let newTo: NavLinkProps['to'];
  if (typeof to === 'object') {
    newTo = {
      ...to,
      search: to.search
        ? qs.stringify({
            account: accountId,
            ...(qs.parse(to.search)),
          })
        : qs.stringify({
            account: accountId,
          }),
    };
  } else {
    newTo = {
      search: qs.stringify({
        account: accountId,
      }),
      pathname: to as string,
    };
  }

  return <RouterLink to={newTo} {...rest} location={location} />;
};

export default Link;
