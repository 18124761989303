import { createSelector } from 'reselect';
import { RoleView } from '../models';
import { getPreferredRole } from '../util/getPreferredRole';
import { getAccountViews } from './getAccountViews';
import { getRoleViews } from './getRoleViews';

/**
 * Gets a map of account IDs to their preferred role object. If either the
 * account's `preferredRole` field is undefined or no role exists for the role
 * ID in that field, no entry will be included for the account
 */
export const getPreferredRoles = createSelector(
  [getAccountViews, getRoleViews],
  (accountViews, roles): Map<string, RoleView> =>
    Array.from(accountViews.values()).reduce((map, accountView) => {
      const role = getPreferredRole(accountView, roles);
      return role ? map.set(accountView.id, role) : map;
    }, new Map())
);
