import { DataFetchStatus } from '../shared/types';
import {
  ACCOUNTS_ARE_LOADING,
  ACCOUNTS_FAILED,
  ACCOUNTS_SUCCEEDED,
  AccountsActionType,
  AccountState,
  POPULATE_ROLES_FOR_ACCOUNTS,
  POPULATE_USERS_FOR_ACCOUNTS,
} from './types';

const initialState: AccountState = {
  allIds: [],
  byId: new Map(),
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
};

export const accountReducer = (
  state = initialState,
  action: AccountsActionType
): AccountState => {
  switch (action.type) {
    case ACCOUNTS_ARE_LOADING:
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
        failureMessage: undefined,
      };

    case ACCOUNTS_FAILED:
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
        failureMessage: action.failureMessage,
      };

    case ACCOUNTS_SUCCEEDED: {
      const accts = action.accounts;
      return {
        ...state,
        allIds: accts.map((acct) => acct.id),
        byId: accts.reduce((m, acct) => m.set(acct.id, acct), state.byId),
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        failureMessage: undefined,
      };
    }

    case POPULATE_ROLES_FOR_ACCOUNTS: {
      const roles = action.roles;
      return {
        ...state,
        byId: roles.reduce((byId, role) => {
          const account = byId.get(role.accountId);
          if (account) {
            const newRoles = [...account.roles];
            newRoles.push(`${role.id}`);
            return byId.set(role.accountId, {
              ...account,
              roles: newRoles,
            });
          }
          return byId;
        }, new Map(state.byId)),
      };
    }

    case POPULATE_USERS_FOR_ACCOUNTS: {
      const users = action.users;
      const accountId = action.accountId;
      return {
        ...state,
        byId: users.reduce((byId, user) => {
          const account = byId.get(accountId);
          if (account) {
            const newUsers = [...account.users];
            newUsers.push(`${user.sAMAccountName}`);
            return byId.set(accountId, {
              ...account,
              users: newUsers,
            });
          }
          return byId;
        }, new Map(state.byId)),
      };
    }

    default:
      return state;
  }
};
