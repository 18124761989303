import { AccountView, Term } from '../models';
import { accountMatchesTerm } from './accountMatchesTerm';

/**
 * Calculates the score for an account by summing the scores of the terms which match the account
 */
export const calculateScore = (account: AccountView, terms: Term[]): number =>
  terms.reduce(
    (acc, term) => (accountMatchesTerm(account, term) ? acc + term.score : acc),
    0
  );

export default calculateScore;
