import * as React from 'react';
import { Modal } from 'reactstrap';
import { ModalType, ModalState, ModalStatus } from '../../store/modal/types';
import AlertModal from './AlertModal';
import KeyModal from './KeyModal';
import ConfirmationModal from './ConfirmationModal';
import styled from 'styled-components';

export type ModelProps = ModalState;
export interface DispatchProps {
  closeModal: () => void;
}

export type Props = ModelProps & DispatchProps;

const ModalRoot: React.FC<Props> = (props) => {
  const { closeModal, modalType, modalProps, modalStatus } = props;
  const isOpen = modalStatus === ModalStatus.Shown;

  const Component = getModalByType(modalType);

  modalProps.closeModal = closeModal;

  return (
    <StyledModal isOpen={isOpen} toggle={closeModal}>
      {Component && <Component {...modalProps} />}
    </StyledModal>
  );
};

const getModalByType = (modalType: ModalType) => {
  switch (modalType) {
    case ModalType.Alert:
      return AlertModal;
    case ModalType.Key:
      return KeyModal;
    case ModalType.Confirm:
      return ConfirmationModal;
    default:
      return null;
  }
};

const StyledModal = styled(Modal)`
  && .modal-content {
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default ModalRoot;
