import { Role } from '../models';

export const getRolePriority = (role: Role) => {
  switch (role.name) {
    case 'LabAdmin':
      return 0;
    case 'Admin':
      return 1;
    case 'IAMAdmin':
      return 2;
    case 'PowerUser':
      return 3;
    case 'ReadOnly':
      return 4;
    default:
      return 9999;
  }
};

export default getRolePriority;
