import { HomeIcon, MailIcon } from '@primer/octicons-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as config from '../../config';

export type Props = RouteComponentProps;

export const Error: React.FC<Props> = (props) => {
  const queryParams = props.location.search;
  const url = new URLSearchParams(queryParams);
  const code = url.get('code') || 418;
  const message =
    url.get('message') ||
    'This is the error page, if you came here manually there is nothing to see here';
  const accountRole = url.get('accountRole');

  return (
    <div
      className="d-flex flex-row align-items-center"
      style={{ height: '100vh' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block" id="errorCode">
              {code}
            </span>
            <div className="mb-4 lead" id="errorMessage">
              {message}
            </div>
            {accountRole && (
              <div className="mb-4">
                You do not have access to AWS account/role{' '}
                <em>{accountRole}</em>. To request access fill this{' '}
                <a
                  href={config.default.userManagement.snowFormAddUser}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  form here
                </a>
              </div>
            )}
            <div>
              <Button color="primary" tag="a" href="/">
                <HomeIcon /> Take Me Home
              </Button>{' '}
              <Button
                tag="a"
                href="https://coxauto.slack.com/archives/C4C5MKVQ9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MailIcon /> Contact Slack
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
