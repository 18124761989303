import * as React from 'react';
import * as Model from '../../models';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Dropdown,
} from 'reactstrap';
import styled from 'styled-components';

export interface RoleProps {
  /**
   * Whether this role is the preferred role for the account (meaning that when clicking the Console button, this is the role that keys will be generated for)
   */
  isPreferredRole: boolean;

  /**
   * The account that this role belongs to
   */
  account: Model.AccountView;

  /**
   * The role that this item represents
   */
  role: Model.RoleView;

  /**
   * A list of options for how many hours a set of keys should last when generating keys for this role
   */
  durations: number[];

  /*
   * Retrieves keys for the given account and role
   */
  getKeys: (
    account: Model.AccountView,
    role: Model.Role,
    duration: number
  ) => void;

  /**
   * Sets this role as the preferred role for the account
   */
  setPreferredRole: (accountId: string, roleId: string) => void;
}

export const Role: React.FC<RoleProps> = ({
  isPreferredRole,
  account,
  role,
  durations,
  getKeys,
  setPreferredRole,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const toggleDropdown = React.useCallback(
    () => setShowDropdown(!showDropdown),
    [showDropdown]
  );
  const buttonColor = React.useMemo(
    () => (isPreferredRole ? 'primary' : 'secondary'),
    [isPreferredRole]
  );

  return (
    <InputGroup key={role.id} className="mb-2" id={role.id}>
      <StyledInput readOnly={true} value={role.name} tabIndex="-1" />
      <Dropdown
        addonType="append"
        isOpen={showDropdown}
        toggle={toggleDropdown}
      >
        <Button
          color={buttonColor}
          outline={true}
          onClick={() => getKeys(account, role, role.duration)}
        >
          Get Keys
        </Button>
        <DropdownToggle split={true} outline={true} color={buttonColor} />
        <StyledDropdownMenu>
          <StyledDropdownItem key="header" header={true}>
            Durations
          </StyledDropdownItem>
          {durations.map((duration) => (
            <StyledDropdownItem
              key={`${duration}`}
              onClick={() => getKeys(account, role, duration)}
            >
              {duration}hr Key
            </StyledDropdownItem>
          ))}
          <StyledDropdownItem key="divider" divider={true} />
          <StyledDropdownItem
            key="setPreferred"
            className="setPreferredRoleButton"
            disabled={isPreferredRole}
            onClick={() => setPreferredRole(account.id, role.id)}
          >
            Set as Preferred Role
          </StyledDropdownItem>
        </StyledDropdownMenu>
      </Dropdown>
    </InputGroup>
  );
};

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }

  &&:disabled,
  &&[readonly] {
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&:hover {
    color: ${({ theme }) => theme.navbar.dropdown.hover.textColor};
    background-color: ${({ theme }) =>
      theme.navbar.dropdown.hover.backgroundColor};
  }
`;
