import { DataFetchStatus } from '../shared/types';
import {
  KeyState,
  KeysActionType,
  KEYS_ARE_LOADING,
  KEYS_FAILED,
  KEYS_SUCCEEDED,
} from './types';

let previousId = 0;

const initialState: KeyState = {
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
  byId: new Map(),
  allIds: [],
};

export const keyReducer = (state = initialState, action: KeysActionType) => {
  switch (action.type) {
    case KEYS_ARE_LOADING: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
      };
    }

    case KEYS_SUCCEEDED: {
      const newId = previousId + 1;
      previousId = newId;

      action.key.id = newId;

      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        allIds: [...state.allIds, newId],
        byId: state.byId.set(newId, action.key),
      };
    }

    case KEYS_FAILED: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
      };
    }

    default: {
      return state;
    }
  }
};
