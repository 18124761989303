import { Col, Container, Row } from "reactstrap";
import { styled } from "styled-components";
import { InfoTooltip } from "../shared/InfoTooltip";
import React from "react";
import { ArfAccount } from "../../models";

export interface Props {
  arfAccount: ArfAccount;
}

export const ArfHeader: React.FC<Props> = ({ arfAccount }) => {
  return (
    <>
      <h1>
        <img
          src="/clifford.png"
          alt="ARF's mascot"
          style={{ maxHeight: '128px', maxWidth: '128px' }}
        />{' '}
        ARF
      </h1>
      <hr />
      <StyledContainer>
        <Row className="arfRow">
          <Col className="data-col" sm={{ size: 'auto' }}>
            <span>Security Level: </span>
            {`L${arfAccount.security_level}`}
          </Col>
          <Col className="data-col" sm={{ size: 'auto' }}>
            <span>Type: </span>
            {arfAccount.account_type}
          </Col>
          <Col className="data-col" sm={{ size: 'auto' }}>
            <span>
              Slack Channels
              <InfoTooltip
                title={
                  <React.Fragment>
                    <div className="slackTooltip">
                      <div>
                        Channel(s) to which ARF will notify when remediations
                        happen.
                      </div>
                      <div>
                        Visit{' '}
                        <a
                          href="https://coxautoinc.sharepoint.com/sites/service-marven/SitePages/ARF-Reports-&-Notifications.aspx?web=1#arf-notifications-in-slack"
                          target="_blank"
                        >
                          here
                        </a>{' '}
                        to add a Slack channel.
                      </div>
                    </div>
                  </React.Fragment>
                }
                placement="bottom"
              />
              :
            </span>{' '}
            {arfAccount.channels.length
              ? arfAccount.channels.map((channel) => channel.name).join(', ')
              : 'No Slack channels provided'}
          </Col>
        </Row>
      </StyledContainer>
      <hr />
    </>
  );
}

const StyledContainer = styled(Container)`
  && {
    padding: 0%;
    margin: 0%;
    color: ${({ theme }) => theme.textColor};
  }
  && .data-col > span {
    font-weight: bold;
  }
`;
