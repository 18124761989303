import { connect } from 'react-redux';
import { OwnProps, TagBadge } from '../components/shared/TagBadge';
import { AppState } from '../store';
import { getActiveAccountId } from '../selectors/getActiveAccountId';
import { Dispatch } from '../store/shared/types';
import { Tag } from '../models';
import { removeTag } from '../store/preferences/actions';

const mapStateToProps = (state: AppState) => ({
  accountId: getActiveAccountId(state),
});
type MappedPropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: (accountId: string, tag: Tag) => dispatch(removeTag(accountId, tag)),
});
type MappedPropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (
  stateProps: MappedPropsFromState,
  dispatchProps: MappedPropsFromDispatch,
  ownProps: OwnProps
) => ({
  ...ownProps,
  onClose: (tag: Tag) =>
    stateProps.accountId !== undefined
      ? dispatchProps.onClose(stateProps.accountId, tag)
      : undefined,
});

export const ActiveAccountTagBadge = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TagBadge);
