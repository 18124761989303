import { loadData } from '../util/loadData';
import { AppState } from '../store';
import { connect } from 'react-redux';
import UserManagement, {
  ModelProps,
  DispatchProps,
} from '../components/userManagement/UserManagement';
import { userAccessLoader } from '../util/dataLoaders/userAccessLoader';
import { getActiveAccountUsers } from '../selectors/getActiveAccountUsers';
import { getMatchingUsers } from '../util/getMatchingUsers';
import { getUserSearchText } from '../selectors/common';
import { Dispatch } from '../store/shared/types';
import { userSearch } from '../store/userManagement/actions';
import { getActiveAccountId } from '../selectors/getActiveAccountId';
import { getActiveSkypieaAccount } from '../selectors/getActiveSkypieaAccount';
import { getActiveAccountName } from '../selectors/getActiveAccountName';

const mapStateToProps = (state: AppState): ModelProps => ({
  users: getMatchingUsers(
    getActiveAccountUsers(state),
    getUserSearchText(state)
  ),
  accountId: getActiveAccountId(state),
  accountName: getActiveAccountName(state),
  skypieaAccount: getActiveSkypieaAccount(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateFilterText: (filterText: string) => dispatch(userSearch(filterText)),
});

const VisibleUserManagement = loadData(
  [userAccessLoader],
  connect(mapStateToProps, mapDispatchToProps)(UserManagement)
);

export default VisibleUserManagement;
