import { ThunkAction } from '../store/shared/types';
import {
  iamRoleUpdateStarted,
  iamRoleUpdateFailed,
  iamRoleUpdateSucceeded,
} from '../store/iamRoles/actions';
import ALKS from 'alks.js';
import * as config from '../config';
import { IamRole } from '../models';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';

export interface RequestDetails {
  name: string;
  id: string;
  arn: string;
  isMachineIdentity: boolean;
  trustPolicy: Record<string, any>;
}

/**
 * Update an existing IAM role
 *
 * @param auth - the Okta auth object from state
 * @param accountId - the ID of the account to create the IAM role in
 * @param role - the name of the login role (e.g. Admin)
 * @param details - details of the role to be updated
 * @param details.arn - the ARN of the role to update
 * @param details.id - ALKS IAM role ID
 * @param details.name - the name of the role to update
 * @param details.trustPolicy - the updated trust policy for the role
 * @param details.isMachineIdentity - whether the IAM role should be a machine identity
 */
export const updateIamRole = (
  auth: OktaAuth,
  accountId: string,
  role: string,
  details: RequestDetails
): ThunkAction<void> => async (dispatch) => {
  dispatch(iamRoleUpdateStarted(details.id));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(iamRoleUpdateFailed(details.id, reason));
    throw e;
  }

  const alks = ALKS.create({
    baseUrl: config.default.alks.baseUrl,
    accessToken,
  });
  try {
    await alks.updateRole({
      account: `${accountId}/ALKS${role}`,
      role: role,
      roleName: details.name,
      trustPolicy: details.trustPolicy,
    });
  } catch (e) {
    const reason = `An error occurred when trying to update account/role ${accountId}/${
      details.name
    }: ${getErrorMessage(e)}`;
    toast(reason, { type: 'error' });
    dispatch(iamRoleUpdateFailed(details.id, reason));
    throw e;
  }

  const iamRole: IamRole = {
    id: details.id,
    name: details.name,
    arn: details.arn,
    accountId: accountId,
    isMachineIdentity: details.isMachineIdentity,
    trustPolicy: details.trustPolicy,
  };

  toast(`IAM role updated successfully: ${details.name}`, { type: 'success' });
  dispatch(iamRoleUpdateSucceeded(details.id, iamRole));
};

export default updateIamRole;
