import memoize from 'memoizee';

export interface ObjectWithName {
  name: string;
}

/**
 * Sorts a list of objects by their `name` field
 *
 * @param list - the list of objects to sort
 * @return the sorted list of objects
 */
export const sortByName = memoize(<T extends ObjectWithName>(list: T[]): T[] =>
  [...list].sort((a, b) => a.name.localeCompare(b.name))
);

export default sortByName;
