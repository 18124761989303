export const SHOW_MODAL = 'SHOW_MODAL';
export type SHOW_MODAL = typeof SHOW_MODAL;

export const HIDE_MODAL = 'HIDE_MODAL';
export type HIDE_MODAL = typeof HIDE_MODAL;

interface ShowModalAction {
  type: SHOW_MODAL;
  modal: ModalType;
  props: any;
}

interface HideModalAction {
  type: HIDE_MODAL;
}

export type ModalActionType = ShowModalAction | HideModalAction;

export enum ModalType {
  Key = 'KEY_MODAL',
  Alert = 'ALERT_MODAL',
  Confirm = 'CONFIRM_MODAL',
  None = 'NO_MODAL',
}

export enum ModalStatus {
  Shown = 'SHOWN',
  Hidden = 'HIDDEN',
}

export interface ModalState {
  modalType: ModalType;
  modalProps: any;
  modalStatus: ModalStatus;
}
