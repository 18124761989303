import { StarIcon } from '@primer/octicons-react';
import * as React from 'react';
import { AccountView, Key, RoleView } from '../../models';
import { ListGroupItem } from 'reactstrap';
import styles from './AccountItem.module.scss';
import { shallowCompare } from '../../util/shallowCompare';
import styled from 'styled-components';
import FeelingLuckyConsole from '../seasonal/FeelingLuckyConsole';
import { Button } from '../theme/Button';
import { useDecision } from '@optimizely/react-sdk';

// Props passed from component's parent
export interface OwnProps {
  account: AccountView;
  preferredRole: RoleView;
  sessionKey: Key | undefined;
  isSelected: boolean;
}

// Props for action creators
export interface DispatchProps {
  onStarClick: (id: string) => void;
  onAccountClick: (id: string) => void;
}

export type Props = OwnProps & DispatchProps;

const AccountItem: React.FC<Props> = React.memo(
  ({ account, preferredRole, isSelected, onStarClick, onAccountClick }) => {
    const handleStarClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onStarClick(account.id);
      },
      [onStarClick, account]
    );

    const handleConsoleClick = React.useCallback(
      (url: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        window.open(url);
      },
      []
    );

    const handleAccountClick = React.useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onAccountClick(account.id);
      },
      [onAccountClick, account]
    );

    const consoleUrl = React.useMemo(() => {
      const destination = encodeURIComponent('https://console.aws.amazon.com');
      return `/console?accountId=${preferredRole.accountId}&role=${preferredRole.name}&duration=${preferredRole.duration}&destination=${destination}`;
    }, [preferredRole]);

    const [aprilFools] = useDecision('april_fools');

    return (
      <StyledListGroupItem
        tag="a"
        href="#"
        action={true}
        onClick={handleAccountClick}
        isSelected={isSelected}
        aria-label={account.name}
      >
        <h6>{account.skypieaAccount ? account.skypieaAccount.label : 'N/A'}</h6>
        <div className={styles.accountItem}>
          <div className={styles.names}>
            <small className="mb-1">{account.name}</small>
            <br />
            <small>{account.id}</small>
          </div>
          <div className={styles.buttons}>
            <Button
              outline={true}
              color="secondary"
              active={account.starred}
              onClick={handleStarClick}
              size="sm"
              aria-label="add account to favorites"
            >
              <StarIcon />
            </Button>
            <Button
              tag="a"
              href={consoleUrl}
              color="primary"
              size="sm"
              onClick={handleConsoleClick(consoleUrl)}
              aria-label="open AWS console"
            >
              Console
            </Button>
            {aprilFools.enabled && (
              <FeelingLuckyConsole
                prefAccountId={preferredRole.accountId}
                prefRoleName={preferredRole.name}
                prefDuration={preferredRole.duration}
              />
            )}
          </div>
        </div>
      </StyledListGroupItem>
    );
  },
  (prevProps, currProps) => {
    // This component will update if any props change except for these
    const blackListProps = ['onStarClick', 'onAccountClick'];
    return shallowCompare(prevProps, currProps, { blackList: blackListProps });
  }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledListGroupItem = styled(({ isSelected, ...rest }) => (
  <ListGroupItem {...rest} />
))`
  && {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.selectionColor : theme.notSelectedColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default AccountItem;
