import { connect } from 'react-redux';
import { NewRelicTracker } from '../components/shared/NewRelicTracker';
import { AppState } from '../store';
import { getActiveAccountId } from '../selectors/getActiveAccountId';

const mapStateToProps = (state: AppState) => ({
  accountId: getActiveAccountId(state),
});

export const VisibleNewRelicTracker = connect(mapStateToProps)(NewRelicTracker);

export default VisibleNewRelicTracker;
