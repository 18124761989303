import {
  RoleTypeState,
  RoleTypesAction,
  ROLE_TYPES_SUCCEEDED,
  ROLE_TYPES_FAILED,
  ROLE_TYPES_ARE_LOADING,
} from './types';
import { DataFetchStatus } from '../shared/types';

const initialState: RoleTypeState = {
  byId: new Map(),
  fetchStatus: DataFetchStatus.DATA_FETCH_UNSTARTED,
};

export const roleTypeReducer = (
  state = initialState,
  action: RoleTypesAction
): RoleTypeState => {
  switch (action.type) {
    case ROLE_TYPES_SUCCEEDED: {
      const { roleTypes } = action;

      return {
        ...state,
        byId: roleTypes.reduce(
          (m, roleType) => m.set(roleType.name, roleType),
          new Map(state.byId)
        ),
        fetchStatus: DataFetchStatus.DATA_FETCH_SUCCEEDED,
        failureMessage: undefined,
      };
    }
    case ROLE_TYPES_FAILED: {
      const { failureMessage } = action;

      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_FAILED,
        failureMessage,
      };
    }
    case ROLE_TYPES_ARE_LOADING: {
      return {
        ...state,
        fetchStatus: DataFetchStatus.DATA_FETCH_PENDING,
        failureMessage: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default roleTypeReducer;
