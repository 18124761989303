import { DefaultTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTheme } from '../selectors/common';
import { setTheme } from '../store/preferences/actions';
import { useCallback } from 'react';
import { themesById } from './theme';

/**
 * React Hook that allows you to access the current theme object within any React function component
 */
export const useTheme = (): [DefaultTheme, (themeId: string) => void] => {
  const currentTheme = useSelector(getCurrentTheme);
  const dispatch = useDispatch();

  const switchTheme = useCallback(
    (themeId: string) => {
      if (themeId in themesById) {
        dispatch(setTheme(themesById[themeId]));
      }
    },
    [dispatch]
  );

  return [currentTheme, switchTheme];
};
