import qs from 'query-string';
import { createSelector } from 'reselect';
import { getQueryString } from './common';

/**
 * extracts the value of the `account` field in a query string
 */
export const getActiveAccountId = createSelector(
  [getQueryString],
  (queryString: string): string | undefined => {
    const { account: accountId } = qs.parse(queryString);

    if (!accountId) {
      return undefined;
    }

    if (Array.isArray(accountId)) {
      return accountId.shift() ?? undefined;
    }

    return accountId;
  }
);

export default getActiveAccountId;
