import { ScoredAccountView, score } from '../models';

/**
 * Compares two accounts by score, then name, then ID, then skypiea label
 */
export const compareAccounts = (
  a: ScoredAccountView,
  b: ScoredAccountView
): number => {
  let diff = b[score] - a[score];
  if (diff !== 0) {
    return diff;
  }
  diff = a.name.localeCompare(b.name);
  if (diff !== 0) {
    return diff;
  }
  diff = a.id.localeCompare(b.id);
  if (diff !== 0) {
    return diff;
  }
  if (a.skypieaAccount && b.skypieaAccount) {
    return a.skypieaAccount.label.localeCompare(b.skypieaAccount.label);
  } else if (!a.skypieaAccount && !b.skypieaAccount) {
    return 0;
  } else {
    return a.skypieaAccount ? -1 : 1;
  }
};

export default compareAccounts;
