import * as React from 'react';
import {
  Alert,
  Input,
  Button,
  Card,
  CardDeck,
  CardTitle,
  CardText,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { execCommand } from '../../util/test/execCommandMock';
import { convertToTag, Tag } from '../../models';
import * as t from 'io-ts';

export interface OwnProps {
  isModalOpen: boolean;
  modalToggle: () => void;
}

export interface ModelProps {
  tagPrefs: object;
  accountIds: string[]; // getting this info so we know what accounts we have for importing tags
  isModalOpen: boolean;
  modalToggle: () => void;
}

const TagPreferencesObjectCodex = t.record(
  t.string,
  t.type({
    tags: t.union([t.array(t.string), t.record(t.string, Tag)]),
  })
);

export interface DispatchProps {
  openConfirmation: (f: () => void) => void;
  importTags: (tags: Record<string, Record<string, Tag>>) => void;
}

export type Props = ModelProps & DispatchProps & OwnProps;

export const ImportExportTagPreference: React.FC<Props> = (props) => {
  const [input, setInput] = React.useState('');
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const importTags = () => {
    let tagObj: unknown;
    try {
      tagObj = JSON.parse(input);
    } catch (err) {
      toast.error('Failed to import: object is not valid JSON');
      return;
    }

    let tagsByAccountId: Record<string, Record<string, Tag>>;
    if (TagPreferencesObjectCodex.is(tagObj)) {
      tagsByAccountId = Object.entries(tagObj).reduce(
        (obj, [accountId, { tags }]) => {
          let tagsByTagKey: Record<string, Tag> = {};

          // If the imported object conforms to the legacy style of storing tags as an array of string, convert it to a record of tag name to tag object
          if (Array.isArray(tags)) {
            tagsByTagKey = tags.reduce(
              (acc, tagName) => ({ ...acc, [tagName]: convertToTag(tagName) }),
              {}
            );
          } else {
            tagsByTagKey = tags;
          }

          return {
            ...obj,
            [accountId]: tagsByTagKey,
          };
        },
        {}
      );
    } else {
      toast.error(
        'Failed to import: object does not conform to the correct format'
      );
      return;
    }

    props.openConfirmation(() => {
      props.importTags(tagsByAccountId);
    });
  };

  const exportTags = () => {
    const tags = JSON.parse(JSON.stringify(props.tagPrefs));
    // creating dummy element because the way copy command works is it has to select an element
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = JSON.stringify(tags);
    dummy.select();
    execCommand('copy');
    document.body.removeChild(dummy);
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} toggle={props.modalToggle} size={'lg'}>
        <Alert color="warning">
          WARNING! Importing tags will override ALL your tags for EVERY account
          you have, there is no way to reverse this.
        </Alert>
        <ModalHeader toggle={props.modalToggle}>
          Import / Export Tags
        </ModalHeader>
        <ModalBody>
          <CardDeck>
            <Card body={true}>
              <CardTitle>Import Tags</CardTitle>
              <CardBody>
                <pre>
                  <Input
                    rows={8}
                    type="textarea"
                    placeholder="Paste JSON String from export here..."
                    value={input}
                    onChange={(e) => setInput(e.currentTarget.value)}
                  />
                </pre>
              </CardBody>
              <Button size="sm" onClick={importTags}>
                Import Tags
              </Button>
            </Card>
            <Card body={true}>
              <CardTitle>Export Tags</CardTitle>
              <CardBody>
                <CardText>
                  Got some awesome tags that you would like to share with your
                  team? Export your tag preferences and send them the special
                  JSON formatted string that is copied to your clipboard. They
                  will only have the tags applied for accounts that you both
                  have ignoring any tags you put for accounts they do not have
                  access to.
                </CardText>
              </CardBody>
              <Button size="sm" id="clipboardTag" onClick={exportTags}>
                Export Tags
              </Button>
              <Tooltip
                toggle={toggle}
                isOpen={tooltipOpen}
                target="clipboardTag"
              >
                Copy to clipboard
              </Tooltip>
            </Card>
          </CardDeck>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.modalToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ImportExportTagPreference;
