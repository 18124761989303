import Account from '../../models/Account';
import {
  ACCOUNTS_ARE_LOADING,
  ACCOUNTS_FAILED,
  ACCOUNTS_SUCCEEDED,
  AccountsActionType,
  POPULATE_ROLES_FOR_ACCOUNTS,
  POPULATE_USERS_FOR_ACCOUNTS,
} from './types';
import Role from '../../models/Role';
import { User } from '../../models';

export const accountsFetchStarted = (): AccountsActionType => ({
  type: ACCOUNTS_ARE_LOADING,
});

export const accountsFetchHasSucceeded = (
  accounts: Account[]
): AccountsActionType => ({
  type: ACCOUNTS_SUCCEEDED,
  accounts,
});

export const accountsFetchHasFailed = (reason: string): AccountsActionType => ({
  type: ACCOUNTS_FAILED,
  failureMessage: reason,
});

export const populateRolesForAccounts = (
  roles: Role[]
): AccountsActionType => ({
  type: POPULATE_ROLES_FOR_ACCOUNTS,
  roles,
});

export const populateUsersForAccounts = (
  users: User[],
  accountId: string
): AccountsActionType => ({
  type: POPULATE_USERS_FOR_ACCOUNTS,
  users,
  accountId,
});
