import { Term, TagTerm, TAG } from '../models';

/**
 * Returns a list of only exclusionary terms
 *
 * @param terms - a list of terms potentially containing some exclusionary terms
 * @return a list of exclusionary terms
 */
export const getExclusionaryTerms = (terms: Term[]): TagTerm[] =>
  terms.filter((term): term is TagTerm => term.type === TAG && !term.include);

export default getExclusionaryTerms;
