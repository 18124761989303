import * as React from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { Role } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import urljoin from 'url-join';

export interface ModelProps {
  iamCapableRole?: Role;
}

export type Props = ModelProps & RouteComponentProps;

export const NewRoleButton: React.FC<Props> = (props) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  let disabled = true;

  if (props.iamCapableRole) {
    disabled = false;
  }

  const onHover = () => {
    if (disabled) setPopoverOpen(true);
  };

  const onLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <div>
      <Button
        id="NewRoleButton"
        size="sm"
        color="primary"
        aria-label="New IAM Role"
        onMouseOver={onHover}
        onMouseLeave={onLeave}
        disabled={disabled}
        onClick={() =>
          props.history.push({
            pathname: urljoin(props.location.pathname, '/new'),
            search: props.location.search,
          })
        }
      >
        New
      </Button>
      <Popover
        placement="top"
        isOpen={popoverOpen}
        target="NewRoleButton"
        toggle={onHover}
      >
        <PopoverBody>
          Admin, IAMAdmin, or LabAdmin is needed to create roles
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default NewRoleButton;
