import * as React from 'react';
import { Col, Row } from 'reactstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  stackoverflowLight,
  stackoverflowDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dump } from 'js-yaml';
import {
  Container,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

import { CheckIcon } from '@primer/octicons-react';

import { ArfAccount, ArfPolicy } from '../../models';
import styled from 'styled-components';
import { useTheme } from '../../styles/useTheme';
import { darkMode } from '../../styles/theme';
import { Failure } from '../shared/Failure';
import { Table } from '../table/Table';
import { InfoTooltip } from '../shared/InfoTooltip';
import { CollapsibleTableRow } from '../table/CollapsibleTableRow';

export interface ModelProps {
  arfAccount: ArfAccount | undefined;
}

export type Props = ModelProps;

export const ArfAccountDetails: React.FC<Props> = ({ arfAccount }) => {
  const [theme] = useTheme();

  if (!arfAccount) {
    return <Failure reason="Arf data could not be loaded" />;
  }

  const policies: ArfPolicy[] = arfAccount.policies;

  return (
    <>
      <ArfContainer>
        <Row className="arfRow">
          <Col className="data-col" sm={{ size: 'auto' }}>
            <span>Security Level: </span>
            {`L${arfAccount.security_level}`}
          </Col>
          <Col className="data-col" sm={{ size: 'auto' }}>
            <span>Type: </span>
            {arfAccount.account_type}
          </Col>
          <Col className="data-col" sm={{ size: 'auto' }}>
            <span>
              Slack Channels
              <InfoTooltip
                title={
                  <React.Fragment>
                    <div className="slackTooltip">
                      <div>
                        Channel(s) to which ARF will notify when remediations
                        happen.
                      </div>
                      <div>
                        Visit{' '}
                        <a
                          href="https://coxautoinc.sharepoint.com/sites/service-marven/SitePages/ARF-Reports-&-Notifications.aspx?web=1#arf-notifications-in-slack"
                          target="_blank"
                        >
                          here
                        </a>{' '}
                        to add a Slack channel.
                      </div>
                    </div>
                  </React.Fragment>
                }
                placement="bottom"
              />
              :
            </span>{' '}
            {arfAccount.channels.length
              ? arfAccount.channels.map((channel) => channel.name).join(', ')
              : 'No Slack channels provided'}
          </Col>
        </Row>
      </ArfContainer>

      <hr />

      <Header2>Configured Policies</Header2>
      <br />

      {!policies.length ? (
        <TextStyle>No policies found.</TextStyle>
      ) : (
        <Table defaultPageSize={5}>
          <TableHead>
            <TableRow>
              <TableCell width={'55%'}>
                Policy Name{' '}
                <InfoTooltip title="Name of the policy as known to the ARF system" />
              </TableCell>
              <TableCell width={'20%'}>
                Enabled{' '}
                <InfoTooltip title="Means a policy has been deployed in your aws account and will perform checks in read-only" />
              </TableCell>
              <TableCell width={'20%'}>
                Remediate{' '}
                <InfoTooltip title="Means a policy will attemt to actively remediate when a violation is found" />
              </TableCell>
              <TableCell width={'5%'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policies.map((policy, index) => {
              const directiveConf = arfAccount.directives.find(
                (x) => x.policy == policy.id
              )?.config;
              return (
                <CollapsibleTableRow
                  key={index}
                  collapseContent={
                    <div style={{ cursor: 'auto' }}>
                      <SyntaxHighlighter
                        onClick={(e: Event) => {
                          e.stopPropagation();
                        }}
                        language="yaml"
                        style={
                          theme.id == darkMode.id
                            ? stackoverflowDark
                            : stackoverflowLight
                        }
                      >
                        {dump(policy)}
                      </SyntaxHighlighter>
                    </div>
                  }
                >
                  <TableCell>{policy.name}</TableCell>
                  <TableCell>
                    {directiveConf?.enabled ? <CheckIcon /> : null}
                  </TableCell>
                  <TableCell>
                    {directiveConf?.remediate ? <CheckIcon /> : null}
                  </TableCell>
                </CollapsibleTableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

const TextStyle = styled.p`
  color: ${({ theme }) => theme.textColor};
`;

const Header2 = styled.h2`
  color: ${({ theme }) => theme.textColor};
`;

const ArfContainer = styled(Container)`
  && {
    padding: 0%;
    margin: 0%;
    color: ${({ theme }) => theme.textColor};
  }
  && .data-col > span {
    font-weight: bold;
  }
`;

export default ArfAccountDetails;
