import {
  USER_ACCESS_FETCH_FAILED,
  USER_ACCESS_FETCH_STARTED,
  USER_ACCESS_FETCH_SUCCEEDED,
  USER_SEARCH,
  UserAccessAction,
} from './types';
import { User } from '../../models';
import { POPULATE_ROLES_FOR_USER } from './types';

export const userAccessFetchStarted = (
  accountId: string
): UserAccessAction => ({
  type: USER_ACCESS_FETCH_STARTED,
  accountId,
});

export const userAccessFetchFailed = (
  accountId: string,
  reason: string
): UserAccessAction => ({
  type: USER_ACCESS_FETCH_FAILED,
  failureMessage: reason,
  accountId,
});

export const userAccessFetchSucceeded = (
  accountId: string,
  users: User[]
): UserAccessAction => ({
  type: USER_ACCESS_FETCH_SUCCEEDED,
  users,
  accountId,
});

export const userSearch = (userSearchText: string): UserAccessAction => ({
  type: USER_SEARCH,
  userSearchText,
});

export const populateRolesForUsers = (
  sAMAccountName: string,
  accountId: string,
  roles: string[]
): UserAccessAction => ({
  type: POPULATE_ROLES_FOR_USER,
  sAMAccountName,
  accountId,
  roles,
});
