import * as t from 'io-ts';

export const ArfDirectiveConfig = t.type({
  notify: t.boolean,
  report: t.boolean,
  enabled: t.boolean,
  remediate: t.boolean,
});

export type ArfDirectiveConfig = t.TypeOf<typeof ArfDirectiveConfig>;

export const ArfDirective = t.type({
  href: t.string,
  id: t.string,
  policy: t.string,
  config: ArfDirectiveConfig,
  created: t.string,
  updated: t.string,
});

export type ArfDirective = t.TypeOf<typeof ArfDirective>;

export default ArfDirective;
