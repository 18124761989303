export interface ArfError {
  errorMessage?: string;
  errorType?: string;
  stackTrace?: string[];
}

export const isArfError = (obj: unknown): obj is ArfError => {
  return (
    typeof obj === 'object' &&
    !!obj &&
    ('errorMessage' in obj || 'errorType' in obj || 'stackTrace' in obj)
  );
};

export const arfErrorToString = (e: ArfError): string =>
  `${e.errorType ?? 'unknown type'}: ${e.errorMessage}\n${e.stackTrace}`;
