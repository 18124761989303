import * as React from 'react';

import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { ArfAccountRemediation } from '../../models';
import styled from 'styled-components';
import { Failure } from '../shared/Failure';
import { InfoTooltip } from '../shared/InfoTooltip';
import { Table } from '../table/Table';

export interface ModelProps {
  arfAccountRemediations: ArfAccountRemediation[] | undefined;
}

export type Props = ModelProps;

export const ArfAccountRemediations: React.FC<Props> = ({
  arfAccountRemediations,
}) => {
  if (!arfAccountRemediations) {
    return <Failure reason="Arf remediations data could not be loaded" />;
  }

  return (
    <>
      <Header2>Recent Remediations</Header2>
      {!arfAccountRemediations.length ? (
        <TextStyle>No remediations found.</TextStyle>
      ) : (
        <Table defaultPageSize={5}>
          <TableHead>
            <TableRow>
              <TableCell>Execution Id</TableCell>
              <TableCell>
                Policy Name{' '}
                <InfoTooltip disableInteractive={true} title="Name of the policy as known to the ARF system" />
              </TableCell>
              <TableCell>Region(s)</TableCell>
              <TableCell>
                Action(s){' '}
                <InfoTooltip disableInteractive={true} title="The action that has been performed on the specified resource in the specified region" />
              </TableCell>
              <TableCell>
                Resource{' '}
                <InfoTooltip disableInteractive={true} title="AWS infrastructure item that has been remediated" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arfAccountRemediations.map((remediation, index) => (
              <TableRow key={index}>
                <TableCell>{remediation.execution_id}</TableCell>
                <TableCell>{remediation.policy_name}</TableCell>
                <TableCell>
                  {remediation.policy_name.includes('s3')
                    ? 'global'
                    : remediation.regions.join(', ')}
                </TableCell>
                <TableCell>{remediation.actions.join(', ')}</TableCell>
                <TableCell>{remediation.resource_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

const TextStyle = styled.p`
  color: ${({ theme }) => theme.textColor};
`;

const Header2 = styled.h2`
  color: ${({ theme }) => theme.textColor};
`;

export default ArfAccountRemediations;
