import memoize from 'memoizee';
import { AccountView, Role } from '../models';

export const getIamCapableRole = memoize(
  (account: AccountView, roles: Map<string, Role>): Role | undefined => {
    const role = account.roles
      .map((roleId) => roles.get(roleId))
      .filter((role): role is Role => !!role)
      .filter((role) => role.iamEnabled)
      .shift();

    return role;
  }
);
