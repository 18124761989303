import * as React from 'react';
import {
  RouteProps,
  RouteComponentProps,
  Redirect,
  Route,
} from 'react-router-dom';
import { AccountView } from '../../models';
import qs from 'query-string';

export interface StateProps {
  activeAccount: AccountView | undefined;
  defaultAccount: AccountView | undefined;
  routerLocation: RouteComponentProps['location'];
}

export type Props = StateProps & RouteProps;

// this component is not covered in tests because it's too diffcult to test
export const AccountRoute: React.FC<Props> = ({
  activeAccount,
  defaultAccount,
  routerLocation,
  ...rest
}) => {
  if (!activeAccount && defaultAccount) {
    const parsedSearch = qs.parse(routerLocation.search);
    const newSearch = qs.stringify({
      ...parsedSearch,
      account: defaultAccount.id,
    });
    return (
      <Redirect
        to={{
          ...routerLocation,
          search: newSearch,
        }}
      />
    );
  }
  return <Route {...rest} location={routerLocation} />;
};

export default AccountRoute;
