import { KEYS_ARE_LOADING, KEYS_SUCCEEDED, KEYS_FAILED } from './types';
import { Key } from '../../models/Key';

export const keyFetchStarted = (accountId: string, roleId: string) => ({
  type: KEYS_ARE_LOADING,
  accountId,
  roleId,
});

export const keyFetchHasSucceeded = (key: Key) => ({
  type: KEYS_SUCCEEDED,
  key,
});

export const keyFetchHasFailed = (roleId: string, failureMessage: string) => ({
  type: KEYS_FAILED,
  roleId,
  failureMessage,
});
