import { createSelector } from 'reselect';
import { AccountView, getAccountView } from '../models';
import { getAccounts, getAccountPreferences } from './common';

export const getAccountViews = createSelector(
  [getAccounts, getAccountPreferences],
  (accounts, accountPreferences): Map<string, AccountView> =>
    Array.from(accounts.values()).reduce(
      (accountViews, account) =>
        accountViews.set(
          account.id,
          getAccountView(account, accountPreferences[account.id])
        ),
      new Map()
    )
);

export default getAccountViews;
