import {
  UserAccessState,
  UserAccessAction,
  USER_ACCESS_FETCH_FAILED,
  USER_ACCESS_FETCH_STARTED,
  USER_ACCESS_FETCH_SUCCEEDED,
  USER_SEARCH,
} from './types';
import { DataFetchStatus } from '../shared/types';
import { POPULATE_ROLES_FOR_USER } from './types';
import { User } from '../../models';

const initialState: UserAccessState = {
  byUserId: new Map(),
  fetchJobs: new Map(),
  userSearchText: '',
};

export const userAccessReducer = (
  state = initialState,
  action: UserAccessAction
): UserAccessState => {
  switch (action.type) {
    case USER_ACCESS_FETCH_STARTED: {
      const { accountId } = action;

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          status: DataFetchStatus.DATA_FETCH_PENDING,
          failureMessage: undefined,
        }),
      };
    }

    case USER_ACCESS_FETCH_FAILED: {
      const { failureMessage, accountId } = action;
      const job = state.fetchJobs.get(accountId);

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_FAILED,
          failureMessage,
        }),
      };
    }

    case USER_ACCESS_FETCH_SUCCEEDED: {
      const { users, accountId } = action;
      const job = state.fetchJobs.get(accountId);
      const newByUserId = new Map(state.byUserId);
      users.forEach((user) => {
        if (newByUserId.has(user.sAMAccountName)) {
          const existingUser = newByUserId.get(user.sAMAccountName) as User;
          newByUserId.set(user.sAMAccountName, {
            ...user,
            // Merge the map of roles by accountId on the existing user with the incoming user's list of roles for the just-fetched account
            roles: new Map(existingUser.roles).set(
              accountId,
              user.roles.get(accountId) as string[]
            ),
          });
        } else {
          newByUserId.set(user.sAMAccountName, user);
        }
      });

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
          failureMessage: undefined,
        }),
        byUserId: newByUserId,
      };
    }

    case USER_SEARCH: {
      const { userSearchText } = action;
      return {
        ...state,
        userSearchText,
      };
    }

    case POPULATE_ROLES_FOR_USER: {
      const { sAMAccountName, accountId, roles } = action;

      const user = state.byUserId.get(sAMAccountName);
      if (!user) {
        return state;
      }

      return {
        ...state,
        byUserId: new Map(state.byUserId).set(sAMAccountName, {
          ...user,
          roles: new Map(user.roles).set(accountId, roles),
        }),
      };
    }

    default: {
      return state;
    }
  }
};
