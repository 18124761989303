import { connect } from 'react-redux';
import { AppState } from '../store';
import { Switch } from 'react-router-dom';

/**
 * This container mitigates an issue where the ConnectedRouter fails to update
 * the router context passed to Switch when navigating by using the browser
 * back/forwards buttons.
 *
 * @see {@link https://github.com/ReactTraining/react-router/issues/5072|the related issue}
 */

const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
});

export const ConnectedSwitch = connect(mapStateToProps)(Switch);

export default ConnectedSwitch;
