import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import { QuestionIcon } from '@primer/octicons-react';
import * as React from 'react';
import styled from 'styled-components';
import { useTheme } from '../../styles/useTheme';

export type Props = Omit<TooltipProps, 'children'>;

export const InfoTooltip: React.FC<Props> = (props) => {
  const [theme] = useTheme();

  return (
    <StyledTooltip {...props}>
      <IconButton size="small">
        <QuestionIcon size={16} fill={theme.textColor} />
      </IconButton>
    </StyledTooltip>
  );
};

const InnerTooltip: React.FC<TooltipProps> = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
);

const StyledTooltip = styled(InnerTooltip)`
  && .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.tooltip.backgroundColor};
    color: ${({ theme }) => theme.tooltip.textColor};
    box-shadow: ${({ theme }) => '2px 2px 5px ' + theme.tooltip.shadowColor};
    margin: 4;
    padding: 5;
    font-size: ${({ theme }) => theme.tooltip.fontSize};
  }
`;
