import { createSelector } from 'reselect';
import { AccountView } from '../models';
import { getActiveAccountId } from './getActiveAccountId';
import { getAccountViews } from './getAccountViews';

/**
 * returns an AccountView object for the active account, or undefined if no
 * account is specified
 */
export const getActiveAccount = createSelector(
  [getActiveAccountId, getAccountViews],
  (activeAccountId, accounts): AccountView | undefined =>
    activeAccountId ? accounts.get(activeAccountId) : undefined
);

export default getActiveAccount;
