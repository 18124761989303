import { DataFetchStatus } from '../shared/types';
import {
  COST_DATA_FETCH_FAILED,
  COST_DATA_FETCH_STARTED,
  COST_DATA_FETCH_SUCCEEDED,
  CostDataActionType,
  CostDataState,
} from './types';

const initialState: CostDataState = {
  fetchJobs: new Map(),
  byId: new Map(),
};

export const costDataReducer = (
  state = initialState,
  action: CostDataActionType
): CostDataState => {
  switch (action.type) {
    case COST_DATA_FETCH_STARTED: {
      const { accountId } = action;

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          status: DataFetchStatus.DATA_FETCH_PENDING,
          failureMessage: undefined,
        }),
      };
    }

    case COST_DATA_FETCH_FAILED: {
      const { accountId, failureMessage } = action;
      const job = state.fetchJobs.get(accountId);

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_FAILED,
          failureMessage,
        }),
      };
    }

    case COST_DATA_FETCH_SUCCEEDED: {
      const { accountId, costData } = action;
      const job = state.fetchJobs.get(accountId);

      if (costData === undefined) {
        return {
          ...state,
          fetchJobs: new Map(state.fetchJobs).set(accountId, {
            ...job,
            status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
            failureMessage: undefined,
          }),
        };
      }

      return {
        ...state,
        fetchJobs: new Map(state.fetchJobs).set(accountId, {
          ...job,
          status: DataFetchStatus.DATA_FETCH_SUCCEEDED,
          failureMessage: undefined,
        }),
        byId: new Map(state.byId).set(accountId, costData),
      };
    }

    default: {
      return state;
    }
  }
};
