export interface ShallowCompareOptions {
  blackList?: Array<string | number | symbol>; // A list of keys to ignore when comparing objects
}

/**
 * returns true if the 2 objects have the same values for all overlapping keys
 */
export const shallowCompare = (
  obj1: object,
  obj2: object,
  options?: ShallowCompareOptions
): boolean => {
  const blackList = (options && options.blackList) || [];
  for (const key in obj1) {
    if (
      !(key in blackList) &&
      obj1.hasOwnProperty(key) &&
      obj2.hasOwnProperty(key) &&
      obj1[key] !== obj2[key]
    ) {
      return false;
    }
  }
  return true;
};

export default shallowCompare;
