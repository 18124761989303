export const ACCOUNT_SEARCH = 'ACCOUNT_SEARCH';
export type ACCOUNT_SEARCH = typeof ACCOUNT_SEARCH;

export const ADD_SEARCH_TAG = 'ADD_SEARCH_TAG';
export type ADD_SEARCH_TAG = typeof ADD_SEARCH_TAG;

export const REMOVE_SEARCH_TAG = 'REMOVE_SEARCH_TAG';
export type REMOVE_SEARCH_TAG = typeof REMOVE_SEARCH_TAG;

interface AccountSearch {
  type: ACCOUNT_SEARCH;
  accountSearchText: string;
}

interface AddSearchTag {
  type: ADD_SEARCH_TAG;
  tag: string;
  include: boolean;
}

interface RemoveSearchTag {
  type: REMOVE_SEARCH_TAG;
  tag: string;
}

export type SearchActionType = AccountSearch | AddSearchTag | RemoveSearchTag;

export interface SearchState {
  accountSearchText: string;
}
