import {
  arfAccountFetchStarted,
  arfAccountFetchFailed,
  arfAccountFetchSucceeded,
} from '../store/arfAccount/actions';
import * as config from '../config';
import { ThunkAction } from '../store/shared/types';
import {
  applyDefaultArfAccountProperties,
  ArfAccount,
  arfErrorToString,
  isArfError,
} from '../models';
import { fetch } from '../util/fetch';
import { OktaAuth } from '@okta/okta-auth-js';
import getErrorMessage from '../util/getErrorMessage';
import { toast } from 'react-toastify';

export const fetchArfAccount = (
  auth: OktaAuth,
  accountId: string
): ThunkAction<void> => async (dispatch) => {
  dispatch(arfAccountFetchStarted(accountId));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
  } catch (e) {
    const reason = `An error occured when requesting an access token from Okta: ${getErrorMessage(
      e
    )}`;
    dispatch(arfAccountFetchFailed(accountId, reason));
    throw e;
  }

  const endpoint = `${config.default.service.url}${config.default.service.arf.path}/accounts/id/${accountId}`;
  let response: Response;
  try {
    response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Api-Key': config.default.service.apiKey,
      },
    });
  } catch (e) {
    const reason = `An error occured while requesting the ARF account data for account ${accountId}: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountFetchFailed(accountId, reason));
    throw e;
  }

  if (!response.ok) {
    const reason = `Received http status code while fetching ARF account data: ${response.status}`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountFetchFailed(accountId, reason));
    throw new Error(reason);
  }

  let responseJson = await response.json();
  if (!responseJson) {
    const reason = `No response body was returned when fetching ARF account data`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountFetchFailed(accountId, reason));
    throw new Error(reason);
  }

  if (isArfError(responseJson)) {
    const reason = `Received error from ARF while fetching account data: ${responseJson.errorMessage}`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountFetchFailed(accountId, reason));
    console.error(`Error from ARF: ${arfErrorToString(responseJson)}`);
    throw new Error(reason);
  }

  if (typeof responseJson === 'object') {
    responseJson = applyDefaultArfAccountProperties(responseJson);
  }

  if (ArfAccount.is(responseJson)) {
    const arfAccountData = responseJson;
    dispatch(arfAccountFetchSucceeded(accountId, arfAccountData));
  } else {
    const reason = `The ARF account data fetched for ${accountId} does not match the expected type structure`;
    toast(reason, { type: 'error' });
    dispatch(arfAccountFetchFailed(accountId, reason));
    throw new Error(reason);
  }
};
