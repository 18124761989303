import {
  IamRolesAction,
  IAM_ROLES_ARE_LOADING,
  IAM_ROLES_SUCCEEDED,
  IAM_ROLES_FAILED,
  IAM_ROLES_ARE_DELETING,
  IAM_ROLES_DELETE_FAILED,
  IAM_ROLES_DELETE_SUCCEEDED,
  IAM_ROLE_CREATION_STARTED,
  IAM_ROLE_CREATION_FAILED,
  IAM_ROLE_CREATION_SUCCEEDED,
  IAM_ROLE_FORM_JOB_ID_UPDATED,
  IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED,
  IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED,
  IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED,
  IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED,
  IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED,
  IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED,
  IAM_ROLE_UPDATE_FAILED,
  IAM_ROLE_UPDATE_STARTED,
  IAM_ROLE_UPDATE_SUCCEEDED,
} from './types';
import { IamRole } from '../../models';

export const iamRolesFetchStarted = (accountId: string): IamRolesAction => ({
  type: IAM_ROLES_ARE_LOADING,
  accountId,
});

export const iamRolesFetchHasSucceeded = (
  accountId: string,
  iamRoles: IamRole[]
): IamRolesAction => ({
  type: IAM_ROLES_SUCCEEDED,
  accountId,
  iamRoles,
});

export const iamRolesFetchHasFailed = (
  accountId: string,
  reason: string
): IamRolesAction => ({
  type: IAM_ROLES_FAILED,
  accountId,
  failureMessage: reason,
});

export const iamRolesDeleteStarted = (iamRoleId: string): IamRolesAction => ({
  type: IAM_ROLES_ARE_DELETING,
  iamRoleId,
});

export const iamRolesDeleteHasSucceeded = (
  iamRoleId: string
): IamRolesAction => ({
  type: IAM_ROLES_DELETE_SUCCEEDED,
  iamRoleId,
});

export const iamRolesDeleteHasFailed = (
  iamRoleId: string,
  failureMessage: string
): IamRolesAction => ({
  type: IAM_ROLES_DELETE_FAILED,
  iamRoleId,
  failureMessage,
});

export const iamRoleCreationStarted = (
  jobId: number,
  accountId: string,
  roleName: string
): IamRolesAction => ({
  type: IAM_ROLE_CREATION_STARTED,
  jobId,
  accountId,
  roleName,
});

export const iamRoleCreationFailed = (
  jobId: number,
  reason: string
): IamRolesAction => ({
  type: IAM_ROLE_CREATION_FAILED,
  jobId,
  failureMessage: reason,
});

export const iamRoleCreationSucceeded = (
  jobId: number,
  iamRole: IamRole
): IamRolesAction => ({
  type: IAM_ROLE_CREATION_SUCCEEDED,
  jobId,
  iamRole,
});

export const iamRoleFormJobIdUpdated = (jobId: number): IamRolesAction => ({
  type: IAM_ROLE_FORM_JOB_ID_UPDATED,
  jobId,
});

export const iamRoleCreateMachineIdentityStarted = (
  jobId: number,
  accountId: string,
  roleName: string,
  roleArn: string
): IamRolesAction => ({
  type: IAM_ROLE_CREATE_MACHINE_IDENTITY_STARTED,
  jobId,
  accountId,
  roleName,
  roleArn,
});

export const iamRoleCreateMachineIdentityFailed = (
  jobId: number,
  failureMessage: string
): IamRolesAction => ({
  type: IAM_ROLE_CREATE_MACHINE_IDENTITY_FAILED,
  jobId,
  failureMessage,
});

export const iamRoleCreateMachineIdentitySucceeded = (
  jobId: number,
  roleArn: string,
  roleId: string
): IamRolesAction => ({
  type: IAM_ROLE_CREATE_MACHINE_IDENTITY_SUCCEEDED,
  jobId,
  roleArn,
  roleId,
});

export const iamRoleDeleteMachineIdentityStarted = (
  roleArn: string
): IamRolesAction => ({
  type: IAM_ROLE_DELETE_MACHINE_IDENTITY_STARTED,
  roleArn,
});

export const iamRoleDeleteMachineIdentityFailed = (
  roleArn: string,
  failureMessage: string
): IamRolesAction => ({
  type: IAM_ROLE_DELETE_MACHINE_IDENTITY_FAILED,
  roleArn,
  failureMessage,
});

export const iamRoleDeleteMachineIdentitySucceeded = (
  roleArn: string,
  roleId: string
): IamRolesAction => ({
  type: IAM_ROLE_DELETE_MACHINE_IDENTITY_SUCCEEDED,
  roleArn,
  roleId,
});

export const iamRoleUpdateStarted = (roleId: string): IamRolesAction => ({
  type: IAM_ROLE_UPDATE_STARTED,
  roleId,
});

export const iamRoleUpdateFailed = (
  roleId: string,
  failureMessage: string
): IamRolesAction => ({
  type: IAM_ROLE_UPDATE_FAILED,
  roleId,
  failureMessage,
});

export const iamRoleUpdateSucceeded = (
  roleId: string,
  iamRole: IamRole
): IamRolesAction => ({
  type: IAM_ROLE_UPDATE_SUCCEEDED,
  roleId,
  iamRole,
});
