import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import VisibleStarAccountPreference from '../../containers/VisibleStarAccountPreference';
import VisibleRolePreference from '../../containers/VisibleRolePreference';
import VisibleRoleDurationPreference from '../../containers/VisibleRoleDurationPreference';
import VisibleTagPreference from '../../containers/VisibleTagPreference';
import styled from 'styled-components';

export const Preference: React.FC = () => (
  <PreferencesDiv>
    <h1>Preferences</h1>
    <hr />
    <div>
      <h5>Account</h5>
      <hr />
      <Row>
        <Col md="6" sm="6" xs="8" id="starAccountToggleLabel">
          Toggle starred account
        </Col>
        <Col md="6" sm="6" xs="4">
          <VisibleStarAccountPreference />
        </Col>
      </Row>
      <h5>Role</h5>
      <hr />
      <Row>
        <Col md="6" sm="6" xs="6" id="preferredRoleLabel">
          Preferred role
        </Col>
        <Col md="6" sm="6" xs="6">
          <VisibleRolePreference />
        </Col>
      </Row>
      <Row>
        <Col md="6" sm="6" xs="6">
          Role duration
        </Col>
        <Col md="6" sm="6" xs="6">
          <VisibleRoleDurationPreference />
        </Col>
      </Row>
      <h5>Tags</h5>
      <hr />
      <Row>
        <Col md="6" sm="6" xs="auto">
          Associated Tags
        </Col>
        <Col md="6" sm="6" xs="auto">
          <VisibleTagPreference />
        </Col>
      </Row>
    </div>
  </PreferencesDiv>
);

const PreferencesDiv = styled.div`
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};

  & hr {
    background-color: ${({ theme }) => theme.borderColor};
  }
`;

export default Preference;
