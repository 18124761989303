import * as React from 'react';
import { DataFetchStatus } from '../../store/shared/types';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export interface ModelProps {
  fetchStatus: DataFetchStatus | undefined;
}

export interface DispatchProps {
  fetchRoleTypes: (auth: OktaAuth) => void;
}

export type Props = ModelProps & DispatchProps;

/**
 * Initiates a fetch for iam roles for each account indicated by `accountIds`
 */
export const RoleTypesFetcher: React.FC<Props> = ({
  fetchStatus,
  fetchRoleTypes,
}) => {
  const { oktaAuth } = useOktaAuth();

  if (!fetchStatus || fetchStatus === DataFetchStatus.DATA_FETCH_UNSTARTED) {
    fetchRoleTypes(oktaAuth);
  }
  return null;
};

export default RoleTypesFetcher;
