import memoize from 'memoizee';
import { AccountView, Role } from '../models';
import { getRolePriority } from '../util/getRolePriority';

/**
 * Gets the preferred Role object for an account or undefined if the account has
 * no roles
 *
 * @param account - the account whose preferred role should be retrieved
 * @param roles - a map of role IDs to roles
 * @return the account's preferred role
 */
export const getPreferredRole = memoize(
  <R extends Role>(
    account: AccountView,
    roles: Map<string, R>
  ): R | undefined =>
    (account.preferredRole && roles.has(account.preferredRole)
      ? roles.get(account.preferredRole)
      : account.roles
          .map((roleId) => roles.get(roleId))
          .filter((r): r is R => !!r)
          .sort((a, b) => getRolePriority(a) - getRolePriority(b))
          .shift()) as R,
  { length: 2 }
);

export default getPreferredRole;
