import { Dispatch } from '../shared/types';
import { logError } from '../../util/errorLogger';

export const thunkErrorHandler = () => (next: Dispatch) => (action: any) => {
  let result: any;
  try {
    result = next(action);
  } catch (error) {
    handleError(error as Error);
  }

  if (result instanceof Promise) {
    result.catch(handleError);
  }

  return result;
};

const handleError = (error: Error) => {
  logError(error);
};

export default thunkErrorHandler;
