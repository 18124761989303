import { AppState } from '../store';
import ImportExportTagPreference, {
  ModelProps,
  DispatchProps,
  OwnProps,
} from '../components/preferences/ImportExportTagPreference';
import { showConfirmationModal } from '../store/modal/actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Tag } from '../models';
import { addTags } from '../store/preferences/actions';

const mapStateToProps = (state: AppState, ownProps: OwnProps): ModelProps => ({
  tagPrefs: state.ui.preferences.preferenceState.accounts,
  accountIds: state.data.accounts.allIds,
  isModalOpen: ownProps.isModalOpen,
  modalToggle: ownProps.modalToggle,
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  openConfirmation: (f: () => void) =>
    dispatch(
      showConfirmationModal(
        'Are you sure you want to import these tags',
        'Import Tags',
        f
      )
    ),
  importTags: (tags: Record<string, Record<string, Tag>>) => {
    dispatch(addTags(tags));
    toast('Successfully imported tags', { type: 'success' });
  },
});

export const VisibleImportExportTagPreference = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportExportTagPreference);

export default VisibleImportExportTagPreference;
