import { createSelector } from 'reselect';
import { getActiveAccount } from './getActiveAccount';

/**
 * Returns the active account name
 * example: awsalks
 */
export const getActiveAccountName = createSelector(
  [getActiveAccount],
  (activeAccount): string | undefined => {
    if (activeAccount && activeAccount.name) {
      return activeAccount.name;
    }
    return undefined;
  }
);

export default getActiveAccountName;
