import { createSelector } from 'reselect';
import { getAnnouncementShow } from '../selectors/common';

/**
 * Gets the boolean for if the Announcement should be shown
 */
export const shouldShowAnnouncement = createSelector(
  [getAnnouncementShow],
  (show): boolean => {
    return show;
  }
);

export default shouldShowAnnouncement;
