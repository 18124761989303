import { connect } from 'react-redux';
import { IamRole, OwnProps, Props } from '../components/iamRole/IamRole';
import { AppState } from '../store';
import { getActiveIamRole } from '../selectors/getActiveIamRole';
import { iamRoleLoader } from '../util/dataLoaders/iamRoleLoader';
import { loadData } from '../util/loadData';
import { Dispatch } from '../store/shared/types';
import { createRoleMachineIdentity } from '../operations/createRoleMachineIdentity';
import {
  getIamRoleFormJobId,
  getDeleteMIJobs,
  getRoles,
} from '../selectors/common';
import { deleteRoleMachineIdentity } from '../operations/deleteRoleMachineIdentity';
import { getIamRoleMIJobStatus } from '../selectors/getIamRoleMIJobStatus';
import { showConfirmationModal } from '../store/modal/actions';
import deleteIamRoles from '../operations/deleteIamRoles';
import { updateIamRole, RequestDetails } from '../operations/updateIamRole';
import { IamRole as IamRoleModel } from '../models';
import getActiveAccount from '../selectors/getActiveAccount';
import getActiveAccountId from '../selectors/getActiveAccountId';
import getDeleteJobsForActiveAccount from '../selectors/getDeleteJobsForActiveAccount';
import { getHighestPriorityIAMRole } from '../util/getHighestPriorityIAMRole';
import { OktaAuth } from '@okta/okta-auth-js';
import { iamRoleFormJobIdUpdated } from '../store/iamRoles/actions';

const mapStateToProps = (state: AppState) => {
  const activeAccount = getActiveAccount(state);
  const role = activeAccount
    ? getHighestPriorityIAMRole(activeAccount, getRoles(state))
    : undefined;
  const iamRoleDeleteJobs = getDeleteJobsForActiveAccount(state);
  const accountSecurityLevel = activeAccount?.securityLevel ?? '2';

  return {
    iamRole: getActiveIamRole(state),
    jobId: getIamRoleFormJobId(state),
    MICreationStatus: getIamRoleMIJobStatus(state),
    MIDeleteJobs: getDeleteMIJobs(state),
    iamRoleDeleteJobs,
    role,
    accountSecurityLevel,
    accountId: getActiveAccountId(state),
  };
};
type MappedPropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  enableMachineIdentity: (
    jobId: number,
    auth: OktaAuth,
    accountId: string,
    iamRoleName: string,
    iamRoleId: string,
    iamRoleArn: string
  ) =>
    dispatch(
      createRoleMachineIdentity(
        jobId,
        auth,
        accountId,
        iamRoleName,
        iamRoleId,
        iamRoleArn
      )
    ),
  disableMachineIdentity: (
    auth: OktaAuth,
    iamRoleArn: string,
    iamRoleId: string
  ) => dispatch(deleteRoleMachineIdentity(auth, iamRoleArn, iamRoleId)),
  openConfirmation: (f: () => void, iamRoleName: string) =>
    dispatch(showConfirmationModal(iamRoleName, 'Delete role', f)),
  deleteRole: (
    auth: OktaAuth,
    accountId: string | undefined,
    role: string,
    iamRole: IamRoleModel
  ) => {
    if (accountId) {
      dispatch(deleteIamRoles(auth, accountId, role, iamRole));
    }
  },
  updateRole: (
    auth: OktaAuth,
    accountId: string,
    role: string,
    details: RequestDetails
  ) => {
    dispatch(updateIamRole(auth, accountId, role, details));
  },
  setIamRoleFormJobId: (jobId: number) =>
    dispatch(iamRoleFormJobIdUpdated(jobId)),
});
type MappedPropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (
  stateProps: MappedPropsFromState,
  dispatchProps: MappedPropsFromDispatch,
  ownProps: OwnProps
): Props => {
  const newDispatchProps = {
    ...dispatchProps,
    enableMachineIdentity: dispatchProps.enableMachineIdentity.bind(
      null,
      stateProps.jobId
    ),
    deleteRole: (auth: OktaAuth, iamRole: IamRoleModel) =>
      stateProps.role
        ? dispatchProps.deleteRole(
            auth,
            stateProps.accountId,
            stateProps.role.name,
            iamRole
          )
        : undefined,
    /** To give some context to the next dev wondering what's going on here: Some
     * arguments (accountId and role) are never passed through to the component
     * layer. They are required arguments, nonetheless, so they must present
     * themselves when updateRole is called. Instead of passing updateRole with
     * full arity, the below partial function (updateRole(auth, details)) is
     * passed to the component. When called, the remaining arguments are amended
     * to the signature before calling the function.
     */
    updateRole: (auth: OktaAuth, details: RequestDetails) =>
      stateProps.role && stateProps.accountId
        ? dispatchProps.updateRole(
            auth,
            stateProps.accountId,
            stateProps.role.name,
            details
          )
        : undefined,
  };

  return {
    ...ownProps,
    ...stateProps,
    ...newDispatchProps,
  };
};

export const ActiveIamRole = loadData(
  [iamRoleLoader],
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(IamRole)
);

export default ActiveIamRole;
