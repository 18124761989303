import { ArfAccountViolation } from '../models';
import { getActiveAccountId } from './getActiveAccountId';
import { getArfAccountViolationsById } from './common';
import { createSelector } from 'reselect';

export const getActiveArfAccountViolations = createSelector(
  [getArfAccountViolationsById, getActiveAccountId],
  (arfAccountViolations, accountId): ArfAccountViolation[] | undefined => {
    return accountId ? arfAccountViolations?.get(accountId) : undefined;
  }
);

export default getActiveArfAccountViolations;
