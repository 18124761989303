import * as React from 'react';
import {
  PlusIcon,
  DiffRemovedIcon,
  SearchIcon,
  TagIcon,
} from '@primer/octicons-react';
import {
  UncontrolledCollapse,
  InputGroup,
  Form,
  Input,
  Button,
  Label,
} from 'reactstrap';
import { TagTerm } from '../../models';
import { SearchTag as Chip } from '../shared/SearchTag';
import styles from './AdvancedSearch.module.scss';
import styled from 'styled-components';

export interface ModelProps {
  tags: TagTerm[];
  globalTags: Record<string, number>;
}

export interface DispatchProps {
  addTag: (tag: string, include: boolean) => void;
  removeTag: (tag: string) => void;
}

export type Props = ModelProps & DispatchProps;

export const AdvancedSearch: React.FC<Props> = (props) => {
  const [excludeTag, setExcludeTag] = React.useState(false);
  const toggleExcludeTag = () => {
    setExcludeTag(!excludeTag);
  };

  const [advancedSearchOpen, setAdvancedSearchOpen] = React.useState(true);
  const toggleAdvancedSearchOpen = () =>
    setAdvancedSearchOpen(!advancedSearchOpen);

  const [input, setInput] = React.useState('');

  const addTag = (tag: string) => (e: React.FormEvent) => {
    e.preventDefault();
    if (tag !== '') {
      props.addTag(tag, !excludeTag);
      setInput('');
    }
  };
  const removeTag = (tag: string) => () => {
    props.removeTag(tag);
  };

  return (
    <>
      <AdvancedSearchSpan
        onClick={toggleAdvancedSearchOpen}
        className={styles.advancedSearch}
        id="toggler"
        color="secondary"
        aria-controls="advancedSearchCollapse"
        aria-expanded={advancedSearchOpen}
      >
        {advancedSearchOpen ? <PlusIcon /> : <DiffRemovedIcon />}
        {' Advanced search'}
      </AdvancedSearchSpan>
      <UncontrolledCollapse toggler="toggler" id="advancedSearchCollapse">
        <InputGroup size="sm">
          <div>
            <Input
              type="checkbox"
              id="excludeResults"
              label="Exclude Tag"
              onChange={toggleExcludeTag}
              inline="true"
              checked={excludeTag}
            />
            {' '}
            <StyledLabel for="excludeResults">Exclude Tag</StyledLabel>
          </div>
        </InputGroup>
        <Form onSubmit={addTag(input)}>
          <InputGroup size="sm">
            <StyledInput
              list="tags"
              value={input}
              onChange={(e) => setInput(e.currentTarget.value)}
              placeholder="Filter by tags..."
              aria-label="specify tag name"
            />
            <datalist id="tags">
              {Object.keys(props.globalTags).map((e) => {
                return <option key={e} value={e} />;
              })}
            </datalist>
            <Button onClick={addTag(input)} aria-label="add tag">
              <SearchIcon />
            </Button>
          </InputGroup>
        </Form>
        <TagIconDiv>
          <TagIcon />
        </TagIconDiv>
        <div id="tagFields">
          {props.tags.map((tag) => (
            <span key={`span-${tag.text}`}>
              <Chip
                key={`Tag-${tag.text}`}
                value={tag.tag}
                onClose={removeTag(tag.text)}
                include={tag.include}
              />{' '}
            </span>
          ))}
        </div>
      </UncontrolledCollapse>
    </>
  );
};

const AdvancedSearchSpan = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.textColor};
`;

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }

  &::placeholder {
    color: ${({ theme }) => theme.input.text.placeholder.textColor};
  }

  margin-bottom: 0.5rem;
`;

const TagIconDiv = styled.div`
  color: ${({ theme }) => theme.textColor};
`;

export default AdvancedSearch;
