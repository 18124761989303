import { Role, Key } from '../../models';
import { DataFetchStatus } from '../shared/types';

export const ROLES_ARE_LOADING = 'ROLES_ARE_LOADING';
export type ROLES_ARE_LOADING = typeof ROLES_ARE_LOADING;

export const ROLES_SUCCEEDED = 'ROLES_SUCCEEDED';
export type ROLES_SUCCEEDED = typeof ROLES_SUCCEEDED;

export const ROLES_FAILED = 'ROLES_FAILED';
export type ROLES_FAILED = typeof ROLES_FAILED;

export const NEW_KEY = 'NEW_KEY';
export type NEW_KEY = typeof NEW_KEY;

interface RolesLoadingAction {
  type: ROLES_ARE_LOADING;
}

interface RolesSucceededAction {
  type: ROLES_SUCCEEDED;
  roles: Role[];
}

interface RolesFailedAction {
  type: ROLES_FAILED;
  failureMessage: string;
}

interface NewKeyAction {
  type: NEW_KEY;
  key: Key;
  roleId: string;
}

export type RolesActionType =
  | RolesLoadingAction
  | RolesSucceededAction
  | RolesFailedAction
  | NewKeyAction;

export interface RoleState {
  fetchStatus: DataFetchStatus;
  byId: Map<string, Role>;
  allIds: string[];
  failureMessage?: string;
}
