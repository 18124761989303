import { connect } from 'react-redux';
import AccountSearchBar, {
  ModelProps as AccountSearchBarModelProps,
  DispatchProps as AccountSearchBarDispatchProps,
} from '../components/accountList/SearchBar';
import { AppState } from '../store';
import { searchAccount } from '../store/search/actions';
import { getAccountSearchText } from '../selectors/common';

const mapStateToProps = (state: AppState): AccountSearchBarModelProps => ({
  filterText: getAccountSearchText(state),
});

const mapDispatchToProps = (dispatch: any): AccountSearchBarDispatchProps => ({
  onFilterTextChange: (filterText: string) =>
    dispatch(searchAccount(filterText)),
});

export const SearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSearchBar);

export default SearchBar;
