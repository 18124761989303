import { ThunkAction } from '../store/shared/types';
import {
  iamRoleCreationStarted,
  iamRoleCreationFailed,
  iamRoleCreationSucceeded,
} from '../store/iamRoles/actions';
import ALKS from 'alks.js';
import * as config from '../config';
import { IamRole } from '../models';
import { hashCode } from '../util/hashCode';
import { parseRoleArn } from '../util/parseRoleArn';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';

export interface RequestDetails {
  name: string;
  isMachineIdentity: boolean;
  trustPolicy: Record<string, any>;
}

/**
 * Creates a new IAM Role
 *
 * @param jobId - the ID to use for the create role job
 * @param auth - the Okta auth object from state
 * @param accountId - the ID of the account to create the IAM role in
 * @param nonIamRoleName - the name of the role to use to create the IAM role (e.g. Admin)
 * @param details - details for the role to be created
 * @param details.name - the name of the role to create
 * @param details.isMachineIdentity - whether the IAM role should be a machine identity
 * @param details.roleType - the type of the role (e.g. "Cross Account" or "Amazon EC2")
 * @param [details.trustArn] - the ARN to include in the trust policy (for Cross Account and Inner Account roles only)
 * @return a void-yielding promise that resolves when the thunk is finished executing
 */
export const createIamRole = (
  jobId: number,
  auth: OktaAuth,
  accountId: string,
  nonIamRoleName: string,
  details: RequestDetails
): ThunkAction<void> => async (dispatch) => {
  dispatch(iamRoleCreationStarted(jobId, accountId, details.name));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(iamRoleCreationFailed(jobId, reason));
    throw e;
  }

  const alks = ALKS.create({
    baseUrl: config.default.alks.baseUrl,
    accessToken,
  });
  let createdRole: ALKS.Role;
  try {
    createdRole = await alks.createRole({
      account: `${accountId}/ALKS${nonIamRoleName}`,
      role: nonIamRoleName,
      roleName: details.name,
      trustPolicy: details.trustPolicy,
      enableAlksAccess: details.isMachineIdentity,
      includeDefaultPolicy: 0,
    });
  } catch (e) {
    const reason = `An error occured when fetching IAM roles from ALKS: ${getErrorMessage(
      e
    )}`;
    dispatch(iamRoleCreationFailed(jobId, reason));
    throw e;
  }

  const iamRoleData = parseRoleArn(createdRole.roleArn);
  const iamRole: IamRole = {
    id: `iam-role-${hashCode(createdRole.roleArn)}`,
    name: iamRoleData.name,
    arn: createdRole.roleArn,
    accountId: iamRoleData.accountId,
    isMachineIdentity: details.isMachineIdentity, // TODO: add isMachineIdentity field to ALKS's create (non-service) role response and pull the value from that response
    trustPolicy: details.trustPolicy, // TODO: add trust policy to ALKS's create (non-service) role response and add it here
  };

  toast(`IAM role created successfully: ${details.name}`, { type: 'success' });
  dispatch(iamRoleCreationSucceeded(jobId, iamRole));
};

export default createIamRole;
