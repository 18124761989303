import { createSelector } from 'reselect';
import { getAccountViews } from './getAccountViews';
import { AccountView } from '../models/AccountView';

export const getUnstarredAccounts = createSelector(
  [getAccountViews],
  (accountViews): AccountView[] =>
    Array.from(accountViews.values()).filter(
      (accountView) => !accountView.starred
    )
);

export default getUnstarredAccounts;
