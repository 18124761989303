import { Job } from '../shared/types';
import { ArfAccount } from '../../models';

export const ARF_ACCOUNT_FETCH_STARTED = 'ARF_ACCOUNT_FETCH_STARTED';
export type ARF_ACCOUNT_FETCH_STARTED = typeof ARF_ACCOUNT_FETCH_STARTED;

export const ARF_ACCOUNT_FETCH_SUCCEEDED = 'ARF_ACCOUNT_FETCH_SUCCEEDED';
export type ARF_ACCOUNT_FETCH_SUCCEEDED = typeof ARF_ACCOUNT_FETCH_SUCCEEDED;

export const ARF_ACCOUNT_FETCH_FAILED = 'ARF_ACCOUNT_FETCH_FAILED';
export type ARF_ACCOUNT_FETCH_FAILED = typeof ARF_ACCOUNT_FETCH_FAILED;

interface ArfAccountStartedAction {
  type: ARF_ACCOUNT_FETCH_STARTED;
  accountId: string;
}

interface ArfAccountSucceededAction {
  type: ARF_ACCOUNT_FETCH_SUCCEEDED;
  accountId: string;
  arfAccount: ArfAccount;
}

interface ArfAccountFailedAction {
  type: ARF_ACCOUNT_FETCH_FAILED;
  accountId: string;
  failureMessage: string;
}

export type ArfAccountActionType =
  | ArfAccountStartedAction
  | ArfAccountSucceededAction
  | ArfAccountFailedAction;

export interface ArfAccountState {
  fetchJobs: Map<string, Job>;
  byId: Map<string, ArfAccount>;
}
