import memoize from 'memoizee';
import { User } from '../models';

export const getMatchingUsers = memoize(
  <T extends User>(users: T[], filter: string): T[] =>
    users.filter(
      (user) =>
        user.sAMAccountName.toLowerCase().includes(filter.toLowerCase()) ||
        user.displayName.toLowerCase().includes(filter.toLowerCase()) ||
        (user.department &&
          user.department.toLowerCase().includes(filter.toLowerCase())) ||
        (user.title &&
          user.title.toLowerCase().includes(filter.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(filter.toLowerCase()))
    )
);

export default getMatchingUsers;
