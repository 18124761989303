/**
 * Window Fetch with timeout configuration, default timeout of 10 seconds
 * @param fetchTimeout timeout configuration in milliseconds
 * This was written referencing https://davidwalsh.name/fetch-timeout
 */
export const fetchWithTimeout = (
  resouce: RequestInfo,
  options: RequestInit,
  fetchTimeout = 10000
): Promise<Response> => {
  let didTimeOut = false;
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true;
      reject(new Error('Request timed out'));
    }, fetchTimeout);

    fetch(resouce, options)
      .then((response) => {
        clearTimeout(timeout);
        if (!didTimeOut) {
          resolve(response);
        }
      })
      .catch((err) => {
        // rejection already happened with setTimeout
        if (didTimeOut) {
          return;
        }
        // reject with error
        reject(err);
      });
  });
};

export default fetchWithTimeout;
