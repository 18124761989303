import * as React from 'react';
import { VisibleAnnouncement } from '../../containers/VisibleAnnouncement';
import { VisibleNavigationBar } from '../../containers/VisibleNavigationBar';
import { ToastContainer } from 'react-toastify';
import { VisibleNewRelicTracker } from '../../containers/VisibleNewRelicTracker';
import { Alert } from 'reactstrap';
import Bowser from 'bowser';
import { useTheme } from '../../styles/useTheme';

const browser = Bowser.getParser(window.navigator.userAgent);
const isIE = browser.is('Internet Explorer');
const showAlert = () => {
  return (
    <Alert color="danger" className="text-center">
      <h4 className="alert-heading">Uh oh!</h4>
      <p>
        Looks like you are using an unsupported browser:{' '}
        <b>{browser.getBrowserName()}</b>
      </p>
    </Alert>
  );
};

export const Nav: React.FC = () => {
  const [theme] = useTheme();

  return (
    <section>
      {isIE && showAlert()}
      <VisibleAnnouncement />
      <VisibleNavigationBar />
      <VisibleNewRelicTracker />
      <ToastContainer hideProgressBar={true} theme={theme.id === 'light mode' ? 'light' : 'dark'} />
    </section>
  )
};
