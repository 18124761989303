import * as config from '../config';
import { ThunkAction } from '../store/shared/types';
import {
  announcementsFetchHasSucceeded,
  announcementsFetchStarted,
  announcementsFetchHasFailed,
} from '../store/announcement/actions';
import { fetchWithTimeout } from '../util/fetchWithTimeout';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';

/**
 * Fetches all the announcements from the API gateway endpoint
 */
export const fetchAnnouncements = (auth: OktaAuth): ThunkAction<void> => async (
  dispatch
) => {
  dispatch(announcementsFetchStarted());

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `Failed to fetch announcements. An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(reason, {
      type: 'error',
    });
    dispatch(announcementsFetchHasFailed(reason));
    throw e;
  }

  let response: Response;

  try {
    response = await fetchWithTimeout(
      `${config.default.service.url}${config.default.service.announcement.path}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Api-Key': config.default.service.apiKey,
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    // dispatch(announcementsFetchHasFailed(e.message));
    const reason = `Failed to fetch announcements, an unexpected error occurred: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });

    // Pretend it succeeded
    dispatch(announcementsFetchHasSucceeded([]));
    throw e;
  }
  if (response.status !== 200) {
    dispatch(announcementsFetchHasFailed(response.statusText + ' error'));
  } else {
    const data = await response.json();
    // sometimes the api might return a 200 but not have the announcements array
    if (data.announcements) {
      dispatch(announcementsFetchHasSucceeded(data.announcements));
    } else {
      dispatch(announcementsFetchHasFailed('api did not return announcements'));
    }
  }
};

export default fetchAnnouncements;
