import { ThunkAction } from '../store/shared/types';
import { CustomUserClaims, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import {
  userInfoFetchFailed,
  userInfoFetchStarted,
  userInfoFetchSucceeded,
} from '../store/userInfo/actions';
import { getErrorMessage } from '../util/getErrorMessage';

export const fetchUserInfo = (auth: OktaAuth): ThunkAction<void> => async (
  dispatch
) => {
  dispatch(userInfoFetchStarted());

  let user: UserClaims<CustomUserClaims>;
  try {
    user = await auth.getUser();
  } catch (e) {
    const reason = `An error occured when fetching user information from Okta: ${getErrorMessage(
      e
    )}`;
    dispatch(userInfoFetchFailed(reason));
    throw e;
  }

  if (!user) {
    const reason = 'ID token not found';
    dispatch(userInfoFetchFailed(reason));
    throw new Error(reason);
  }

  if (!user.preferred_username) {
    const reason = 'preferred_username field not found on ID token';
    dispatch(userInfoFetchFailed(reason));
    throw new Error(reason);
  }

  dispatch(
    userInfoFetchSucceeded(user.preferred_username, user.picture.toString())
  );
};

export default fetchUserInfo;
