import * as React from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';

const AlertModal = ({ closeModal, title, message }) => {
  return (
    <>
      <StyledModalHeader toggle={closeModal}>{title}</StyledModalHeader>
      <StyledModalBody>{message}</StyledModalBody>
      <StyledModalFooter>
        <Button color="primary" onClick={closeModal}>
          Close
        </Button>
      </StyledModalFooter>
    </>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};

  && .close {
    color: ${({ theme }) => theme.textColor};
    text-shadow: 0 1px 0 ${({ theme }) => theme.modal.backgroundColor};
  }
`;

const StyledModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledModalFooter = styled(ModalFooter)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

export default AlertModal;
