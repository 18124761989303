const dev = {
  service: {
    url: 'https://service-api.dev.alks.coxautoinc.com',
    apiKey: 'nmvfYZUdfs69wsB4XwOkk8z2wLzIzdjq1iDC4ft8',
    arf: {
      path: '/arf-handler',
    },
    announcement: {
      version: 2,
      path: '/announcements',
    },
    clickThruAction: {
      path: '/console',
    },
    userPreferenceService: {
      path: '/user-preference/user/',
    },
  },
  alks: {
    baseUrl: 'https://dev.alks.coxautoinc.com/rest',
  },
  okta: {
    issuer: 'https://coxauto.okta.com/',
    clientId: '0oa1g555k9zdy3Flh0h8',
    redirectPath: '/okta/callback.htm',
    responseType: ['token', 'id_token'],
    scopes: ['profile', 'openid', 'groups'],
    storageType: 'localStorage',
    alksOauthUrl:
      'https://coxauto.okta.com/oauth2/v1/authorize?client_id=0oa1g555k9zdy3Flh0h8&redirect_uri=https://dev.alks.coxautoinc.com/token/callback&response_type=code&scope=offline_access%20openid%20profile%20groups&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601',
  },
  optimizely: {
    enableLogging: true,
    sdkKey: 'Tg8ZinaP3pT3ZCy7mMngt',
  },
};

const prod = {
  service: {
    url: 'https://service-api.alks.coxautoinc.com',
    apiKey: 'ZF0P5f5IiXP5faeUtEI91Znm7enWnPH1XRRyrhy5',
    arf: {
      path: '/arf-handler',
    },
    announcement: {
      version: 2,
      path: '/announcements',
    },
    clickThruAction: {
      path: '/console',
    },
    userPreferenceService: {
      path: '/user-preference/user/',
    },
  },
  alks: {
    baseUrl: 'https://alks.coxautoinc.com/rest',
  },
  okta: {
    issuer: 'https://coxauto.okta.com/',
    clientId: '0oa1g555k9zdy3Flh0h8',
    redirectPath: '/okta/callback.htm',
    responseType: ['token', 'id_token'],
    scopes: ['profile', 'openid', 'groups'],
    storageType: 'localStorage',
    alksOauthUrl:
      'https://coxauto.okta.com/oauth2/v1/authorize?client_id=0oa1g555k9zdy3Flh0h8&redirect_uri=https://alks.coxautoinc.com/token/callback&response_type=code&scope=offline_access%20openid%20profile%20groups&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601',
  },
  optimizely: {
    enableLogging: false,
    sdkKey: 'Vb3DDAqKNPDmiv7Pxiczf',
  },
};

let config;

if (
  process.env.REACT_APP_ENVIRONMENT === 'dev' ||
  process.env.REACT_APP_ENVIRONMENT === 'local'
) {
  config = dev;
} else {
  config = prod;
}

export default {
  routes: {
    index: '/',
    overview: '/overview',
    iamRoles: '/iamRoles',
    preferences: '/preferences',
    arf: '/arf',
    oldArf: '/arf/legacy',
    oktaCallback: '/okta/callback.htm',
    tokenManagement: '/token-management',
    costManagement: '/cost-management',
    userManagement: '/userManagement',
    console: '/console',
    error: '/error',
  },
  docs: {
    authentication:
      'https://ghe.coxautoinc.com/ETS-CloudAutomation/ALKS/wiki/Authentication',
    token_authentication:
      'https://ghe.coxautoinc.com/ETS-CloudAutomation/ALKS/wiki/Token-Authentication',
    swagger: 'https://pages.ghe.coxautoinc.com/ETS-CloudAutomation/ALKS/',
    profile_pics:
      'https://coxautoinc.sharepoint.com/sites/service-internal-tools-team/SitePages/ALKS-Web-UI---Features-and-Functionality.aspx#profile-pics',
  },
  costData: {
    apiKey: '08IHcKT53m5FC6sNpdtL4aJ5SQHLF87V8Scai3N4',
    baseUrl:
      'https://swrucxcola.execute-api.us-east-1.amazonaws.com/dev/costData/',
  },
  userManagement: {
    snowFormAddUser:
      'https://coxauto.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=e88c2ed81399f2401d38bcaf3244b0c0',
  },
  cloudsploit: {
    reportBaseUrl: 'https://cloud.aquasec.com/results',
  },
  cloudability: {
    orgSpendDashboard: 'https://app.cloudability.com/#/dashboards/84167',
  },
  ...config,
};
