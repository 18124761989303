import * as React from 'react';
import Particles from 'react-tsparticles';
import particlesConfigJson from './ghost-particlesjs-config.json';
import { loadFull } from 'tsparticles';

export const GhostParticles: React.FC = () => {
  return <></>;
};

// This package is broken due to updates in the underlying library. It is not used in the application.
// @ts-ignore
const _GhostParticles: React.FC = () => {
  const initParticles = React.useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      id="tsParticles"
      init={initParticles}
      // @ts-ignore
      options={particlesConfigJson}
    />
  );
};
