import { createSelector } from 'reselect';
import { getIamRolesForActiveAccount } from './getIamRolesForActiveAccount';
import { getDeleteJobs } from './common';
import { DeleteJob } from '../store/shared/types';

export const getDeleteJobsForActiveAccount = createSelector(
  [getIamRolesForActiveAccount, getDeleteJobs],
  (iamRoles, deleteJobs): Map<string, DeleteJob> => {
    const iamRoleIds: string[] = [];
    const copyDeleteJob = new Map(deleteJobs);
    iamRoles.forEach((iamRole) => {
      iamRoleIds.push(iamRole.id);
    });
    for (const k of Array.from(copyDeleteJob.keys())) {
      if (iamRoleIds.indexOf(k) === -1) {
        copyDeleteJob.delete(k);
      }
    }
    return copyDeleteJob;
  }
);

export default getDeleteJobsForActiveAccount;
