import { connect } from 'react-redux';
import {
  IamRoleList,
  OwnProps,
  Props as IamRoleListProps,
} from '../components/iamRole/IamRoleList';
import { getMatchingIamRoles } from '../util/getMatchingIamRoles';
import { AppState } from '../store';
import { loadData } from '../util/loadData';
import { iamRoleLoader } from '../util/dataLoaders/iamRoleLoader';
import { getIamRolesForActiveAccount } from '../selectors/getIamRolesForActiveAccount';
import { sortByName } from '../util/sortByName';
import { Dispatch } from '../store/shared/types';
import { showConfirmationModal } from '../store/modal/actions';
import deleteIamRoles from '../operations/deleteIamRoles';
import getActiveAccountId from '../selectors/getActiveAccountId';
import getActiveAccount from '../selectors/getActiveAccount';
import { getRoles } from '../selectors/common';
import { IamRole } from '../models';
import getDeleteJobsForActiveAccount from '../selectors/getDeleteJobsForActiveAccount';
import { getIamCapableRole } from '../util/getIamCapableRole';
import { getRoleViews } from '../selectors/getRoleViews';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const activeAccount = getActiveAccount(state);
  const iamCapableRole =
    activeAccount && getIamCapableRole(activeAccount, getRoles(state));
  const deleteJobs = getDeleteJobsForActiveAccount(state);
  const roleViews = getRoleViews(state);

  return {
    iamRoles: sortByName(
      getMatchingIamRoles(
        Array.from(getIamRolesForActiveAccount(state).values()),
        ownProps.filterText
      )
    ),
    deleteJobs,
    accountId: getActiveAccountId(state),
    iamCapableRole,
    roleViews,
  };
};
type MappedPropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteRole: (
    auth: OktaAuth,
    accountId: string | undefined,
    role: string,
    iamRole: IamRole
  ) => {
    if (accountId) {
      dispatch(deleteIamRoles(auth, accountId, role, iamRole));
    }
  },
  openConfirmation: (f: () => void, iamRoleName: string) =>
    dispatch(showConfirmationModal(iamRoleName, 'Delete role', f)),
});
type MappedPropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (
  stateProps: MappedPropsFromState,
  dispatchProps: MappedPropsFromDispatch,
  ownProps: OwnProps
): IamRoleListProps => {
  const newDispatchProps = {
    ...dispatchProps,
    deleteRole: (auth: OktaAuth, iamRole: IamRole) =>
      stateProps.iamCapableRole
        ? dispatchProps.deleteRole(
            auth,
            stateProps.accountId,
            stateProps.iamCapableRole.name,
            iamRole
          )
        : undefined,
  };

  return {
    ...stateProps,
    ...newDispatchProps,
    ...ownProps,
  };
};

export const VisibleIamRoleList = loadData(
  [iamRoleLoader],
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(IamRoleList)
);
