import { createSelector } from 'reselect';
import { DataFetchStatus } from '../store/shared/types';
import { getIamRoleMIJob } from './getIamRoleMIJob';

/**
 * Gets the status of the create MI job that is currently being tracked
 */
export const getIamRoleMIJobStatus = createSelector(
  [getIamRoleMIJob],
  (job): DataFetchStatus =>
    job ? job.status : DataFetchStatus.DATA_FETCH_UNSTARTED
);

export default getIamRoleMIJobStatus;
