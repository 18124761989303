import { fetchArfAccount } from '../../operations/fetchArfAccount';
import { getActiveAccountId } from '../../selectors/getActiveAccountId';
import { DataFetchStatus } from '../../store/shared/types';
import { DataLoader } from '../loadData';

export const arfAccountLoader: DataLoader = (state, dispatch, auth) => {
  const accountId = getActiveAccountId(state);
  if (!accountId) {
    throw new Error('No active account is found');
  }
  const activeAccountFetchJob = state.data.arfAccount.fetchJobs.get(accountId);
  const arfAccountFetchStatus = activeAccountFetchJob
    ? activeAccountFetchJob.status
    : DataFetchStatus.DATA_FETCH_UNSTARTED;
  switch (arfAccountFetchStatus) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchArfAccount(auth, accountId));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      const message = activeAccountFetchJob
        ? activeAccountFetchJob.failureMessage
        : 'Failed to fetch ARF Account Data but no reason was given';
      throw new Error(message);
    }
    default: {
      throw new Error('invalid value for ARF account fetch status');
    }
  }
};

export default arfAccountLoader;
