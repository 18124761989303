import * as React from 'react';
import { FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';
import { Role, AccountView } from '../../models';

export interface ModelProps {
  roles: Role[];
  account: AccountView | undefined;
}

export interface DispatchProps {
  onSaveRole: (accountId: string, roleId: string) => void;
}

export type Props = ModelProps & DispatchProps;

class RolePreference extends React.Component<Props> {
  public render() {
    if (!this.props.account) {
      return null;
    }
    const preferredRoleId = this.props.account.preferredRole;

    return (
      <FormGroup>
        <StyledInput
          defaultValue={preferredRoleId}
          type="select"
          id="preferredRole"
          aria-labelledby="preferredRoleLabel"
          onChange={this.onSaveRole}
        >
          {this.props.roles.map((role) => {
            return (
              <option value={role.id} key={role.id}>
                {role.name}
              </option>
            );
          })}
        </StyledInput>
      </FormGroup>
    );
  }

  private onSaveRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    // we are casting this because the Input from reactstrap is of type HTMLInputElement
    const element = (e.target as unknown) as HTMLSelectElement;
    if (!this.props.account) {
      // this line is not covered in tests because it's too difficult to cover
      return;
    }
    this.props.onSaveRole(this.props.account.id, element.value);
  };
}

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default RolePreference;
