import * as React from 'react';

import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { ArfAccountViolation } from '../../models';
import styled from 'styled-components';
import { Failure } from '../shared/Failure';
import { InfoTooltip } from '../shared/InfoTooltip';
import { Table } from '../table/Table';

export interface ModelProps {
  arfAccountViolations: ArfAccountViolation[] | undefined;
}

export type Props = ModelProps;

export const ArfAccountViolations: React.FC<Props> = ({
  arfAccountViolations,
}) => {
  if (!arfAccountViolations) {
    return <Failure reason="Arf violations data could not be loaded" />;
  }

  return (
    <>
      <Header2>Open Violations</Header2>
      {!arfAccountViolations.length ? (
        <TextStyle>No violations found.</TextStyle>
      ) : (
        <Table defaultPageSize={5}>
          <TableHead>
            <TableRow>
              <TableCell>
                Policy Name{' '}
                <InfoTooltip disableInteractive={true} title="Name of the policy as known to the ARF system" />
              </TableCell>
              <TableCell>Region(s)</TableCell>
              <TableCell>
                Resource{' '}
                <InfoTooltip disableInteractive={true} title="AWS infrastructure item that has recent violation" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arfAccountViolations.map((violation, i) => (
              <TableRow key={i}>
                <TableCell>{violation.policy_name}</TableCell>
                <TableCell>
                  {violation.policy_name.includes('s3')
                    ? 'global'
                    : violation.regions.join(', ')}
                </TableCell>
                <TableCell>{violation.resource_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

const TextStyle = styled.p`
  color: ${({ theme }) => theme.textColor};
`;
const Header2 = styled.h2`
  color: ${({ theme }) => theme.textColor};
`;

export default ArfAccountViolations;
