import { connect } from 'react-redux';
import { AppState } from '../store';
import { Dispatch } from '../store/shared/types';
import { RoleTypesFetcher as RoleTypesFetcherComponent } from '../components/dataInitiation/RoleTypesFetcher';
import { fetchRoleTypes } from '../operations/fetchRoleTypes';
import { OktaAuth } from '@okta/okta-auth-js';

const mapStateToProps = (state: AppState) => ({
  fetchStatus: state.data.roleTypes.fetchStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRoleTypes: (auth: OktaAuth) => dispatch(fetchRoleTypes(auth)),
});

export const RoleTypesFetcher = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleTypesFetcherComponent);
