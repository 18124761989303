import { DataFetchStatus } from '../shared/types';

export const USER_INFO_IS_LOADING = 'USER_INFO_IS_LOADING';
export type USER_INFO_IS_LOADING = typeof USER_INFO_IS_LOADING;

export const USER_INFO_SUCCEEDED = 'USER_INFO_SUCCEEDED';
export type USER_INFO_SUCCEEDED = typeof USER_INFO_SUCCEEDED;

export const USER_INFO_FAILED = 'USER_INFO_FAILED';
export type USER_INFO_FAILED = typeof USER_INFO_FAILED;

interface UserInfoLoadingAction {
  type: USER_INFO_IS_LOADING;
}

interface UserInfoSucceededAction {
  type: USER_INFO_SUCCEEDED;
  username: string;
  email: string;
}

interface UserInfoFailedAction {
  type: USER_INFO_FAILED;
  failureMessage: string;
}

export type UserInfoActionType =
  | UserInfoSucceededAction
  | UserInfoFailedAction
  | UserInfoLoadingAction;

export interface UserInfoState {
  fetchStatus: DataFetchStatus;
  username: string;
  email: string;
  failureMessage?: string;
}
