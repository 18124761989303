import {
  Collapse,
  TableCell,
  TableRow,
  TableRowProps,
} from '@mui/material';
import { TriangleDownIcon, TriangleUpIcon } from '@primer/octicons-react';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../styles/useTheme';

export interface OwnProps {
  collapseContent: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

export type CollapsibleTableRowProps = OwnProps & TableRowProps;

/**
 * A TableRow with a collapsible component that shows/hides when you click the row
 *
 * @param collapseContent - the component(s) to put in the collapsed section of this row
 * @example
 * <Table>
 *   <TableBody>
 *     <CollapsibleTableRow
 *       collapseContent={
 *         <div>this will show in the collapsed portion</div>
 *       }
 *     >
 *       <TableCell>This will be a table cell in the uncollapsible portion of the row</TableCell>
 *     </CollapsibleTableRow>
 *   </TableBody>
 * </Table>
 */
export const CollapsibleTableRow: React.FC<CollapsibleTableRowProps> = ({
  children,
  collapseContent,
  open,
  setOpen,
  ...props
}) => {
  const [theme] = useTheme();

  const [hover, setHover] = useState(false);

  // Use these to manage state internally if the parent doesn't want to manage it
  const [unmanagedOpen, setUnmanagedOpen] = useState(false);
  const internalOpen = open ?? unmanagedOpen;
  const internalSetOpen = setOpen ?? setUnmanagedOpen;

  return (
    <>
      <BorderlessRow
        style={{
          borderBottom: 0,
          backgroundColor: hover || internalOpen
            ? theme.backgroundColor
            : theme.card.header.backgroundColor,
          cursor: hover ? 'pointer' : 'auto',
        }}
        onClick={() => internalSetOpen(!internalOpen)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...props}
      >
        {children}
        <TableCell style={{ textAlign: 'right' }}>
          {internalOpen ? (
            <TriangleUpIcon fill={theme.textColor} size={18} />
          ) : (
            <TriangleDownIcon fill={theme.textColor} size={18} />
          )}
        </TableCell>
      </BorderlessRow>
      <TableRow
        onClick={() => internalSetOpen(!internalOpen)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          backgroundColor: hover || internalOpen
            ? theme.backgroundColor
            : theme.card.header.backgroundColor,
          cursor: hover ? 'pointer' : 'auto',
        }}
      >
        <TableCell
          colSpan={React.Children.count(children) + 1}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={internalOpen} sx={{borderTop: '1px dashed rgba(127,127,127,0.5)'}} unmountOnExit>
            {collapseContent}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const BorderlessRow = styled(TableRow)`
  && .MuiTableCell-root {
    border-bottom: none;
  }
`;
