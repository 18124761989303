import { Role } from './Role';
import {
  RolePreferences,
  applyDefaultRolePreferences,
} from './RolePreferences';
import memoize from 'memoizee';

// This is the same as `type RoleView = Role & RolePreferences` except that it's extensible
export interface RoleView extends Role, RolePreferences {}

export const getRoleView = memoize(
  (role: Role, prefs?: RolePreferences): RoleView => ({
    ...role,
    ...applyDefaultRolePreferences(prefs),
  }),
  { length: 2 }
);

export default RoleView;
