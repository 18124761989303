import * as t from 'io-ts';

export const Announcement = t.interface({
  id: t.string,
  previewText: t.string,
  message: t.string,
  link: t.string,
  linkText: t.string,
});

export type Announcement = t.TypeOf<typeof Announcement>;

export default Announcement;
