import { Button as ReactstrapButton } from 'reactstrap';
import styled from 'styled-components';

export const Button = styled(ReactstrapButton)`
  &&:not(:disabled):not(.disabled):active,
  &&:not(:disabled):not(.disabled).active,
  .show > &&.dropdown-toggle,
  &&.btn-outline-primary {
    border-color: none;

    :focus {
      box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.boxShadowColor};
      background-color: ${({ theme }) => theme.primaryColor};
    }
  }

  .show > &&.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.boxShadowColor};
  }
`;
