import { DataFetchStatus } from '../../store/shared/types';
import { fetchAnnouncements } from '../../operations/fetchAnnouncements';
import { DataLoader } from '../loadData';

export const announcementsLoader: DataLoader = (state, dispatch, auth) => {
  const status = state.ui.announcement.fetchStatus;

  switch (status) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchAnnouncements(auth));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      throw new Error(state.ui.announcement.failureMessage);
    }
    default: {
      throw new Error('invalid value for announcement fetch status');
    }
  }
};

export default announcementsLoader;
