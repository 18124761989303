import { ThunkAction } from '../store/shared/types';
import * as config from '../config';
import {
  iamRoleDeleteMachineIdentityStarted,
  iamRoleDeleteMachineIdentityFailed,
  iamRoleDeleteMachineIdentitySucceeded,
} from '../store/iamRoles/actions';
import { toast } from 'react-toastify';
import ALKS, { MachineIdentity } from 'alks.js';
import { OktaAuth } from '@okta/okta-auth-js';
import { getErrorMessage } from '../util/getErrorMessage';

/**
 * Deletes a machine identity for a role
 *
 * @param auth - the Okta auth object
 * @param iamRoleArn - the arn of the role
 * @param iamRoleId - the role id
 */
export const deleteRoleMachineIdentity = (
  auth: OktaAuth,
  iamRoleArn: string,
  iamRoleId: string
): ThunkAction<void> => async (dispatch) => {
  dispatch(iamRoleDeleteMachineIdentityStarted(iamRoleArn));

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `An error occured when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(`${iamRoleArn} failed to delete machine identity`, { type: 'error' });
    dispatch(iamRoleDeleteMachineIdentityFailed(iamRoleArn, reason));
    throw e;
  }

  let machineIdentity: MachineIdentity;
  try {
    const alks = ALKS.create({
      baseUrl: config.default.alks.baseUrl,
      accessToken,
    });

    machineIdentity = await alks.deleteRoleMachineIdentity({
      roleARN: iamRoleArn,
    });
  } catch (e) {
    const reason = `An error occured when deletin machine identity for ${iamRoleArn} from ALKS: ${getErrorMessage(
      e
    )}`;
    toast(`${iamRoleArn} machine identity failed to delete`, { type: 'error' });
    dispatch(iamRoleDeleteMachineIdentityFailed(iamRoleArn, reason));
    throw e;
  }

  toast(
    `Machine identity deleted successfully: ${machineIdentity.machineIdentityArn}`,
    {
      type: 'success',
    }
  );
  dispatch(iamRoleDeleteMachineIdentitySucceeded(iamRoleArn, iamRoleId));
};

export default deleteRoleMachineIdentity;
