import { connect } from 'react-redux';
import { getActiveAccount } from '../selectors/getActiveAccount';
import { getDefaultAccount } from '../selectors/getDefaultAccount';
import { AppState } from '../store';
import { AccountRoute, StateProps } from '../components/routing/AccountRoute';

const mapStateToProps = (state: AppState): StateProps => ({
  activeAccount: getActiveAccount(state),
  defaultAccount: getDefaultAccount(state),
  routerLocation: state.router.location,
});

export const ActiveAccountRoute = connect(mapStateToProps)(AccountRoute);
