import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import urljoin from 'url-join';
import { ActiveIamRole } from '../../containers/ActiveIamRole';
import { ConnectedNewIamRole } from '../../containers/ConnectedNewIamRole';
import { FilterableIamRoleList } from './FilterableIamRoleList';

export type Props = RouteComponentProps;

export const IamRoles: React.FC<Props> = ({ match }) => (
  <>
    <Switch>
      <Route exact={true} path={match.path} component={FilterableIamRoleList} />
      <Route
        path={urljoin(match.path, '/name/:roleName')}
        component={ActiveIamRole}
      />
      <Route
        path={urljoin(match.path, '/new')}
        component={ConnectedNewIamRole}
      />
      <Redirect from={match.path} to={match.path} />
    </Switch>
  </>
);

export default IamRoles;
