import { Job } from '../shared/types';
import { CostData } from '../../models';

export const COST_DATA_FETCH_STARTED = 'COST_DATA_FETCH_STARTED';
export type COST_DATA_FETCH_STARTED = typeof COST_DATA_FETCH_STARTED;

export const COST_DATA_FETCH_SUCCEEDED = 'COST_DATA_FETCH_SUCCEEDED';
export type COST_DATA_FETCH_SUCCEEDED = typeof COST_DATA_FETCH_SUCCEEDED;

export const COST_DATA_FETCH_FAILED = 'COST_DATA_FETCH_FAILED';
export type COST_DATA_FETCH_FAILED = typeof COST_DATA_FETCH_FAILED;

interface CostDataStartedAction {
  type: COST_DATA_FETCH_STARTED;
  accountId: string;
}

interface CostDataSucceededAction {
  type: COST_DATA_FETCH_SUCCEEDED;
  accountId: string;
  costData: CostData | undefined;
}

interface CostDataFailedAction {
  type: COST_DATA_FETCH_FAILED;
  accountId: string;
  failureMessage: string;
}

export type CostDataActionType =
  | CostDataFailedAction
  | CostDataStartedAction
  | CostDataSucceededAction;

export interface CostDataState {
  fetchJobs: Map<string, Job>;
  byId: Map<string, CostData>;
}
