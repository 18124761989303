import { DataFetchStatus } from '../../store/shared/types';
import { fetchAccountsAndRoles } from '../../operations/fetchAccountsAndRoles';
import { DataLoader } from '../loadData';

export const accountLoader: DataLoader = (state, dispatch, auth) => {
  const status = state.data.accounts.fetchStatus;

  switch (status) {
    case DataFetchStatus.DATA_FETCH_UNSTARTED: {
      dispatch(fetchAccountsAndRoles(auth));
      return false;
    }
    case DataFetchStatus.DATA_FETCH_PENDING: {
      return false;
    }
    case DataFetchStatus.DATA_FETCH_SUCCEEDED: {
      return true;
    }
    case DataFetchStatus.DATA_FETCH_FAILED: {
      throw new Error(state.data.accounts.failureMessage);
    }
    default: {
      throw new Error('invalid value for account fetch status');
    }
  }
};

export default accountLoader;
