import { createSelector } from 'reselect';
import { getAccountSearchText } from './common';
import { parseTerms } from '../util/parseTerms';
import { TagTerm, TAG } from '../models';

/**
 * Gets a list of distinct tag terms based on the account search string
 */
export const getUniqueTagTerms = createSelector(
  [getAccountSearchText],
  (accountSearchText): TagTerm[] =>
    parseTerms(accountSearchText)
      .filter((term): term is TagTerm => term.type === TAG)
      .reduce(
        (unique: TagTerm[], term) =>
          unique.some((t) => t.text.toLowerCase() === term.text.toLowerCase())
            ? unique
            : [...unique, term],
        []
      )
);

export default getUniqueTagTerms;
