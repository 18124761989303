import { DataFetchStatusType } from '../shared/types';
import * as t from 'io-ts';
import { Announcement } from '../../models';

export const SHOW_ANNOUNCEMENT = 'SHOW_ANNOUNCEMENT';
export type SHOW_ANNOUNCEMENT = typeof SHOW_ANNOUNCEMENT;

export const HIDE_ANNOUNCEMENT = 'HIDE_ANNOUNCEMENT';
export type HIDE_ANNOUNCEMENT = typeof HIDE_ANNOUNCEMENT;

export const ANNOUNCEMENTS_ARE_LOADING = 'ANNOUNCEMENTS_ARE_LOADING';
export type ANNOUNCEMENTS_ARE_LOADING = typeof ANNOUNCEMENTS_ARE_LOADING;

export const ANNOUNCEMENTS_SUCCEEDED = 'ANNOUNCEMENTS_SUCCEEDED';
export type ANNOUNCEMENTS_SUCCEEDED = typeof ANNOUNCEMENTS_SUCCEEDED;

export const ANNOUNCEMENTS_FAILED = 'ANNOUNCEMENTS_FAILED';
export type ANNOUNCEMENTS_FAILED = typeof ANNOUNCEMENTS_FAILED;

interface AnnouncementsLoadingAction {
  type: ANNOUNCEMENTS_ARE_LOADING;
}

interface AnnouncementFailedAction {
  type: ANNOUNCEMENTS_FAILED;
  failureMessage: string;
}

interface AnnouncementSucceededAction {
  type: ANNOUNCEMENTS_SUCCEEDED;
  announcements: any[];
}

interface ShowAnnouncement {
  type: SHOW_ANNOUNCEMENT;
  announcementId: string;
}

interface HideAnnouncement {
  type: HIDE_ANNOUNCEMENT;
}

export type AnnouncementActionType =
  | ShowAnnouncement
  | HideAnnouncement
  | AnnouncementSucceededAction
  | AnnouncementFailedAction
  | AnnouncementsLoadingAction;

export const AnnouncementState = t.intersection([
  t.interface({
    fetchStatus: DataFetchStatusType,
    show: t.boolean,
    announcements: t.record(t.string, Announcement), // all announcements
    currentAnnouncement: t.union([t.string, t.null]), // announcement ID
  }),
  t.partial({
    failureMessage: t.string,
  }),
]);

export type AnnouncementState = t.TypeOf<typeof AnnouncementState>;
