import {
  Paper,
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableContainer,
  TablePagination,
  ThemeProvider,
  createTheme,
  TableHead,
  TableBody,
  styled as muiStyled,
  TablePaginationProps
} from '@mui/material';
import * as React from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../styles/useTheme';

export interface Props extends MuiTableProps {
  children?: React.ReactNode;
  defaultPageSize?: number;
  style?: React.CSSProperties;
  hover?: boolean;
}

/**
 * A replacement for the Material UI table component, prestyled for our application and preset with
 * automatic pagination.
 *
 * If you want a header row, you must use a TableHead. If you want any elements to exist in the
 * table, they must be put in a TableBody. Any child elements that are not a child of one of these
 * two components will not appear in the Table.
 *
 * @example
 * <Table>
 *   <TableHead>
 *     <TableRow>
 *       <TableCell>ID</TableCell>
 *       <TableCell>Age</TableCell>
 *       <TableCell>Favorite Color</TableCell>
 *     </TableRow>
 *   </TableHead>
 *   <TableBody>
 *     {items.map((item, index) => (
 *       <TableRow key={index}>
 *         <TableCell>{item.id}</TableCell>
 *         <TableCell>{item.age}</TableCell>
 *         <TableCell>{item.favColor}</TableCell>
 *       </TableRow>
 *     ))}
 *   </TableBody>
 * </Table>
 */
export const Table: React.FC<Props> = ({
  children,
  defaultPageSize = 10,
  style,
  hover = true,
  ...props
}) => {
  const [theme] = useTheme();

  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(defaultPageSize);

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme.type,
          background: {
            paper: theme.card.header.backgroundColor,
          },
        },
      }),
    [theme]
  );

  const { head, body, count } = useMemo(() => {
    const childrenArray = React.Children.toArray(
      children
    ).filter((child): child is React.ReactElement =>
      React.isValidElement(child)
    );
    const tableHead = childrenArray.find((child) => child.type === TableHead);
    const tableBody = childrenArray.find((child) => child.type === TableBody);
    if (tableBody === undefined) {
      return {
        head: tableHead,
        body: [],
        count: 0,
      };
    }
    const tableBodyChildren = React.Children.toArray(tableBody?.props.children);
    const filteredChildren = tableBodyChildren.slice(
      page * pageSize,
      (page + 1) * pageSize
    );
    const newTableBody = React.cloneElement(
      tableBody,
      tableBody?.props,
      filteredChildren
    );
    return {
      head: tableHead,
      body: newTableBody,
      count: tableBodyChildren.length,
    };
  }, [children, page, pageSize]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Paper style={style}>
        <TableContainer>
          <StyledTable hover={hover} {...props}>
            {head}
            {body}
          </StyledTable>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledTablePagination
            count={count}
            page={page}
            component={'div'}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setPageSize(parseInt(event.target.value));
              setPage(0);
            }}
          />
        </div>
      </Paper>
    </ThemeProvider>
  );
};

const StyledTablePagination = muiStyled(TablePagination)<TablePaginationProps>(() => ({
  '& p': {
    marginBottom: 0
  }
}));

interface StyledTableProps {
  hover: boolean;
}

const StyledTable = styled(MuiTable).withConfig({ shouldForwardProp: (prop) => prop !== 'hover'})<StyledTableProps>`
  && :not(.MuiTableRow-head).MuiTableRow-root {
    background-color: inherit;

    ${({ hover, theme }) => hover ? `
      :hover {
        background-color: ${theme.backgroundColor};
      }
    ` : ''}
  }

  && .MuiTableCell-root {
    border-color: ${({ theme }) => theme.borderColor};
  }

  && .MuiTableRow-head {
    background-color: inherit;
  }

  && .MuiTableCell-head {
    font-size: larger;
  }
`;
